import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { cepMask } from '@utils/masks'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import IcPadlock from '@interco/icons/core/privacy-security/ic_padlock'
import {
  enderecoRequest,
  setBairro,
  setCep,
  setCidadeSelecionada,
  setComplemento,
  setEstadoSelecionado,
  setLogradouro,
  setNumero,
  setTipoLogradouro,
} from '@store/portabilidade/endereco/actions'
import { ApplicationState } from '@store/types'
import { cidadesRequest, estadosRequest } from '@store/globalApi/actions'
import { Loading } from '@interco/inter-ui/components/Loading'
import {
  buildCidadesNovo,
  buildEstadosNovo,
  transformCidadeToOption,
  transformEstadoToOption,
} from '@utils/transformers'
import routes from '@routes/routes'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import Select from '@atoms/SelectNovo'
import Tags from '@utils/Tags'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'

import { Container, CustomInput } from './styles'

const Endereco = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(false)
  const [disableCep, setDisableCep] = useState(false)
  const [enderecoLoaded, setEnderecoLoaded] = useState(false)

  const {
    cep,
    tipoLogradouro,
    logradouro,
    bairro,
    numero,
    complemento,
    estadoSelecionado,
    cidadeSelecionada,
  } = useSelector((state: ApplicationState) => state.portabilidade.endereco)
  const [cepLocal, setCepLocal] = useState(cepMask(cep))
  const { estados = [], cidades = [] } = useSelector((state: ApplicationState) => state.globalApi)
  const loading = useSelector(
    (state: ApplicationState) => state.portabilidade.endereco.enderecoLoading,
  )

  useEffect(() => {
    dispatch(estadosRequest())
  }, [dispatch])

  useEffect(() => {
    if (estadoSelecionado) {
      dispatch(cidadesRequest(estadoSelecionado))
    }
  }, [dispatch, estadoSelecionado])

  useEffect(() => {
    if (cepLocal.length === 10) {
      dispatch(
        enderecoRequest({
          cep: cepLocal.replace(/[. -]/g, ''),
          callback: () => {
            setEnderecoLoaded(true)
          },
        }),
      )
      dispatch(setCep(cepLocal))
    }
  }, [cepLocal, dispatch])

  useEffect(() => {
    const requiredFields = [
      { value: !disableCep && !cepLocal },
      {
        value: !disableCep && (!cepLocal || cepLocal.length < 10),
      },
      { value: !disableCep && !cep },
      { value: !disableCep && (cep || '').length < 8 },
      { value: !tipoLogradouro },
      { value: !logradouro },
      { value: !bairro },
      { value: !estadoSelecionado },
      { value: !cidadeSelecionada },
      { value: !numero },
    ]
    const hasEmptyFields = requiredFields.some((field) => field.value)
    setDisabled(hasEmptyFields)
  }, [
    bairro,
    cep,
    cepLocal,
    cidadeSelecionada,
    disableCep,
    estadoSelecionado,
    logradouro,
    numero,
    tipoLogradouro,
  ])

  return (
    <NovoPage
      id="local-nascimento"
      stickyContainerFooterBottom="0px"
      positionFooter="sticky"
      stickyFooter={
        <Button
          disabled={disabled}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidadeNovo.ENDERECO))
            Tags.enviarTagueamentoWeb({
              element_name: 'Confirmar',
              section_name: 'Endereço',
              element_action: 'click button',
              c_page: window.location.href,
              redirect_url: `${window.location.origin}${routes.DADOS_BANCARIOS_V2}`,
              step: 'null',
            })
            navigate(routes.DADOS_BANCARIOS_V2)
          }}
        >
          Confirmar
        </Button>
      }
    >
      <Text
        variant="headline-h2"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '22px 0 16px 0',
        }}
      >
        Cadastre o seu endereço
      </Text>
      <CustomInput
        id="cep"
        type="text"
        label="CEP"
        placeholder="00000-000"
        value={cepLocal}
        disabled={disableCep}
        iconRight={disableCep ? <IcPadlock height={24} width={24} color={Colors.GRAY300} /> : <></>}
        onChange={(e) => setCepLocal(cepMask((e.target as HTMLInputElement).value))}
      />
      {disableCep || loading || (!loading && cepLocal.length === 10) ? (
        <></>
      ) : (
        <Text
          variant="body-3"
          color="primary"
          colorWeight={500}
          bold
          style={{ textAlign: 'center', margin: '15px 0 16px 0' }}
          onClick={() => {
            setDisableCep(true)
          }}
        >
          Não sei o meu CEP
        </Text>
      )}

      {loading ? <Loading height="25px" width="25px" style={{ alignSelf: 'center' }} /> : <></>}
      {(!loading && cepLocal.length === 10 && enderecoLoaded) || disableCep ? (
        <>
          <CustomInput
            id="tipo"
            type="text"
            value={tipoLogradouro}
            label="Tipo"
            placeholder="Selecione um tipo"
            maxLength={20}
            onChange={(e) => {
              dispatch(setTipoLogradouro((e.target as HTMLInputElement).value))
            }}
          />
          <CustomInput
            id="logradouro"
            type="text"
            value={logradouro}
            label="Endereço"
            placeholder="Insira o endereço"
            maxLength={45}
            onChange={(e) => {
              dispatch(setLogradouro((e.target as HTMLInputElement).value))
            }}
          />
          <Container>
            <CustomInput
              id="numero"
              type="text"
              value={numero}
              label="Número"
              placeholder="Insira o número"
              onlyNumber
              maxLength={5}
              onChange={(e) => {
                dispatch(setNumero((e.target as HTMLInputElement).value))
              }}
              style={{ width: '45%' }}
            />
            <CustomInput
              id="complemento"
              type="text"
              value={complemento}
              label="Complemento"
              placeholder="Complemento"
              maxLength={20}
              onChange={(e) => {
                dispatch(setComplemento((e.target as HTMLInputElement).value))
              }}
              style={{ width: '50%' }}
            />
          </Container>
          <CustomInput
            id="bairro"
            type="text"
            value={bairro}
            label="Bairro"
            placeholder="Insira o bairro"
            maxLength={60}
            onChange={(e) => {
              dispatch(setBairro((e.target as HTMLInputElement).value))
            }}
          />
          <Select
            label="Estado"
            name="dropdown"
            margin="0 0 20px 0"
            value={transformEstadoToOption(estados, String(estadoSelecionado))}
            hideSearch={false}
            options={buildEstadosNovo(estados)}
            placeholder="Selecione o Estado"
            onChange={(option: Option) => {
              dispatch(setEstadoSelecionado(String(option.value)))
              dispatch(cidadesRequest(String(option.value)))
              if (cidadeSelecionada) {
                dispatch(setCidadeSelecionada(''))
              }
            }}
          />
          <Select
            label="Cidade"
            name="dropdown"
            margin="0 0 20px 0"
            value={transformCidadeToOption(cidades, String(cidadeSelecionada))}
            hideSearch={false}
            options={buildCidadesNovo(cidades)}
            placeholder="Selecione a Cidade"
            onChange={(option: Option) => {
              dispatch(setCidadeSelecionada(String(option.value)))
            }}
            disabled={!estadoSelecionado}
          />
        </>
      ) : (
        <></>
      )}
    </NovoPage>
  )
}
export default Endereco
