import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (data: SalvarPropostaRequest, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_POST_SALVAR_PROPOSTA}`
    : process.env.REACT_APP_POST_SALVAR_PROPOSTA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = BaseServices.request<DataResponseType>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface IAddress {
  zipCode: string
  addressType: string
  address: string
  addressLine2: string
  number: number
  neighborhood: string
  state: string
  city: string
}

export interface IBankInfo {
  originBank: string
  accountType: string
  agency: string
  account: string
  accountDigit: string
}

export interface SalvarPropostaRequest {
  addressInfo: IAddress
  bankInfo: IBankInfo
}

export interface DataResponseType {
  documentoId: string
}
