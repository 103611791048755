import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'
import { Page } from '@interco/cp-react-ui-lib'

export const VisualizarFoto = styled.img`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1rem solid #f5f6fa;
  background-color: #f5f6fa;
  border-radius: 8;
`
export const LineDescription = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`

export const LineButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`
export const RightButton = styled(Button)`
  & > button {
    min-width: 150px;
    margin-left: 6px;
  }
`

export const LeftButton = styled(Button)`
  & > button {
    min-width: 150px;
    margin-right: 6px;
  }
`
export const CustomGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
`
export const LineButtonsWeb = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0;
`

export const PageImage = styled(Page)<{
  fotografia: string
}>`
  min-height: 100vh;
  background-image: ${({ fotografia }) => `url(${fotografia})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const RootPage = styled(Page)<{
  isApp: boolean
  backgroundColor?: string
  hasProgressStep?: boolean
  fullPage?: boolean
}>`
  background-color: var(--neutral-theme);

  height: 100vh;

  max-height: ${(props) => props.isApp && props.hasProgressStep && `calc(100vh - 41px)`};
  max-height: ${(props) => !props.isApp && props.hasProgressStep && `calc(100vh - 188px)`};
  max-height: ${(props) => !props.isApp && !props.hasProgressStep && `calc(100vh - 148px)`};

  @media (min-width: 768px) {
    max-height: ${(props) => !props.isApp && props.hasProgressStep && `calc(100vh - 225px)`};
    max-height: ${(props) => !props.isApp && !props.hasProgressStep && `calc(100vh - 185px)`};
  }
  @media (min-width: 991px) {
    max-height: ${(props) => !props.isApp && props.hasProgressStep && `calc(100vh - 154px)`};
    max-height: ${(props) => !props.isApp && !props.hasProgressStep && `calc(100vh - 112px)`};
  }

  & > div:first-child {
    margin-top: ${(props) => !props.isApp && !props.hasProgressStep && `0px`};
    margin-bottom: ${(props) => !props.isApp && !props.hasProgressStep && `0px`};
  }
`
