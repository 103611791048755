import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { GridStyled, Loading } from '@atoms'
import { Page } from '@templates'
import { convenioByTokenRequest } from '@store/portabilidade/validacao/actions'
import { ConveniosCodes } from '@utils/enums'
import routes from '@routes/routes'
import useAuth from '@hooks/useAuth'

const VerificacaoRedirecionamento = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getToken, accessToken } = useAuth()

  useEffect(() => {
    getToken()
  }, [getToken])

  useEffect(() => {
    if (accessToken) {
      dispatch(
        convenioByTokenRequest({
          accessToken,
          callback: (convenio) => {
            if (convenio === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
              navigate(routes.CONSULTA_MARGEM_SIAPE_V2)
            } else if (convenio === ConveniosCodes.INSS) {
              navigate(routes.CARREGAMENTO_DADOS_V2)
            } else {
              navigate(routes.ONBOARDING)
            }
          },
        }),
      )
    }
  }, [accessToken, dispatch, navigate])

  return (
    <Page id="page-root">
      <GridStyled margin="40vh 0 0">
        <Loading width={50} height={50} />
      </GridStyled>
    </Page>
  )
}

export default VerificacaoRedirecionamento
