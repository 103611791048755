import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

interface DocAnexoType {
  type: string
  files: Files[]
}

export interface Files {
  file: string
  extension: string
}

export default async (data: DocAnexoType, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_ENVIAR_ANEXO_PORTABILITY}`
    : process.env.REACT_APP_ENVIAR_ANEXO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
