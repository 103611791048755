import OAuthAssistant from '@curity/oauth-assistant'

export const isBrowser = typeof window !== 'undefined'

const oAuthScopesNewLogin = ['cpc-portal-bff-service:write', 'cpc-portal-bff-service:read']

export const oAuthScopes = oAuthScopesNewLogin

const debug = process.env.REACT_APP_ENVIROMENT === 'development'
export const siteUrl =
  process.env.REACT_APP_ENVIROMENT === 'development'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_PORTABILIDADE
const baseCurityUrl = process.env.REACT_APP_HOST_CURITY as string

const issuer = `${baseCurityUrl}/generic/oauth`
const clientIdNewLogin = 'cpc-session-manager'
const clientId = clientIdNewLogin
const openidConfigurationUrl = `${issuer}/.well-known/openid-configuration`
const baseAppUrl = siteUrl as string
const redirectUri = `${siteUrl}/portabilidade/verificacao` as string

const settings: OAuthAssistant.Settings = {
  flow_type: 'code',
  base_url: baseCurityUrl,
  client_id: clientId,
  issuer,
  redirect_uri: redirectUri,
  for_origin: baseAppUrl,
  allowed_origins: [isBrowser ? window.origin : ''],
  jwt_sig_public_key: {
    format: 'issuer',
    value: null,
  },
  ignore_not_before: false,
  ignore_expiration: false,
  clock_tolerance: 0,
  debug,
  openid_configuration_url: openidConfigurationUrl,
}

const oAuthAssistant = new OAuthAssistant(settings)

export function sso() {
  return oAuthAssistant?.authorizeHiddenFrame({
    scope: oAuthScopes,
  })
}

export default oAuthAssistant || null
