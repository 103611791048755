import { action, Action } from 'typesafe-actions'

import {
  BuscarDocumentosAssinaturaPayload,
  DocumentosAssinaturaTypes,
  FinalizarPropostaPayload,
} from './types'

export const setDocumentos = (payload: string): Action =>
  action(DocumentosAssinaturaTypes.SET_DOCUMENTOS, payload)
export const setIdIteracao = (payload: string): Action =>
  action(DocumentosAssinaturaTypes.SET_ID_ITERACAO, payload)
export const setPropostaAtualizada = (payload: boolean): Action =>
  action(DocumentosAssinaturaTypes.SET_PROPOSTA_ATUALIZADA, payload)
export const setDocumentosGerados = (payload: string): Action =>
  action(DocumentosAssinaturaTypes.SET_DOCUMENTOS_GERADOS, payload)
export const setDocumentoRevisao = (payload: string): Action =>
  action(DocumentosAssinaturaTypes.SET_DOCUMENTO_REVISAO, payload)
export const setCheckMessageRevisao = (payload: string): Action =>
  action(DocumentosAssinaturaTypes.SET_CHECK_MESSAGE_REVISAO, payload)
export const setTitleDocumentoRevisao = (payload: string): Action =>
  action(DocumentosAssinaturaTypes.SET_TITLE_DOCUMENTO_REVISAO, payload)
export const atualizarProposta = (): Action => action(DocumentosAssinaturaTypes.ATUALIZAR_PROPOSTA)

export const setLoading = (payload: boolean): Action =>
  action(DocumentosAssinaturaTypes.SET_LOADING, payload)

export const finalizarProposta = (payload: FinalizarPropostaPayload): Action =>
  action(DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA, payload)

export const finalizarPropostaSuccess = (): Action =>
  action(DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA_SUCCESS)

export const finalizarPropostaErro = (): Action =>
  action(DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA_ERROR)

export const postBuscarDocumentosAssinatura = (
  payload: BuscarDocumentosAssinaturaPayload,
): Action => action(DocumentosAssinaturaTypes.BUSCAR_DOCUMENTOS_ASSINATURA_REQUEST, payload)
