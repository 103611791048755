import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import NovoPage from '@templates/NovoPage'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import routes from '@routes/routes'
import DetalhesRefin from '@pages/PortabilidadeV2/EtapaContratos/RevisaoSimulacao/DetalhesRefin'
import DetalhesPortabilidade from '@pages/PortabilidadeV2/EtapaContratos/RevisaoSimulacao/DetalhesPortabilidade'
import { formatCurrency } from '@utils/masks'
import { Separator } from '@atoms'
import IcContract from '@interco/icons/core/files/ic_contract'
import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { Colors } from '@utils/colors'
import { IWbISafeResponse } from '@interco/inter-webview-bridge'
import Tags from '@utils/Tags'
import { getValidateBiometrics, postBiometriaDadoSave } from '@store/biometria/actions'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo, TipoPortabilidade } from '@utils/enums'
import { ISafeBottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { finalizarProposta } from '@store/portabilidade/documentosAssinatura/actions'
import { postIsafeAutorizacao } from '@store/portabilidade/validacao/actions'

import { BoxCustom, Container, Header, SeguroRefin } from './styles'
import { BottomSheetRefazerBiometria } from './BottomSheetRefazerBiometria'
import { variants } from './variants'
import { BottomSheetError } from './BottomSheetError'

const RevisaoContratacao = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showISafe, setShowISafe] = useState(false)
  const [variant, setVariant] = useState(variants.autenticacaoRealizada)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [showBottomSheetRefazerBiometria, setShowBottomSheetRefazerBiometria] = useState(false)
  const {
    refinancing,
    refinancingWithInsurance,
    portability,
    benefitNumber,
    tipoPortabilidade,
    seguro,
  } = useSelector((state: ApplicationState) => state.portabilidade.simulacaoCompleta)
  const { nomeConvenioSelecionado, convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { isApp, origem } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { accessToken } = useSelector((state: ApplicationState) => state.session)
  const { loadingBiometricsValidate } = useSelector((state: ApplicationState) => state.biometria)
  const { conta } = useSelector((state: ApplicationState) => state.portabilidade.dadosBancarios)
  const { loadingFinalizarProposta } = useSelector(
    (state: ApplicationState) => state.portabilidade.documentosAssinatura,
  )

  let isafeResponse: IWbISafeResponse | undefined

  useEffect(() => {
    dispatch(
      getValidateBiometrics({
        callback: (isValid) => {
          if (!isValid) {
            setShowBottomSheetRefazerBiometria(true)
          } else {
            dispatch(trackingRequest(StepPortabilidadeNovo.CONCLUSAO_ENVIO_DOCUMENTOS))
          }
        },
      }),
    )
  }, [dispatch])

  useEffect(() => {
    BridgeService.tac({
      name: 'revisao_contratacao',
      actions: ['continuar_biometria_finalizada'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: `Revisão ${refinancing ? 'com troco' : 'sem troco'}`,
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: refinancing
        ? 'Os valores são aproximados, caem na sua conta e podem variar por convênio.'
        : 'Os valores são aproximados e podem variar por convênio.',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado, refinancing])

  const callFinalizar = () => {
    dispatch(
      finalizarProposta({
        callback: () => {
          dispatch(trackingRequest(StepPortabilidadeNovo.PROPOSTA_FINALIZADA))
          Tags.enviarTagueamentoWeb({
            element_name: 'Confirmar contratação',
            section_name: 'Revisão',
            element_action: 'click button',
            c_page: window.location.href,
            redirect_url: `${window.location.origin}${routes.SUCESSO_V2}`,
            step: 'null',
          })
          navigate(routes.SUCESSO_V2)
        },
        callbackTryAgain: () => {
          Tags.enviarTagueamentoWeb({
            element_name: 'Confirmar contratação',
            section_name: 'Revisão',
            element_action: 'click button',
            c_page: window.location.href,
            redirect_url: `${process.env.REACT_APP_BIOMETRIA_MODULO}`,
            step: 'null',
          })
          setShowBottomSheetRefazerBiometria(true)
        },
      }),
    )
  }

  const onAuthenticateComplete = async (status: boolean) => {
    if (!BridgeService.isBrowser()) {
      isafeResponse = await BridgeService?.authenticateRequestISafe(
        Number(portability?.[0].installmentValue),
      )
    }
    if (status && isafeResponse) {
      const { authenticationToken, authenticationType, authenticationValue } = isafeResponse
      dispatch(
        postIsafeAutorizacao({
          callback: () => {
            setShowISafe(false)
            dispatch(trackingRequest(StepPortabilidadeNovo.REVISAO_ASSINATURA))
            callFinalizar()
          },
          erro: () => {
            setShowISafe(false)
            setVariant(variants.erroAutenticacao)
            setShowBottomSheet(true)
          },
          erroAutorizacao: () => {
            setShowISafe(false)
            setVariant(variants.erroAutorizacao)
            setShowBottomSheet(true)
          },
          tipoAutenticacao: `${authenticationType || ''}`,
          token: `${authenticationToken || ''}`,
          valor: `${authenticationValue || ''}`,
          conta,
        }),
      )
    } else {
      setShowISafe(false)
      setVariant(variants.erroAutenticacao)
      setShowBottomSheet(true)
    }
  }

  const iSafeAuthenticate = async () => Promise.resolve()

  const contratar = () => {
    if (isApp || !BridgeService.isBrowser()) {
      setShowISafe(true)
    } else {
      dispatch(trackingRequest(StepPortabilidadeNovo.REVISAO_ASSINATURA))
      callFinalizar()
    }
  }

  return (
    <NovoPage
      id="revisao-simulacao"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      titleHeader="Revisão e assinatura"
      stickyFooter={
        <>
          <Separator margin="8px -24px" height="1px" />
          <Text variant="caption-1">
            Ao clicar em confirmar contratação, eu reconheço que li e concordo com os termos acima.
          </Text>
          <Button
            disabled={
              loadingBiometricsValidate ||
              showBottomSheetRefazerBiometria ||
              loadingFinalizarProposta
            }
            isLoading={loadingBiometricsValidate || loadingFinalizarProposta}
            fullWidth
            onClick={() => {
              BridgeService.tic({
                name: 'btn_confirmar_contratacao',
                action: 'confirmar_contratacao',
              })
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: `Revisão ${refinancing ? 'com troco' : 'sem troco'}`,
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Confirmar contratação',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              Tags.enviarTagueamentoWeb({
                element_name: 'Confirmar contratação',
                section_name: `Revisão ${refinancing ? 'com troco' : 'sem troco'}`,
                element_action: 'click button',
                c_page: window.location.href,
                redirect_url: `${window.location.origin}${routes.SUCESSO_V2}`,
                step: 'null',
              })
              contratar()
            }}
          >
            {loadingBiometricsValidate ? 'Validando biometria enviada...' : 'Confirmar contratação'}
          </Button>
        </>
      }
    >
      <Container>
        <Header>
          <Text variant="headline-h1" semiBold colorWeight={500}>
            Para finalizar, toque em Confirmar contratação
          </Text>
        </Header>
        {tipoPortabilidade === TipoPortabilidade.COM_TROCO ? (
          <>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
              <Text variant="headline-h1" semiBold colorWeight={500}>
                {formatCurrency(refinancing?.requestedAmount)}
              </Text>
              <Text variant="body-3">Valor do novo saldo devedor</Text>
            </div>
            <DetalhesRefin refinancing={seguro ? refinancingWithInsurance : refinancing} />
            <SeguroRefin>
              <Text variant="body-2" bold colorWeight={500}>
                Refinanciamento protegido
              </Text>
              <Text variant="body-3">
                Quitação em caso de morte ou invalidez e crédito liberado em até 48 horas.
              </Text>
            </SeguroRefin>
          </>
        ) : (
          <></>
        )}
        <DetalhesPortabilidade
          portabilityType={tipoPortabilidade}
          portabilities={portability}
          benefitNumber={benefitNumber}
        />
        <BoxCustom
          onClick={() => {
            BridgeService.tic({
              name: 'btn_termos',
              action: 'ver_termos',
            })
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: `Revisão ${refinancing ? 'com troco' : 'sem troco'}`,
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Termos da contratação',
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            Tags.enviarTagueamentoWeb({
              element_name: 'Termos da contratação',
              section_name: `Revisão ${refinancing ? 'com troco' : 'sem troco'}`,
              element_action: 'click button',
              c_page: window.location.href,
              redirect_url: `${window.location.origin}${routes.TERMOS_CONTRATACAO_V2}`,
              step: 'null',
            })
            navigate(routes.TERMOS_CONTRATACAO_V2)
          }}
          actived={false}
        >
          <IcContract height={24} width={24} color={Colors.GRAY500} />
          <Text style={{ textAlign: 'start' }} variant="body-3" bold colorWeight={500}>
            Termos da contratação
          </Text>
          <IcChevronRight height={24} width={24} color={Colors.PRIMARY500} />
        </BoxCustom>
      </Container>
      {showISafe ? (
        <ISafeBottomSheet
          authenticate={iSafeAuthenticate}
          onComplete={onAuthenticateComplete}
          onClose={() => setShowISafe(false)}
          description="Para sua segurança estamos autenticando sua transação com o i-safe."
        />
      ) : (
        <></>
      )}
      {showBottomSheet ? (
        <BottomSheetError setShowBottomSheet={setShowBottomSheet} variant={variant} />
      ) : (
        <></>
      )}
      {showBottomSheetRefazerBiometria ? (
        <BottomSheetRefazerBiometria
          setShowBottomSheetRefazerBiometria={setShowBottomSheetRefazerBiometria}
          redirectBiometria={() => {
            dispatch(
              postBiometriaDadoSave({
                callback: (biometricToken: string) => {
                  dispatch(trackingRequest(StepPortabilidadeNovo.ORIENTACOES_VIDEO_SELFIE))
                  window.location.href = BridgeService.isBrowser()
                    ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioSelecionado}&accessToken=${accessToken}&produto=PORTABILIDADE`
                    : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioSelecionado}&produto=PORTABILIDADE`
                },
              }),
            )
          }}
        />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default RevisaoContratacao
