import styled from 'styled-components'

import { Colors } from '@utils/colors'
import { BoxComponent } from '@atoms/Box/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
  }
`

export const SeguroRefin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > label {
    padding: 16px;
    height: 107px;
    border: 1px solid ${Colors.PRIMARY200};
    border-radius: 8px;
    margin-bottom: 0;
  }
`

export const BoxCustom = styled(BoxComponent)`
  margin-top: 40px;
  min-height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
