import { all } from 'typed-redux-saga'

import watchPortabilidade from './portabilidade/saga'
import watchToken from './token/saga'
import watchBiometria from './biometria/saga'
import watchSession from './session/saga'

export default function* rootSaga() {
  yield* all([watchPortabilidade(), watchToken(), watchBiometria(), watchSession()])
}
