export enum HttpMethod {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
}

export enum HttpStatus {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

export type TParamsRequest = {
  method: HttpMethod
  headers?: Record<string, string>
  host: string | unknown
  pathname: string | unknown
  data?: unknown
  engine?: string
}

export type TResponseType<T> = {
  status: number
  data: T
  headers: Record<string, string>
}

export type TPayloadCallbacks<TSuccessData = unknown, TErrorData = unknown> = {
  success?: (data: TSuccessData) => void
  error?: (error: TErrorData) => void
  finally?: () => void
}
