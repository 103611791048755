import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface GetPropostaResponse {
  proposals: {
    contract: string
    proposal: string
  }[]
}

export default async (accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_GET_PROPOSTA}`
    : process.env.REACT_APP_GET_PROPOSTA
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<GetPropostaResponse>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
