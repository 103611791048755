import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface HireData {
  moneyChange: boolean
  withInsurance: boolean
}

export default (data: HireData, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_POST_CONTRATACAO_COMPLETA}`
    : process.env.REACT_APP_POST_CONTRATACAO_COMPLETA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
}
