import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import NovoPage from '@templates/NovoPage'
import { Text } from '@interco/inter-ui/components/Text'
import imageOnboardingV2 from '@images/imageOnboardingV2.png'
import { Image } from '@atoms/Image'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'
import Tags from '@utils/Tags'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { requestSession } from '@store/session/actions'

import { Dot } from './styles'

const Onboarding = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { utmCampaign, loadingSession } = useSelector((state: ApplicationState) => state.session)

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '1',
      name: 'CONSIGNADO_PORT_ACESSO',
      screen: 'Onboarding',
      content_action: 'Dado carregado',
      product: 'PORTABILIDADE_CONSIGNADO',
      utm_campaign: utmCampaign,
    })
  }, [utmCampaign])

  return (
    <NovoPage
      id="onboarding-v2"
      stickyFooter={
        <Button
          isLoading={loadingSession}
          disabled={loadingSession}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidadeNovo.ONBOARDING))
            BridgeService.tic({
              name: 'btn_continuar',
              action: 'continuar_portabilidade',
            })
            Tags.enviarTagueamentoApp({
              ref_figma: '2',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Onboarding',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Continuar',
              product: 'PORTABILIDADE_CONSIGNADO',
            })
            dispatch(
              requestSession({
                callback: () => {
                  navigate(routes.SELECAO_GRUPO_V2)
                },
              }),
            )
          }}
        >
          Continuar
        </Button>
      }
    >
      <Image src={imageOnboardingV2} alt="Homem olhando o celular" />
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '24px 0',
        }}
      >
        Traga seus contratos e refinancie com troco e as melhores taxas!
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '0 0 16px 0',
        }}
      >
        <Dot />
        <Text variant="body-3">Processo 100% digital e seguro</Text>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 0 8px 0' }}
      >
        <Dot />
        <Text variant="body-3">Dinheiro rápido na sua conta</Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Dot />
        <div style={{ width: '80%', margin: '0 0 16px 0' }}>
          <Text variant="body-3">
            Tranquilidade com parcelas descontadas diretamente do seu benefício ou folha de
            pagamento.
          </Text>
        </div>
      </div>
    </NovoPage>
  )
}
export default Onboarding
