import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@interco/inter-ui/components/Button'
import { Timeline } from '@interco/inter-ui/components/Timeline'
import { Text } from '@interco/inter-ui/components/Text'
import MoneyCheck from '@interco/icons/core/finance/ic_money_check_check'
import Check from '@interco/icons/core/action-navigation/ic_check'
import Lock from '@interco/icons/core/privacy-security/ic_padlock'
import Fingerprint from '@interco/icons/core/privacy-security/ic_fingerprint'
import { Input } from '@interco/inter-ui/components/Input'
import { ApplicationState } from '@store/types'
import { formatCurrency } from '@utils/masks'
import { BridgeService } from '@services/bridge'
import { setSalario } from '@store/portabilidade/dadosProfissionais/actions'
import routes from '@routes/routes'
import { openLinkByEnviroment } from '@utils/functions'
import { atualizarCacheSiapeRequest } from '@store/portabilidade/consultaMargemGeral/actions'
import NovoPage from '@templates/NovoPage'
import { ErrorCodesAPI } from '@utils/enums'
import { Colors } from '@utils/colors'
import {
  requestMarginAccept,
  requestMarginResult,
} from '@store/portabilidade/consultaMarginQuery/actions'
import Tags from '@utils/Tags'

import { Container, TextLink, TimelineItemContent } from './styles'
import BottomsheetAutorizar from './BottomsheetAutorizar'
import { TimelineItem } from './TimelineItem'
import BottomsheetPrimeiraTentativa from './BottomsheetPrimeiraTentativa'
import BottomsheetSegundaTentativa from './BottomsheetSegundaTentativa'

const ConsultaMargem = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [salaryCheck, setSalaryCheck] = useState(false)
  const [consultaDeMargemJaAutorizada, setConsultaDeMargemJaAutorizada] = useState<boolean | null>(
    null,
  )
  const [showBottonSheetSiape, setshowBottonSheetSiape] = useState(false)
  const [showBottonSheetPrimeiraTentativa, setshowBottonSheetPrimeiraTentativa] = useState(false)
  const [showBottonSheetSegundaTentativa, setshowBottonSheetSegundaTentativa] = useState(false)
  const { salario } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { dataValidadeAutorizacao } = useSelector(
    (state: ApplicationState) => state.portabilidade.consultaMargemGeral,
  )
  const { loadingQuery, marginSuccess } = useSelector(
    (state: ApplicationState) => state.portabilidade.consultaMarginQuery,
  )
  const { cpf } = useSelector((state: ApplicationState) => state.portabilidade.dadosPessoais)

  useEffect(() => {
    BridgeService.tac({
      name: 'consulta_margem_siape',
      actions: ['selecionar_convenio'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Autorizacao de margem',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Siga as etapas para simular',
      product: `PORTABILIDADE_CONSIGNADO_SIAPE`,
    })
  }, [])

  useEffect(() => {
    setSalaryCheck(Number(salario) > 0)
  }, [salario])

  useEffect(() => {
    if (!consultaDeMargemJaAutorizada) {
      setConsultaDeMargemJaAutorizada(marginSuccess)
    }
  }, [marginSuccess, consultaDeMargemJaAutorizada])

  const callSimular = () => {
    BridgeService.tic({
      name: 'btn_continuar',
      action: 'autorizar_termo_siape',
    })
    Tags.enviarTagueamentoApp({
      ref_figma: '3',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Autorizacao de margem',
      ref_type: 'Botao',
      content_action: 'Toque',
      action_id: 'Simular melhor condição',
      product: `PORTABILIDADE_CONSIGNADO_SIAPE`,
    })
    navigate(routes.CARREGAMENTO_DADOS_V2)
  }

  const callWeb = (redirectUrl: string) => {
    Tags.enviarTagueamentoWeb({
      element_name: 'Já autorizei a consulta',
      section_name: 'Autorizacao de margem',
      element_action: 'click button',
      c_page: window.location.href,
      redirect_url: `${window.location.origin}${redirectUrl}`,
      step: 'null',
    })
  }

  const callResult = () => {
    setLoading(false)
    dispatch(
      requestMarginResult({
        convenioDescricao: 'siape',
        callback: (pendencia) => {
          if (pendencia === ErrorCodesAPI.R0002) {
            callWeb(routes.ENTRAREMOS_CONTATO_V2)
            navigate(routes.ENTRAREMOS_CONTATO_V2)
          } else if (pendencia === ErrorCodesAPI.R0003 || pendencia === ErrorCodesAPI.R0004) {
            callWeb(routes.NAO_LIBERADO_V2)
            navigate(routes.NAO_LIBERADO_V2, { state: pendencia })
          } else if (pendencia === ErrorCodesAPI.R0005) {
            setshowBottonSheetPrimeiraTentativa(true)
          } else if (pendencia === ErrorCodesAPI.R0006) {
            setshowBottonSheetSegundaTentativa(true)
          } else if (pendencia === ErrorCodesAPI.R0007) {
            callWeb(routes.DADOS_MANUAIS_SIAPE_V2)
            navigate(routes.DADOS_MANUAIS_SIAPE_V2)
          }
        },
      }),
    )
  }

  const consultarMargem = () => {
    setLoading(true)
    dispatch(
      requestMarginAccept({
        convenioDescricao: 'siape',
        callback: () => {
          setTimeout(() => {
            callResult()
          }, 6000)
        },
        callbackError: () => {
          setLoading(false)
        },
      }),
    )
  }

  const getMargemAutorizada = () => (
    <TimelineItem
      verticalBar={!!consultaDeMargemJaAutorizada}
      title="Consulta de margem autorizada"
      type="default"
      icon={<Check height={24} width={24} color="green" />}
    >
      <TimelineItemContent>
        <Text variant="body-3">
          Você poderá simular novamente sem precisar autorizar a consulta no{' '}
          <span style={{ fontWeight: 700 }}>SouGov</span> até:{' '}
          <span style={{ fontWeight: 700 }}>
            {dataValidadeAutorizacao
              ? new Date(dataValidadeAutorizacao).toLocaleDateString('pt-br')
              : ''}
          </span>
          .
        </Text>
      </TimelineItemContent>
    </TimelineItem>
  )

  const getConsultaMargem = () =>
    marginSuccess ? (
      <>{getMargemAutorizada()}</>
    ) : (
      <TimelineItem
        verticalBar={marginSuccess}
        title="Autorize a consulta de margem"
        type={salaryCheck ? 'default' : 'disabled'}
        icon={
          salaryCheck ? (
            <Fingerprint height={24} width={24} color={Colors.GRAY500} />
          ) : (
            <Lock height={24} width={24} color={Colors.GRAY400} />
          )
        }
      >
        <TimelineItemContent>
          <Text variant="body-3" colorWeight={salaryCheck ? 500 : 400}>
            Após autorizar no SouGov, volte aqui para continuar. Se tiver dúvidas,
            <TextLink
              color={salaryCheck ? Colors.PRIMARY500 : Colors.GRAY400}
              onClickCapture={() => {
                setshowBottonSheetSiape(true)
              }}
            >
              {' '}
              confira as etapas detalhadas
            </TextLink>
            .
          </Text>
          <Button
            disabled={!salaryCheck}
            variant="primary"
            size="small"
            onClick={async () => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Autorizacao de margem',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Acessar SouGov',
                product: `PORTABILIDADE_CONSIGNADO_SIAPE`,
              })
              await openLinkByEnviroment('https://www.gov.br/sougov')
            }}
          >
            Acessar SouGov
          </Button>
          <Button
            variant="secondary"
            size="small"
            disabled={!salaryCheck || loadingQuery}
            isLoading={loadingQuery || loading}
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Autorização de margem',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Já autorizei a consulta',
                product: `PORTABILIDADE_CONSIGNADO_SIAPE`,
              })
              consultarMargem()
            }}
          >
            Já autorizei a consulta
          </Button>
        </TimelineItemContent>
      </TimelineItem>
    )

  const getInputSalario = () => (
    <TimelineItem
      verticalBar={!consultaDeMargemJaAutorizada}
      title={salaryCheck ? 'Você já informou o seu salário' : 'Informe seu salário bruto mensal'}
      icon={
        salaryCheck ? (
          <Check height={24} width={24} color={Colors.SUCCESS500} />
        ) : (
          <MoneyCheck height={24} width={24} color={Colors.GRAY500} />
        )
      }
    >
      <TimelineItemContent>
        <Text variant="body-3">
          {salaryCheck
            ? 'Se precisar, você pode editá-lo abaixo.'
            : 'Essa informação nos ajuda a oferecer a melhor condição possível pra você.'}
        </Text>
        <Input
          id="margem-input"
          placeholder="Insira seu salário bruto"
          type="currency"
          currency="BRL"
          value={formatCurrency(salario)}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = parseFloat(
              value.replace('R$ ', '').replace('.', '').replace(',', '.'),
            )
            if (inputValue === 0.0) setSalaryCheck(false)
            dispatch(setSalario(inputValue))
          }}
          onBlur={({ target: { value } }) => {
            const inputValue = parseFloat(
              value.replace('R$ ', '').replace('.', '').replace(',', '.'),
            )
            setSalaryCheck(inputValue > 0)
          }}
        />
      </TimelineItemContent>
    </TimelineItem>
  )

  return (
    <NovoPage
      id="consulta-margem-siape"
      stickyFooter={
        <Button
          disabled={!salaryCheck || !marginSuccess}
          onClick={() => {
            if (!BridgeService.isBrowser()) {
              dispatch(
                atualizarCacheSiapeRequest({
                  authorizedMargin: true,
                  salary: Number(salario).toFixed(2),
                  socialNumber: cpf,
                  callback: () => {
                    callSimular()
                  },
                }),
              )
            } else {
              callSimular()
            }
          }}
        >
          {BridgeService.isBrowser() ? 'Continuar' : 'Simular melhor condição'}
        </Button>
      }
      positionFooter="sticky"
    >
      <Container>
        <Text variant="headline-h2" semiBold colorWeight={500}>
          Siga as etapas para simular:
        </Text>
        <Timeline>
          {consultaDeMargemJaAutorizada && getMargemAutorizada()}
          {getInputSalario()}
          {!consultaDeMargemJaAutorizada && getConsultaMargem()}
        </Timeline>
        {showBottonSheetSiape && (
          <BottomsheetAutorizar setShowBottomsheetSiape={setshowBottonSheetSiape} />
        )}
        {showBottonSheetPrimeiraTentativa && (
          <BottomsheetPrimeiraTentativa
            consultarMargem={consultarMargem}
            setShowBottomsheet={setshowBottonSheetPrimeiraTentativa}
          />
        )}
        {showBottonSheetSegundaTentativa && (
          <BottomsheetSegundaTentativa
            consultarMargem={consultarMargem}
            setShowBottomsheet={setshowBottonSheetSegundaTentativa}
          />
        )}
      </Container>
    </NovoPage>
  )
}

export default ConsultaMargem
