import getBancos from './getBancos'
import getFontesPagamentos from './getFontesPagamentos'
import getConvenios from './getConvenios'
import getCidades from './getCidades'
import getEstados from './getEstados'
import getOrgaos from './getOrgaos'
import getUpags from './getUpags'
import postSalvarLeadPortabilidade from './postSalvarLeadPortabilidade'
import getValidaCliente from './getValidaCliente'
import postValidaPortabilidade from './postValidaPortabilidade'
import postValidaMatricula from './postValidaMatricula'
import getValidaToken from './getValidaToken'
import postEnviaToken from './postEnviaToken'
import getDadosBeneficiarioInss from './getDadosBeneficiarioInss'
import postInssAsyncStart from './postInssAsyncStart'
import postConsultaMargemGeral from './postConsultaMargemGeral'
import postAtualizaCacheSiape from './postAtualizaCacheSiape'
import getCacheSiape from './getCacheSiape'
import getDadosInssResult from './getDadosInssResult'
import postSimulatePortability from './postSimulatePortability'
import postContractMarginAccept from './postContractMarginAccept'
import postContractMarginResult from './postContractMarginResult'
import getDadosBancariosInss from './getDadosBancariosInss'
import getBuscarProposta from './getBuscarProposta'
import postDetalhesCompletosProposta from './postDetalhesCompletosProposta'
import postSalvarProposta from './postSalvarProposta'
import postCaptureDocuments from './postCaptureDocuments'
import postDocumentosAssinatura from './postDocumentosAssinatura'
import postPropostaFinalizar from './postPropostaFinalizar'
import postEnviarDocumentos from './postEnviarDocumentos'
import getBiometricsValidate from './getBiometricsValidate'
import postBiometriaDados from './postBiometriaDados'
import getEndereco from './getEndereco'
import postRiscoEndereco from './postRiscoEndereco'
import postSendPortabilityLead from './postSendPortabilityLead'
import postSimulatePortabilityComplete from './postSimulatePortabilityComplete'
import getBancosNovo from './getBancosNovo'
import getConvenioByToken from './getConvenioByToken'
import postHireCompletePortability from './postHireCompletePortability'
import postAutorizacaoISafe from './postAutorizacaoISafe'
import postBeginSession from './postBeginSession'

export default {
  getBancos,
  getBancosNovo,
  getFontesPagamentos,
  getConvenios,
  getCidades,
  getEstados,
  getOrgaos,
  getUpags,
  postSalvarLeadPortabilidade,
  getValidaCliente,
  postValidaPortabilidade,
  postValidaMatricula,
  getValidaToken,
  postEnviaToken,
  getDadosBeneficiarioInss,
  postInssAsyncStart,
  postConsultaMargemGeral,
  postAtualizaCacheSiape,
  getCacheSiape,
  getDadosInssResult,
  postContractMarginAccept,
  postContractMarginResult,
  postSimulatePortability,
  getDadosBancariosInss,
  getBuscarProposta,
  postDetalhesCompletosProposta,
  postSalvarProposta,
  postCaptureDocuments,
  postDocumentosAssinatura,
  postPropostaFinalizar,
  postEnviarDocumentos,
  getBiometricsValidate,
  postBiometriaDados,
  getEndereco,
  postRiscoEndereco,
  postSendPortabilityLead,
  postSimulatePortabilityComplete,
  getConvenioByToken,
  postHireCompletePortability,
  postAutorizacaoISafe,
  postBeginSession,
}
