/**
 * Actions Types
 */
export enum ValidaTypes {
  VALIDA_REQUEST = '@valida/VALIDAR_REQUEST',
  VALIDA_SET_LOADING = '@valida/VALIDA_SET_LOADING',
  POST_ISAFE_AUTORIZACAO = '@valida/POST_ISAFE_AUTORIZACAO',
  REQUEST_CONVENIO_BY_TOKEN = '@valida/REQUEST_CONVENIO_BY_TOKEN',

  RESET = 'RESET',
}

export interface CallBackContatoType {
  readonly contato: boolean
}

export type ValidaRequestPayload = {
  callback: (params: CallBackContatoType) => void
}

export interface ValidaState {
  readonly loading: boolean
}

export type ConvenioTokenPayload = {
  accessToken: string
  callback: (convenio: string | number) => void
}

export interface PayloadAutorizacao {
  callback: () => void
  erro: () => void
  erroAutorizacao?: () => void
  token: string
  tipoAutenticacao: string
  valor: string
  conta: string
}
