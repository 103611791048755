import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ConveniosCodes, ErrorCodesAPI } from '@utils/enums'
import { ApplicationState } from '@store/types'
import postSimulatePortabilityComplete from '@services/postSimulatePortabilityComplete'

import { SimulacaoCompletaRequestPayload } from '../types'
import { simulacaoCompletaError, simulacaoCompletaSuccess } from '../actions'

export default function* postSimularPortabilidadeCompletaSaga(action: AnyAction) {
  const payload = action.payload as SimulacaoCompletaRequestPayload
  const { convenioSelecionado } = yield* select(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    const convenioUrl = convenioSelecionado === ConveniosCodes.INSS ? 'inss' : 'siape'
    const { data } = yield call(
      postSimulatePortabilityComplete,
      convenioUrl,
      {
        originBank: payload.originBank,
        contracts: payload.contracts,
      },
      accessToken,
    )
    yield put(simulacaoCompletaSuccess(data))
    yield payload.callback(data.errorCode)
  } catch (error) {
    yield put(simulacaoCompletaError())
    yield payload.callback(ErrorCodesAPI.R0003)
  }
}
