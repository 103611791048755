import { action, Action } from 'typesafe-actions'

import {
  GlobalApiTypes,
  Banco,
  FontePagamento,
  Estado,
  Cidade,
  Upag,
  Convenio,
  Orgao,
  ConvenioRequestPayload,
  BancoNovo,
} from './types'

export const fontesSuccess = (payload: FontePagamento[] | null): Action =>
  action(GlobalApiTypes.FONTES_SUCCESS, payload)
export const fontesError = (): Action => action(GlobalApiTypes.FONTES_ERROR)
export const fontesRequest = (): Action => action(GlobalApiTypes.FONTES_REQUEST)

export const estadosSuccess = (payload: Estado[] | null): Action =>
  action(GlobalApiTypes.ESTADOS_SUCCESS, payload)
export const estadosError = (): Action => action(GlobalApiTypes.ESTADOS_ERROR)
export const estadosRequest = (): Action => action(GlobalApiTypes.ESTADOS_REQUEST)

export const cidadesSuccess = (payload: Cidade[] | null): Action =>
  action(GlobalApiTypes.CIDADES_SUCCESS, payload)
export const cidadesError = (): Action => action(GlobalApiTypes.CIDADES_ERROR)
export const cidadesRequest = (payload: string): Action =>
  action(GlobalApiTypes.CIDADES_REQUEST, payload)

export const bancosSuccess = (payload: Banco[] | null): Action =>
  action(GlobalApiTypes.BANCOS_SUCCESS, payload)
export const bancosError = (): Action => action(GlobalApiTypes.BANCOS_ERROR)
export const bancosRequest = (): Action => action(GlobalApiTypes.BANCOS_REQUEST)

export const bancosSuccessNovo = (payload: BancoNovo[] | null): Action =>
  action(GlobalApiTypes.BANCOS_SUCCESS_NOVO, payload)
export const bancosErrorNovo = (): Action => action(GlobalApiTypes.BANCOS_ERROR_NOVO)
export const bancosRequestNovo = (): Action => action(GlobalApiTypes.BANCOS_REQUEST_NOVO)

export const upagsSuccess = (payload: Upag[] | null): Action =>
  action(GlobalApiTypes.UPAGS_SUCCESS, payload)
export const upgasError = (): Action => action(GlobalApiTypes.UPAGS_ERROR)
export const upagsRequest = (): Action => action(GlobalApiTypes.UPAGS_REQUEST)

export const conveniosSuccess = (payload: Convenio[] | null): Action =>
  action(GlobalApiTypes.CONVENIOS_SUCCESS, payload)
export const conveniosError = (): Action => action(GlobalApiTypes.CONVENIOS_ERROR)
export const conveniosRequest = (payload: ConvenioRequestPayload): Action =>
  action(GlobalApiTypes.CONVENIOS_REQUEST, payload)

export const orgaosSuccess = (payload: Orgao[] | null): Action =>
  action(GlobalApiTypes.ORGAOS_SUCCESS, payload)
export const orgaosError = (): Action => action(GlobalApiTypes.ORGAOS_ERROR)
export const orgaosRequest = (payload: number): Action =>
  action(GlobalApiTypes.ORGAOS_REQUEST, payload)

export const dadosBancariosInssRequest = (): Action =>
  action(GlobalApiTypes.DADOS_BANCARIOS_INSS_REQUEST)

export const dadosBancariosInssSuccess = (): Action =>
  action(GlobalApiTypes.DADOS_BANCARIOS_INSS_SUCCESS)

export const dadosBancariosInssError = (): Action =>
  action(GlobalApiTypes.DADOS_BANCARIOS_INSS_ERROR)

export const setTipoConta = (payload: string | number | undefined): Action =>
  action(GlobalApiTypes.SET_TIPO_CONTA, payload)

export const setTipoCreditoInss = (payload: string | number | undefined): Action =>
  action(GlobalApiTypes.SET_TIPO_CREDITO_INSS, payload)

export const setAgencia = (payload: string): Action => action(GlobalApiTypes.SET_AGENCIA, payload)

export const setConta = (payload: string): Action => action(GlobalApiTypes.SET_CONTA, payload)

export const setDigito = (payload: string): Action => action(GlobalApiTypes.SET_DIGITO, payload)
