import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const DotNumber = styled.div`
  width: 24px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.GRAY500};
`
