import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Alert } from '@interco/inter-ui/components/Alert'
import { Text } from '@interco/inter-ui/components/Text'
import NovoPage from '@templates/NovoPage'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import Tags from '@utils/Tags'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { StepPortabilidadeNovo } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'

import { Dot } from './styles'
import OndeEncontrar from './Bottomsheets/OndeEncontrar'

const DadosNecessarios = [
  'Banco de origem',
  'Número do contrato',
  'Valor da parcela',
  'Saldo devedor',
  'Prazo remanescente',
]

const DicasContratos = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openBottomsheet, setOpenBottomsheet] = useState(false)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  // TODO: de onde vai vir essa informação de mais de um beneficio?
  const maisDeUmBeneficio = false

  useEffect(() => {
    BridgeService.tac({
      name: 'dicas_contratos',
      actions: ['trazer_contrato_resultado'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Tela vazia com dicas',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Adicione os contratos que deseja trazer',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado])

  return (
    <NovoPage
      id="dicas-contratos"
      stickyContainerFooterBottom="10px"
      positionFooter="sticky"
      stickyFooter={
        <>
          {maisDeUmBeneficio ? (
            <Alert type="warning">
              É possível adicionar apenas contratos do mesmo convênio e benefício.
            </Alert>
          ) : (
            <></>
          )}
          <Button
            onClick={() => {
              BridgeService.tic({
                name: 'btn_adicionar_contrato',
                action: 'adicionar_contrato',
              })
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Tela vazia com dicas',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Adicionar contrato',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              Tags.enviarTagueamentoWeb({
                element_name: 'Adicionar contrato',
                section_name: 'Tela vazia com dicas',
                element_action: 'click button',
                c_page: window.location.href,
                redirect_url: `${window.location.origin}${routes.ADICIONAR_CONTRATO_V2}`,
                step: 'null',
              })
              dispatch(trackingRequest(StepPortabilidadeNovo.TELA_DICAS))
              navigate(routes.ADICIONAR_CONTRATO_V2)
            }}
          >
            Adicionar contrato
          </Button>
        </>
      }
    >
      <Text color="typography" colorWeight={500} semiBold variant="headline-h1">
        Adicione os contratos que deseja trazer
      </Text>
      <Text
        variant="body-2"
        color="typography"
        colorWeight={500}
        bold
        style={{
          margin: '24px 0',
        }}
      >
        Você vai precisar desses dados:
      </Text>
      <>
        {DadosNecessarios.map((dado) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0 0 16px 0',
            }}
          >
            <Dot />
            <Text variant="body-3">{dado}</Text>
          </div>
        ))}
      </>
      <Text
        color="primary"
        colorWeight={500}
        bold
        variant="body-3"
        onClick={() => {
          Tags.enviarTagueamentoApp({
            ref_figma: '3',
            name: 'CONSIGNADO_PORT_FLUXO',
            screen: 'Tela vazia com dicas',
            ref_type: 'Botao',
            content_action: 'Toque',
            action_id: 'Saiba como solicitar',
            product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
          })
          setOpenBottomsheet(true)
        }}
      >
        Saiba como solicitar
      </Text>
      {openBottomsheet ? <OndeEncontrar setOpenBottomsheet={setOpenBottomsheet} /> : <></>}
    </NovoPage>
  )
}
export default DicasContratos
