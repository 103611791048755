import React from 'react'

import { useSelector } from 'react-redux'

import { formatCurrency, PagePdf, TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { ConvertDateUTC, DateFormat } from '@utils/functions'

const PortabilidadeNovo = () => {
  const variant = 'web'
  const { dadosProposta } = useSelector((state: ApplicationState) => state.portabilidade.proposta)

  return (
    <PagePdf variant={variant}>
      <TemplatesPDF.PropostaPortabilidadePageNovo
        variant={variant}
        solicitante={{
          cpf: dadosProposta.cpf,
          nome: dadosProposta.nomeCliente,
          telefone: `${dadosProposta.dddCelular} ${dadosProposta.telefoneCelular}`,
          email: dadosProposta.email,
          nomeSocial: '',
        }}
        representante={{
          cpf: '',
          nome: '',
          nomeSocial: '',
          docIdentificacao: '',
          checkBoxes: [
            { checked: false, description: 'Sim', name: 'Sim' },
            { checked: true, description: 'Não', name: 'Não' },
          ],
        }}
        ifCredora={{
          razaoSocial: dadosProposta.bancoPortadoDescricao || '',
          cnpj: dadosProposta.cnpjFinanceiraCredorPortabilidade || '',
          numeroContrato: dadosProposta.codigoContratoOriginalPortabilidade || '',
          modalidade: '',
          saldoDevedor: dadosProposta?.valorSaldoDevedorPortabilidade
            ? formatCurrency(
                parseFloat(
                  dadosProposta?.valorSaldoDevedorPortabilidade
                    ?.replace('.', '')
                    ?.replace(',', '.'),
                ),
              )
            : '',
          parcelasRemanescentes: String(dadosProposta.quantidadeDeParcelas || ''),
        }}
        responsavelAtendimento={{
          razaoSocial: 'BANCO INTER S/A',
          cnpj: '00.416.968/0001-01',
          nomeCompletoAgente: '',
          cpfAgente: '',
          checkBoxes: [
            { checked: false, description: 'Correspondente', name: 'Correspondente' },
            { checked: true, description: 'Outros', name: 'Outros' },
          ],
        }}
        ifProponente={{
          razaoSocial: 'BANCO INTER S/A',
          cnpj: '00.416.968/0001-01',
          endereco: 'Av Barbacena',
          numero: '1219',
          complemento: '',
          bairro: 'Santo Agostinho',
          cidade: 'Belo Horizonte',
          estado: 'MG',
          cep: '30190-131',
          dataSimulacao: dadosProposta?.dataEmissao
            ? DateFormat(ConvertDateUTC(dadosProposta?.dataEmissao), 'dd/MM/yyyy')
            : '',
          dataVencimentoPrimeiraParcela: dadosProposta?.primeiroDesconto
            ? DateFormat(ConvertDateUTC(dadosProposta?.primeiroDesconto), 'MM/yyyy')
            : '',
          dataVencimentoUltimaParcela: dadosProposta?.ultimoDesconto
            ? DateFormat(ConvertDateUTC(dadosProposta?.ultimoDesconto), 'MM/yyyy')
            : '',
          saldoDevedor: dadosProposta?.valorSaldoDevedorPortabilidade
            ? formatCurrency(
                parseFloat(
                  dadosProposta?.valorSaldoDevedorPortabilidade
                    ?.replace('.', '')
                    ?.replace(',', '.'),
                ),
              )
            : '',
          maximoCobertura: '',
          valorParcela: formatCurrency(
            parseFloat(dadosProposta.valorParcela?.replace('.', '')?.replace(',', '.')),
          ),
          numeroParcelas: String(dadosProposta.quantidadeDeParcelas || ''),
          taxaJurosEfetivaAM: dadosProposta?.taxaEfetivaMensal
            ? `${parseFloat(dadosProposta?.taxaEfetivaMensal).toFixed(2)}%`
            : '',
          taxaJurosEfetivaAA: dadosProposta?.taxaEfetivaAnual
            ? `${parseFloat(dadosProposta?.taxaEfetivaAnual).toFixed(2)}%`
            : '',
          taxaNominalAA: dadosProposta?.taxaNominalAnual
            ? `${parseFloat(dadosProposta?.taxaNominalAnual).toFixed(2)}%`
            : '',
          cetAA: `${dadosProposta.atributosCetAnual}%`,
          baseRemuneracao: '',
          sistPagamento: '',
          condicoes: '',
        }}
        assinatura={{
          isDgAssinado: false,
          cidade: 'Belo Horizonte',
          data: new Date(),
          cpfCliente: dadosProposta.cpf,
          cpfRogado: '',
          nomeRogado: '',
          nomeSocialRogado: '',
          codigoAutenticacao: '',
        }}
      />
    </PagePdf>
  )
}
export default PortabilidadeNovo
