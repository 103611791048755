import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface IBiometriaSave {
  proposalCode: string
  isFullCaptureFlow: boolean
}

export interface IResponseBiometriaToken {
  biometricToken: string
}

export default async (data: IBiometriaSave, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_BIOMETRICS_DADOS_WEB}`
    : process.env.REACT_APP_BIOMETRICS_DADOS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  const response = await BaseServices.request<IResponseBiometriaToken>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
