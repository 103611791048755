import { BaseServices, HttpMethod } from '@services/base'
import { EnderecoAPI } from '@store/portabilidade/endereco/types'

export default (cep: string) => {
  const pathname = `${process.env.REACT_APP_ZIPCODE_BFF}?cep=${cep}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const response = BaseServices.request<EnderecoAPI>({
    pathname,
    method,
    host,
  })
  return response
}
