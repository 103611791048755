/**
 * Actions Types
 */

export enum DocumentosTypes {
  SET_TIPO_DOCUMENTO = '@portabilidade/documentos/SET_TIPO_DOCUMENTO',

  SHOW_LOADING = '@portabilidade/documentos/SHOW_LOADING',
  HIDE_LOADING = '@portabilidade/documentos/HIDE_LOADING',

  CAPTURA_DOCUMENTOS_REQUEST = '@portabilidade/documentos/CAPTURA_DOCUMENTOS_REQUEST',
  CARREGAR_CAPTURA_REQUEST = '@portabilidade/documentos/CARREGAR_CAPTURA_REQUEST',
  DOCUMENTOS_ASSINATURA_REQUEST = '@portabilidade/documentos/DOCUMENTOS_ASSINATURA_REQUEST',
  BUSCAR_DOCUMENTOS_SAGA = '@portabilidade/documentos/BUSCAR_DOCUMENTOS_SAGA',

  RESET = 'RESET',

  SET_ETAPA_BUSCA = '@portabilidade/documentos/SET_ETAPA_BUSCA',
  SET_ERRO_UPDATE_PROPOSAL = '@portabilidade/documentos/SET_ERRO_UPDATE_PROPOSAL',
}

/**
 * State Type
 */

export interface DocumentosState {
  readonly tipoDocumento: string
  readonly loading: boolean
  readonly etapaBusca: number
  readonly erroUpdateProposal: boolean
}

export interface BuscarPayload {
  callback?: () => void
}
