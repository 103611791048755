import React, { memo } from 'react'

import { CheckboxProps } from '../Checkbox'
import * as S from './styles'

export type SwitchProps = Omit<CheckboxProps, 'indeterminate'>

export const Component = ({
  label = '',
  id,
  checked,
  disabled = false,
  onChange,
  reversed = false,
  ...attrs
}: SwitchProps) => (
  <S.Container disabled={disabled} htmlFor={id}>
    {reversed && label && (
      <S.Label reversed={reversed} data-testid="reversed-switch-label">
        {label}
      </S.Label>
    )}

    <S.Input
      {...attrs}
      id={id}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event)}
    />

    <S.Body>
      <S.Bar checked={!!checked} />
      <S.Circle checked={!!checked} />
    </S.Body>

    {!reversed && label && (
      <S.Label reversed={reversed} data-testid="switch-label">
        {label}
      </S.Label>
    )}
  </S.Container>
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-switch-since-v1-0-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3465&t=3Cq4Nk8a0OE83e3Z-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Switch } from '@interco/inter-ui/components/Switch'
 *
 * export const MyPage = () => {
 *   const [isChecked, setIsChecked] = useState<boolean>(false)
 *
 *   return (
 *     <Switch
 *       id="simple"
 *       checked={isChecked}
 *       onChange={() => setIsChecked(!isChecked)}
 *     />
 *   )
 * }
 * ```
 */
export const Switch = memo(Component)
