import styled from 'styled-components'

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
`

export const LineInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
`
