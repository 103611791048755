import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

import { ConveniosCodes, TipoDocumento, TipoMiniatura, StepPortabilidadeNovo } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { Camera } from '@interco/cp-react-ui-lib'
import { setHideFooter, setHideHeader } from '@store/ui/navigation/actions'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
  setRollbackRouterError,
} from '@store/portabilidade/fotoDocumentos/actions'
import { ReviewFoto } from '@organisms/ReviewFoto'
import { postBiometriaDadoSave } from '@store/biometria/actions'
import NovoPage from '@templates/NovoPage'
import routes from '@routes/routes'
import { useWindowSize } from '@hooks'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'

const FotoDocumento = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [habilitaCamera, setHabilitaCamera] = useState(true)
  const [foto, setFoto] = useState('')
  const [descricaoFoto, setDescricaoFoto] = useState('Frente')
  const [fotoFrente, setFotoFrente] = useState(false)
  const [fullScreenMobile, setFullScreenMobile] = useState(false)

  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.portabilidade.fotoDocumentos,
  )
  const { convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { tipoDocumento } = useSelector((state: ApplicationState) => state.portabilidade.documentos)

  const { isApp, origem } = useSelector((state: ApplicationState) => state.ui.navigation)

  const { accessToken } = useSelector((state: ApplicationState) => state.session)

  const size = useWindowSize()

  if (!BridgeService.isBrowser()) {
    interWbSession.editToolbar('', false)
  }

  useEffect(() => {
    BridgeService.tac({
      name: 'foto_documento',
      actions: ['tirar_foto'],
    })
  }, [])

  useEffect(() => {
    if (size.width < 680) {
      setFullScreenMobile(true)
      dispatch(setHideFooter(true))
      dispatch(setHideHeader(true))
    } else {
      setFullScreenMobile(false)
      dispatch(setHideFooter(false))
      dispatch(setHideHeader(false))
    }
    return () => {
      setFullScreenMobile(false)
    }
  }, [dispatch, size])

  useEffect(() => {
    if (isDone) {
      if (convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
        navigate(routes.STATUS_DOCUMENTO_V2)
      } else {
        BridgeService.tic({
          name: 'btn_solicitar_biometria',
          action: 'solicitar_biometria',
        })
        dispatch(
          postBiometriaDadoSave({
            callback: (biometricToken: string) => {
              dispatch(trackingRequest(StepPortabilidadeNovo.ORIENTACOES_VIDEO_SELFIE))
              window.location.href = BridgeService.isBrowser()
                ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioSelecionado}&accessToken=${accessToken}&produto=PORTABILIDADE`
                : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioSelecionado}&produto=PORTABILIDADE`
            },
          }),
        )
      }
    }
  }, [accessToken, convenioSelecionado, dispatch, isDone, navigate, origem])

  const onConfirmReview = (fotografia: string) => {
    if (anexos.length === 2) {
      dispatch(setIsLoading(true))
      dispatch(setIsError(false))
      dispatch(setRollbackRouterError(routes.STATUS_DOCUMENTO_V2))
      dispatch(sendToS3({ tipo: 'RG_CNH', anexos }))
    } else {
      const img = new Image()
      img.onload = () => {
        dispatch(
          setAnexos({
            anexo: {
              id: uuidv4(),
              nome: `RG/CNH.jpeg`,
              tipoExtensao: 'jpeg',
              blobs: [
                {
                  height: img.height,
                  width: img.width,
                  data: fotografia,
                },
              ],
            },
            tipo: 'RG_CNH',
          }),
        )
        if (anexos.length === 2) {
          dispatch(setIsLoading(true))
          dispatch(setIsError(false))
          dispatch(setRollbackRouterError(routes.STATUS_DOCUMENTO_V2))
          dispatch(sendToS3({ tipo: 'RG_CNH', anexos }))
        }
        setFotoFrente(true)
        setDescricaoFoto('Verso')
      }
      img.src = fotografia
    }
  }

  useEffect(() => {
    if (fotoFrente) {
      setHabilitaCamera(true)
    }
  }, [fotoFrente])

  const tipoTexto = () => {
    if (tipoDocumento === TipoDocumento.CNH) {
      return `${descricaoFoto} da ${tipoDocumento?.toUpperCase()}`
    }
    return `${descricaoFoto} do ${tipoDocumento?.toUpperCase()}`
  }

  const tipoMiniatura = () => {
    if (descricaoFoto === 'Frente') {
      if (tipoDocumento === TipoDocumento.RG) return TipoMiniatura.RG_FRENTE
      if (tipoDocumento === TipoDocumento.CNH) return TipoMiniatura.CNH_FRENTE
      return TipoMiniatura.RNE_FRENTE
    }
    if (tipoDocumento === TipoDocumento.RG) return TipoMiniatura.RG_VERSO
    if (tipoDocumento === TipoDocumento.CNH) return TipoMiniatura.CNH_VERSO
    return TipoMiniatura.RNE_VERSO
  }

  return habilitaCamera ? (
    <NovoPage id="foto-documento" headerPosition="fixed">
      <Camera
        audio={false}
        mask="secondary"
        mode="photo"
        facing="back"
        mobile={isApp || fullScreenMobile}
        onCapturedPhoto={(fotografia) => {
          setHabilitaCamera(false)
          setFoto(String(fotografia))
        }}
        titleDescription={tipoTexto()}
        imagemMiniatura={tipoMiniatura()}
      />
    </NovoPage>
  ) : (
    <ReviewFoto
      fotografia={foto}
      onConfirm={onConfirmReview}
      setHabilitaCamera={() => {
        setHabilitaCamera(true)
      }}
      isLoading={isLoading}
      forceIsMobile={fullScreenMobile}
    />
  )
}

export default FotoDocumento
