import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Radio } from '@interco/inter-ui/components/Radio'
import { Switch } from '@interco/inter-ui/components/Switch'
import { StepPortabilidadeNovo, TipoPortabilidade } from '@utils/enums'
import { Tag } from '@interco/inter-ui/components/Tag'
import { ApplicationState } from '@store/types'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { BridgeService } from '@services/bridge'
import Tags from '@utils/Tags'
import routes from '@routes/routes'
import {
  contratacaoCompletaRequest,
  setSeguroRefinPortabilidade,
  setTipoPortabilidade,
} from '@store/portabilidade/simulacaoCompleta/actions'

import { Container, Header, GroupRadio, SeguroRefin, SwitchLabel } from './styles'
import DetalhesRefin from './DetalhesRefin'
import DetalhesPortabilidade from './DetalhesPortabilidade'
import BottomsheetContratoIndisponivel from './BottomsheetContratoIndisponivel'

const RevisaoSimulacao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showBottomsheetContratoIndisponivel, setShowBottomsheetContratoIndisponivel] =
    useState(false)
  const {
    refinancing,
    refinancingWithInsurance,
    portability,
    loadingContratacaoCompleta,
    benefitNumber,
    unavailableContracts,
    tipoPortabilidade,
    seguro,
  } = useSelector((state: ApplicationState) => state.portabilidade.simulacaoCompleta)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'revisao_simulacao',
      actions: ['retornar_resultado_simulacao'],
    })
  }, [])

  useEffect(() => {
    if (unavailableContracts && unavailableContracts?.length > 0) {
      setShowBottomsheetContratoIndisponivel(true)
    }
  }, [unavailableContracts])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Resultado da simulacao - revisao',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: refinancing
        ? 'Os valores são aproximados, caem na sua conta e podem variar por convênio.'
        : 'Os valores são aproximados e podem variar por convênio.',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado, refinancing])

  return (
    <NovoPage
      id="revisao-simulacao"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      stickyFooter={
        <Button
          fullWidth
          isLoading={loadingContratacaoCompleta}
          disabled={loadingContratacaoCompleta}
          onClick={() => {
            dispatch(trackingRequest(StepPortabilidadeNovo.RESULTADO_SIMULACAO_2))
            BridgeService.tic({
              name: 'btn_solicitar_contratacao',
              action: 'solicitar_contratacao',
            })
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Resultado da simulacao - revisao',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: `Solicitar contratação ${
                tipoPortabilidade === TipoPortabilidade.COM_TROCO ? 'com troco' : 'sem troco'
              }`,
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            Tags.enviarTagueamentoWeb({
              element_name:
                tipoPortabilidade === TipoPortabilidade.COM_TROCO
                  ? 'Solicitar contratação com troco'
                  : 'Solicitar contratação sem troco',
              section_name: 'Resultado da simulacao - revisao',
              element_action: 'click button',
              c_page: window.location.href,
              redirect_url: `${window.location.origin}${routes.ENDERECO_V2}`,
              step: 'null',
            })
            if (BridgeService.isBrowser()) {
              navigate(routes.ENDERECO_V2)
            } else {
              dispatch(
                contratacaoCompletaRequest({
                  withInsurance: tipoPortabilidade === TipoPortabilidade.COM_TROCO ? seguro : false,
                  moneyChange: Boolean(tipoPortabilidade === TipoPortabilidade.COM_TROCO),
                  callback: () => {
                    navigate(routes.CARREGAMENTO_DADOS_FORMALIZACAO_V2)
                  },
                }),
              )
            }
          }}
        >
          {tipoPortabilidade === TipoPortabilidade.COM_TROCO
            ? 'Solicitar contratação com troco'
            : 'Solicitar contratação sem troco'}
        </Button>
      }
    >
      <Container>
        <Header>
          <Text variant="headline-h1" semiBold colorWeight={500}>
            Trazer contrato
          </Text>
          {refinancing ? (
            <GroupRadio>
              <Radio
                id="radio-com-troco"
                value={TipoPortabilidade.COM_TROCO}
                name="com-troco"
                checked={tipoPortabilidade === TipoPortabilidade.COM_TROCO}
                onClick={() => {
                  Tags.enviarTagueamentoApp({
                    ref_figma: '3',
                    name: 'CONSIGNADO_PORT_FLUXO',
                    screen: 'Resultado da simulacao - revisao',
                    ref_type: 'Radio',
                    content_action: 'Toque',
                    action_id: 'Com troco',
                    product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                  })
                  dispatch(setTipoPortabilidade(TipoPortabilidade.COM_TROCO))
                }}
                label={
                  <Text variant="body-3" bold colorWeight={500}>
                    Com troco
                  </Text>
                }
              />
              <Radio
                id="radio-sem-troco"
                value={TipoPortabilidade.SEM_TROCO}
                name="sem-troco"
                checked={tipoPortabilidade === TipoPortabilidade.SEM_TROCO}
                onClick={() => {
                  Tags.enviarTagueamentoApp({
                    ref_figma: '3',
                    name: 'CONSIGNADO_PORT_FLUXO',
                    screen: 'Resultado da simulacao - revisao',
                    ref_type: 'Radio',
                    content_action: 'Toque',
                    action_id: 'Sem troco',
                    product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                  })
                  dispatch(setTipoPortabilidade(TipoPortabilidade.SEM_TROCO))
                }}
                label={
                  <Text variant="body-3" bold colorWeight={500}>
                    Sem troco
                  </Text>
                }
              />
            </GroupRadio>
          ) : (
            <></>
          )}
        </Header>
        {tipoPortabilidade === TipoPortabilidade.COM_TROCO ? (
          <>
            <DetalhesRefin refinancing={seguro ? refinancingWithInsurance : refinancing} />
            <SeguroRefin>
              <Text variant="body-2" bold colorWeight={500}>
                Refinanciamento protegido
              </Text>
              <Switch
                id="simple"
                label={
                  <SwitchLabel>
                    <Tag size="small" theme="green" intensity="dark">
                      Taxa com desconto
                    </Tag>
                    <Text variant="body-3">
                      Quitação em caso de morte ou invalidez e crédito liberado em até 48 horas.
                    </Text>
                  </SwitchLabel>
                }
                checked={seguro}
                onChange={() => {
                  Tags.enviarTagueamentoApp({
                    ref_figma: '7',
                    name: 'CONSIGNADO_PORT_FLUXO',
                    screen: 'Revisao com troco',
                    ref_type: 'Botao',
                    content_action: 'Toque',
                    action_id: 'Refinanciamento protegido',
                    favorite: String(seguro),
                    product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                  })
                  dispatch(setSeguroRefinPortabilidade(!seguro))
                }}
                reversed
              />
            </SeguroRefin>
          </>
        ) : (
          <></>
        )}
        <DetalhesPortabilidade
          portabilityType={tipoPortabilidade}
          portabilities={portability}
          benefitNumber={benefitNumber}
          canChangeContract
        />
      </Container>
      {showBottomsheetContratoIndisponivel && unavailableContracts ? (
        <BottomsheetContratoIndisponivel
          contratos={unavailableContracts}
          setShowBottomSheet={setShowBottomsheetContratoIndisponivel}
        />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default RevisaoSimulacao
