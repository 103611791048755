import styled, { css } from 'styled-components'

import Info from '@interco/icons/build-v4/bidis/v2/action/stats/info'
import Error from '@interco/icons/build-v4/bidis/v2/action/stats/error'
import Warning from '@interco/icons/build-v4/bidis/v2/action/stats/warning'
import CheckFill from '@interco/icons/build-v4/orangeds/MD/check-fill'
import Help from '@interco/icons/build-v4/bidis/v2/action/help'

import { AlertProps } from './types'

export type StyledAlertProps = Pick<AlertProps, 'customBackgroundColor' | 'type'>

const modifiers = {
  success: () => css`
    background-color: var(--alert-success-background-color);
  `,
  warning: () => css`
    background-color: var(--alert-warning-background-color);
  `,
  error: () => css`
    background-color: var(--alert-error-background-color);
  `,
  grayscale: () => css`
    background-color: var(--alert-grayscale-background-color);
  `,
  info: () => css`
    background-color: var(--alert-info-background-color);
  `,
}

export const Container = styled.div<StyledAlertProps>`
  ${({ type = 'success', customBackgroundColor }) => css`
    border-radius: var(--radiiMd);
    position: relative;
    padding: 16px;
    display: flex;

    ${customBackgroundColor
      ? css`
          background-color: ${customBackgroundColor};
        `
      : modifiers[type]}
  `}
`

export const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const CloseButton = styled.button`
  background-color: transparent;
  display: flex;
  margin-left: 16px;

  &:hover {
    cursor: pointer;
    opacity: 30%;
  }
`

export const SuccessIconColor = styled(CheckFill)`
  & > path:first-child {
    fill: var(--alert-success-icon-color);
  }
`
export const WarningIconColor = styled(Warning)`
  & > path:first-child {
    fill: var(--alert-warning-icon-color);
  }
`
export const ErrorIconColor = styled(Error)`
  & > path:first-child {
    fill: var(--alert-error-icon-color);
  }
`
export const GrayscaleIconColor = styled(Info)`
  & > path:first-child {
    fill: var(--alert-grayscale-icon-color);
  }
`
export const InfoIconColor = styled(Help)`
  & > path:first-child {
    fill: var(--alert-info-icon-color);
  }
`

export const IconContainer = styled.div`
  margin-right: 16px;
  width: 16px;
  height: 16px;
`
