import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (convenioDescricao: string, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_MARGIN_ACCEPT_RESULT}/${convenioDescricao}/query/accept`
    : `${process.env.REACT_APP_MARGIN_ACCEPT_RESULT}/${convenioDescricao}/query/accept`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
}
