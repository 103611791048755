import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { formatCurrency } from '@utils/masks'
import { RefinancingType } from '@store/portabilidade/simulacaoCompleta/types'
import { formatDecimalBr } from '@utils/functions'

import { Container } from './styles'
import { BoxLine, Header } from '../styles'

interface OwnProps {
  refinancing?: RefinancingType
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetSaibaMais = ({ refinancing, setShowBottomSheet }: OwnProps) => (
  <BottomSheet onClose={() => setShowBottomSheet(false)} noSwipe>
    <Container>
      <Header>
        <Text variant="body-2" bold colorWeight={500}>
          Detalhes do contrato com troco
        </Text>
      </Header>
      <BoxLine>
        <Text variant="body-3">Troco aproximado</Text>
        <Text variant="body-3" bold colorWeight={500} style={{ color: '#00AA4F' }}>
          {formatCurrency(refinancing?.moneyChange)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Parcelas mensais </Text>
        <Text variant="body-3" bold colorWeight={500}>
          {refinancing?.numberOfInstallments}x {formatCurrency(refinancing?.installmentValue)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Taxa nominal (a.m.)</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatDecimalBr(refinancing?.nominalRateAM)}%
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Valor IOF</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatCurrency(refinancing?.iofValue)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">CET (a.m.)</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatDecimalBr(refinancing?.cetAm)}%
        </Text>
      </BoxLine>
      {refinancing && refinancing?.insuranceFee > 0 ? (
        <>
          <BoxLine>
            <Text variant="body-3">Valor do seguro</Text>
            <Text variant="body-3" bold colorWeight={500}>
              {formatCurrency(refinancing?.insuranceFee)}
            </Text>
          </BoxLine>
        </>
      ) : (
        <></>
      )}
      <BoxLine>
        <Text variant="body-3">Novo saldo devedor</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatCurrency(refinancing?.requestedAmount)}
        </Text>
      </BoxLine>

      <Button fullWidth style={{ marginTop: '8px' }} onClick={() => setShowBottomSheet(false)}>
        Entendi
      </Button>
    </Container>
  </BottomSheet>
)

export default BottomsheetSaibaMais
