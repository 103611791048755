import { Reducer } from 'redux'

import { ContratoAtualState, ContratoAtualTypes } from './types'

const INITIAL_STATE: ContratoAtualState = {
  bancoSelecionado: '',
  valorParcela: '',
  loading: false,
  error: false,
  numeroContrato: '',
  saldoDevedor: undefined,
  parcelasRestantes: undefined,
  contratosAtuais: [],
}

const reducer: Reducer<ContratoAtualState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContratoAtualTypes.SET_BANCO_SELECIONADO:
      return { ...state, bancoSelecionado: action.payload }
    case ContratoAtualTypes.SET_NUMERO_CONTRATO:
      return { ...state, numeroContrato: action.payload }
    case ContratoAtualTypes.SET_VALOR_PARCELA:
      return { ...state, valorParcela: action.payload }
    case ContratoAtualTypes.SET_SALDO_DEVEDOR:
      return { ...state, saldoDevedor: action.payload }
    case ContratoAtualTypes.SET_PARCELAS_RESTANTES:
      return { ...state, parcelasRestantes: action.payload }
    case ContratoAtualTypes.ADD_CONTRATO_ATUAL:
      return { ...state, contratosAtuais: action.payload }
    case ContratoAtualTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
