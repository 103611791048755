/**
 * Actions Types
 */

export enum SimulacaoCompletaTypes {
  SIMULACAO_COMPLETA_REQUEST = '@simulacaoCompleta/SIMULACAO_COMPLETA_REQUEST',
  SIMULACAO_COMPLETA_SUCCESS = '@simulacaoCompleta/SIMULACAO_COMPLETA_SUCCESS',
  SIMULACAO_COMPLETA_ERROR = '@simulacaoCompleta/SIMULACAO_COMPLETA_ERROR',

  CONTRATACAO_COMPLETA_REQUEST = '@simulacaoCompleta/CONTRATACAO_COMPLETA_REQUEST',
  CONTRATACAO_COMPLETA_SUCCESS = '@simulacaoCompleta/CONTRATACAO_COMPLETA_SUCCESS',
  CONTRATACAO_COMPLETA_ERROR = '@simulacaoCompleta/CONTRATACAO_COMPLETA_ERROR',
  SET_TIPO_PORTABILIDADE = '@simulacaoCompleta/SET_TIPO_PORTABILIDADE',
  SET_SEGURO_REFIN_PORTABILIDADE = '@simulacaoCompleta/SET_SEGURO_REFIN_PORTABILIDADE',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface RefinancingType {
  contractNumber: string
  moneyChange: number
  numberOfInstallments: number
  installmentValue: number
  nominalRateAM: number
  iofValue: number
  cetAm: number
  insuranceFee: number
  requestedAmount: number
}

export interface PortabilityContractsType {
  contractNumber: string
  numberOfInstallments: number
  installmentValue: number
  nominalRateAM: number
  iofValue: number
  cetAM: number
  debtBalance: number
}

export interface UnavailableType {
  contractNumber: string
}

export interface SimulacaoCompletaState {
  readonly errorCode: string
  readonly benefitNumber: string
  readonly refinancing?: RefinancingType
  readonly refinancingWithInsurance?: RefinancingType
  readonly portability?: PortabilityContractsType[]
  readonly unavailableContracts?: UnavailableType[]
  readonly loading: boolean
  readonly loadingContratacaoCompleta: boolean
  readonly tipoPortabilidade: string
  readonly seguro: boolean
}

/**
 * Actions Payload Types
 */

export interface ContractsRequestType {
  installmentValue: number
  contractNumber: string
  debtBalance: number
  numberOfRemaingInstallments: number
}

export type SimulacaoCompletaRequestPayload = {
  originBank: string
  contracts: ContractsRequestType[]
  callback: (erroCode: string) => void
}

export type ContratacaoCompletaRequestPayload = {
  moneyChange: boolean
  withInsurance: boolean
  callback: () => void
}
