import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { bancosErrorNovo, bancosRequestNovo, bancosSuccessNovo } from '../actions'

export default function* getBancos() {
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    const response = yield* call(services.getBancosNovo, accessToken)
    const { accreditedBanks } = response.data
    const data = Object.values(accreditedBanks).filter((value) => !!value && !!value?.code)
    yield put(bancosSuccessNovo(data))
  } catch (error) {
    yield put(bancosErrorNovo())
    yield put(
      showError({
        title: 'Erro ao buscar os bancos',
        message: 'Ocorreu um erro ao buscar a listagem de bancos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_001_BANCOS,
        actionCallback: () => bancosRequestNovo(),
      }),
    )
  }
}
