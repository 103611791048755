import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { ContratosTypes } from '@store/portabilidade/contratoAtual/types'
import { ApplicationState } from '@store/types'
import { setAddContratos } from '@store/portabilidade/contratoAtual/actions'
import routes from '@routes/routes'
import { Text } from '@interco/inter-ui/components/Text'
import { formatCurrency } from '@utils/masks'
import { toTitleCase } from '@utils/functions'
import { Button } from '@interco/inter-ui/components/Button'

import { LineInfo } from '../styles'

interface OwnProps {
  setOpenBottomsheet: React.Dispatch<React.SetStateAction<boolean>>
  contratoSelecionado?: ContratosTypes
}
const BottomsheetExcluir = ({ setOpenBottomsheet, contratoSelecionado }: OwnProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bancosNovo = [] } = useSelector((state: ApplicationState) => state.globalApi)
  const { bancoSelecionado, contratosAtuais } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )

  const excluirContrato = () => {
    const novaLista = contratosAtuais?.filter(
      (item) => item.numeroContrato !== contratoSelecionado?.numeroContrato,
    )
    if (novaLista?.length === 0) {
      dispatch(setAddContratos([]))
      setOpenBottomsheet(false)
      navigate(routes.DICAS_CONTRATO_V2)
    }
    dispatch(setAddContratos(novaLista || []))
    setOpenBottomsheet(false)
  }

  return (
    <BottomSheet onClose={() => setOpenBottomsheet(false)}>
      <Text
        variant="headline-h3"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '0 0 24px 0',
        }}
      >
        Você quer excluir esse contrato?
      </Text>
      <LineInfo>
        <Text variant="body-3">Nº do contrato</Text>
        <Text variant="body-3" color="typography" colorWeight={500} bold>
          {contratoSelecionado?.numeroContrato}
        </Text>
      </LineInfo>
      <LineInfo>
        <Text variant="body-3">Valor da parcela</Text>
        <Text variant="body-3" color="typography" colorWeight={500} bold>
          {formatCurrency(contratoSelecionado?.valorParcela)}
        </Text>
      </LineInfo>
      <LineInfo>
        <Text variant="body-3">Banco de origem</Text>
        <Text variant="body-3" color="typography" colorWeight={500} bold>
          {toTitleCase(
            bancosNovo?.find((item) => item.code === bancoSelecionado)?.description || '',
          )}
        </Text>
      </LineInfo>
      <Button variant="primary" fullWidth style={{ marginTop: '24px' }} onClick={excluirContrato}>
        Excluir
      </Button>
      <Button
        variant="secondary"
        fullWidth
        style={{ marginTop: '16px' }}
        onClick={() => setOpenBottomsheet(false)}
      >
        Agora não
      </Button>
    </BottomSheet>
  )
}
export default BottomsheetExcluir
