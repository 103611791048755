import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

interface Props {
  setShowBottomSheetRefazerBiometria: React.Dispatch<React.SetStateAction<boolean>>
  redirectBiometria: () => void
}
export const BottomSheetRefazerBiometria = ({
  setShowBottomSheetRefazerBiometria,
  redirectBiometria,
}: Props) => (
  <BottomSheet
    onClose={() => {
      setShowBottomSheetRefazerBiometria(false)
      redirectBiometria()
    }}
    noSwipe
  >
    <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
      Biometria não validada!
    </Text>

    <Text
      variant="body-3"
      color="typography"
      colorWeight={500}
      style={{
        margin: '0 0 16px 0',
      }}
    >
      Ocorreu um erro durante a biometria, por favor faça o processo novamente!
    </Text>
    <Button
      fullWidth
      onClick={() => {
        setShowBottomSheetRefazerBiometria(false)
        redirectBiometria()
      }}
      style={{ marginTop: '14px' }}
    >
      Refazer biometria
    </Button>
  </BottomSheet>
)
