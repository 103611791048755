import { action, Action } from 'typesafe-actions'

import { BuscarPayload, DocumentosTypes } from './types'

export const showLoading = (): Action => action(DocumentosTypes.SHOW_LOADING)

export const hideLoading = (): Action => action(DocumentosTypes.HIDE_LOADING)

export const postCapturaDocumentos = (): Action =>
  action(DocumentosTypes.CAPTURA_DOCUMENTOS_REQUEST)

export const clearStore = (): Action => action(DocumentosTypes.RESET)

export const carregarCaptura = (): Action => action(DocumentosTypes.CARREGAR_CAPTURA_REQUEST)

export const postDocumentosAssinatura = (): Action =>
  action(DocumentosTypes.DOCUMENTOS_ASSINATURA_REQUEST)

export const buscarDocumentos = (payload?: BuscarPayload): Action =>
  action(DocumentosTypes.BUSCAR_DOCUMENTOS_SAGA, payload)

export const setEtapaBusca = (payload: number): Action =>
  action(DocumentosTypes.SET_ETAPA_BUSCA, payload)

export const setErroUpdateProposal = (payload: boolean): Action =>
  action(DocumentosTypes.SET_ERRO_UPDATE_PROPOSAL, payload)

export const setTipoDocumento = (payload: string | number | undefined): Action =>
  action(DocumentosTypes.SET_TIPO_DOCUMENTO, payload)
