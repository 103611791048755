import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
`
export const Div = styled.div`
  padding: 16px 24px 0 24px;
  margin: 0 -24px;
  border-top: 1px solid ${Colors.GRAY100};
`
export const LineInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
`
