import styled from 'styled-components'

import { Input } from '@interco/inter-ui/components/Input'
import { Colors } from '@utils/colors'

export const CustomInput = styled(Input)`
  & label {
    margin-right: 8px;
  }
  & input {
    color: ${Colors.GRAY500};
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
export const Bold = styled.b`
  font-weight: 700;
`
