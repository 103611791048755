import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { GridStyled } from '@atoms'
import NovoPage from '@templates/NovoPage'
import { Text } from '@interco/inter-ui/components/Text'
import Timer from '@icons/Timer'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'

const InssIndisponivel = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'inss-indisponivel',
      actions: ['retornar_margin_result'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Feedback: problema tecnico',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'INSS indisponivel',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado])

  return (
    <NovoPage
      id="inss-indisponivel"
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Feedback: problema tecnico',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Ir para a home',
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack)) || ''
          }}
        >
          Ir para home
        </Button>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <GridStyled justify="space-around" margin="24px 0">
          <Timer />
        </GridStyled>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Text variant="headline-h1" color="typography" colorWeight={500} semiBold>
            INSS indisponível
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '8px',
          }}
        >
          <Text variant="body-3">
            Devido ao horário, não conseguimos dar sequência na contratação do empréstimo.
          </Text>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
          <Text variant="body-3">Por favor, retorne entre 06h e 22h</Text>
        </div>
      </div>
    </NovoPage>
  )
}

export default InssIndisponivel
