import React, { useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Separator } from '@atoms/Separator'
import { Button } from '@interco/inter-ui/components/Button'
import { ApplicationState } from '@store/types'
import { validaEnvHomeByBridge } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import { clearStore } from '@store/portabilidade/documentos/actions'
import { GridStyled } from '@atoms'
import SuccessIcon from '@icons/SuccessIcon'
import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/colors'
import NovoPage from '@templates/NovoPage'
import { WaitingIcon } from '@interco/cp-react-ui-lib'
import Tags from '@utils/Tags'

import { TimelineCustom } from './styles'

const Sucesso = () => {
  const dispatch = useDispatch()
  const { isApp, origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { refinancing } = useSelector(
    (state: ApplicationState) => state.portabilidade.simulacaoCompleta,
  )

  const finish = useCallback(() => {
    dispatch(clearStore())
  }, [dispatch])

  useEffect(() => {
    finish()
  }, [finish])

  useEffect(() => {
    BridgeService.tac({
      name: 'tela_sucesso',
      actions: ['confirmar_contratacao'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '5',
      name: 'CONSIGNADO_PORT_SUCESSO',
      screen: 'Feedback: Sucesso',
      ref_type: 'Sucesso',
      content_action: 'Dado carregado',
      action_id: 'Contratacao solicitada',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
    Tags.enviarTagueamentoWeb({
      element_name: 'Contratacao solicitada',
      section_name: 'Feedback: Sucesso',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [nomeConvenioSelecionado])

  return (
    <NovoPage
      id="sucesso"
      stickyContainerFooterBottom="30px"
      positionFooter="sticky"
      titleHeader="Portabilidade Consignado"
      stickyFooter={
        <>
          <Button
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Feedback: Sucesso',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Acompanhar proposta',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              if (isApp) {
                window.location.href = String(
                  process.env.REACT_APP_HOME_EMPRESTIMO_PROPOSTAS_CONTRATOS,
                )
              } else {
                window.location.href = String(process.env.REACT_APP_HOME_EMPRESTIMO_NOVO)
              }
            }}
          >
            Acompanhar proposta
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Feedback: Sucesso',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Ir para a home',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack)) || ''
            }}
          >
            Ir para a home
          </Button>
        </>
      }
    >
      <GridStyled justify="space-around">
        <SuccessIcon />
      </GridStyled>
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          textAlign: 'center',
        }}
      >
        Contração solicitada!
      </Text>
      <Separator margin="24px 0" variant="small" style={{ backgroundColor: Colors.GRAY100 }} />
      <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
        O que acontece a partir de agora:
      </Text>
      <TimelineCustom>
        <TimelineCustom.Item
          title="Já estamos validando seus dados"
          type="Alert"
          icon={
            <div style={{ fill: 'white !important' }}>
              <WaitingIcon />
            </div>
          }
        >
          <Text variant="body-3" style={{ marginTop: '14px' }}>
            Estando tudo certo, te avisaremos por notificação no seu celular e por e-mail.
          </Text>
        </TimelineCustom.Item>
        <TimelineCustom.Item title="Portabilidade realizada" type="Disabled" />
        {refinancing ? (
          <>
            <TimelineCustom.Item title="Refinanciamento iniciado" type="Disabled" />
            <TimelineCustom.Item title="Refinanciamento realizado" type="Disabled" />
          </>
        ) : (
          <></>
        )}
      </TimelineCustom>
    </NovoPage>
  )
}
export default Sucesso
