import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import ChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import { validarContrato } from '@utils/validators'
import { Input } from '@atoms'
import { setAddContratos } from '@store/portabilidade/contratoAtual/actions'
import { bancosRequestNovo } from '@store/globalApi/actions'
import { Alert } from '@interco/inter-ui/components/Alert'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { setMatricula } from '@store/portabilidade/dadosProfissionais/actions'
import { ContratosTypes } from '@store/portabilidade/contratoAtual/types'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'
import { StepPortabilidadeNovo } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { currencyToFloat } from '@utils/masks'

import { LineInfo } from './styles'
import BottomsheetErroBeneficio from './Bottomsheets'

const AdicionarContrato = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [openBottomsheetBeneficio, setOpenBottomsheetBeneficio] = useState(false)
  const [openBottomsheetErroBeneficio, setOpenBottomsheetErroBeneficio] = useState(false)
  const [numeroContratoError, setNumeroContratoError] = useState<string>('')
  const [valorParcelaError, setValorParcelaError] = useState<string>('')
  const [saldoDevedorError, setSaldoDevedorError] = useState<string>('')
  const [parcelaRestanteError, setParcelaRestanteError] = useState<string>('')
  const [bancoError, setBancoError] = useState<string>('')
  const beneficioError = ''
  const [valorParcela, setValorParcela] = useState('')
  const [numeroContrato, setNumeroContrato] = useState('')
  const [saldoDevedor, setSaldoDevedor] = useState('')
  const [parcelasRestantes, setParcelasRestantes] = useState('')
  const [isEdition, setIsEdition] = useState(false)
  const { contratosAtuais } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { matricula, nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { bancoSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { bancosNovo = [] } = useSelector((state: ApplicationState) => state.globalApi)

  // TODO: de onde vai vir essa informação de mais de um beneficio?
  const maisDeUmBeneficio = false

  // TODO: de onde vai vir esse array de matriculas?
  const matriculasDisponiveis = ['123456', '445677']

  useEffect(() => {
    BridgeService.tac({
      name: 'adicionar_contrato_tela',
      actions: ['adicionar_contrato'],
    })
  }, [])

  useEffect(() => {
    if (location.state) {
      const contratoEdicao: ContratosTypes = location.state
      setValorParcela(contratoEdicao.valorParcela)
      setNumeroContrato(contratoEdicao.numeroContrato)
      setSaldoDevedor(contratoEdicao.saldoDevedor)
      setParcelasRestantes(contratoEdicao.parcelasRestantes)
      setIsEdition(true)
    } else {
      setIsEdition(false)
    }
  }, [location.state])

  useEffect(() => {
    if (!bancosNovo || bancosNovo.length === 0) {
      dispatch(bancosRequestNovo())
    }
  }, [bancosNovo, dispatch])

  const validateField = (
    condition: boolean,
    setError: (message: string) => void,
    errorMessage: string,
  ) => {
    if (!condition) {
      setError(errorMessage)
      return false
    }
    setError('')
    return true
  }

  const handleContinue = () => {
    let valid = true

    // valid = validateField(Boolean(matricula), setBeneficioError, 'Selecione o benefício') && valid

    valid =
      validateField(Boolean(bancoSelecionado), setBancoError, 'Informe o banco de origem') && valid

    valid =
      validateField(
        Boolean(numeroContrato) && validarContrato(numeroContrato),
        setNumeroContratoError,
        !numeroContrato ? 'Informe o número do contrato' : 'Informe um número válido',
      ) && valid

    valid =
      validateField(
        Boolean(valorParcela) && currencyToFloat(valorParcela) > 0,
        setValorParcelaError,
        'Informe o valor da parcela',
      ) && valid

    valid =
      validateField(
        Boolean(saldoDevedor) && currencyToFloat(saldoDevedor) > 0,
        setSaldoDevedorError,
        'Informe um saldo devedor válido',
      ) && valid

    valid =
      validateField(
        Boolean(parcelasRestantes),
        setParcelaRestanteError,
        'Informe uma quantidade válida',
      ) && valid

    if (valid) {
      const contratosAtualizados =
        contratosAtuais?.map((contratoExistente) => {
          if (contratoExistente.numeroContrato === numeroContrato) {
            return {
              ...contratoExistente,
              valorParcela,
              parcelasRestantes,
              saldoDevedor,
            }
          }
          return contratoExistente
        }) || []

      // Se nenhum contrato foi atualizado, significa que estamos adicionando um novo
      if (!contratosAtualizados.some((contrato) => contrato.numeroContrato === numeroContrato)) {
        contratosAtualizados.push({
          numeroContrato,
          valorParcela,
          bancoSelecionado,
          parcelasRestantes,
          saldoDevedor,
        })
      }

      dispatch(setAddContratos(contratosAtualizados))
      Tags.enviarTagueamentoApp({
        ref_figma: '3',
        name: 'CONSIGNADO_PORT_FLUXO',
        screen: 'Adicionar contrato',
        ref_type: 'Botao',
        content_action: 'Toque',
        action_id: 'Confirmar',
        product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
      })
      BridgeService.tic({
        name: 'btn_confirmar_contrato',
        action: 'confirmar_contrato',
      })
      Tags.enviarTagueamentoWeb({
        element_name: 'Confirmar',
        section_name: 'Adicionar contrato',
        element_action: 'click button',
        c_page: window.location.href,
        redirect_url: `${window.location.origin}${routes.CONTRATOS_CADASTRADOS_V2}`,
        step: 'null',
      })
      dispatch(trackingRequest(StepPortabilidadeNovo.DADOS_CONTRATOS))
      navigate(routes.CONTRATOS_CADASTRADOS_V2)
    }
  }

  useEffect(() => {
    if (numeroContrato && !validarContrato(numeroContrato)) {
      setNumeroContratoError('Informe um número válido')
    } else {
      setNumeroContratoError('')
    }
  }, [numeroContrato])

  return (
    <NovoPage
      id="adicionar-contrato"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      stickyFooter={
        <>
          {maisDeUmBeneficio ? (
            <Alert type="warning">
              É possível adicionar apenas contratos do mesmo convênio e benefício.
            </Alert>
          ) : (
            <></>
          )}
          <Button fullWidth style={{ marginTop: '8px' }} onClick={handleContinue}>
            Confirmar
          </Button>
        </>
      }
    >
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '0 0 16px 0',
        }}
      >
        Informe os dados do seu contrato
      </Text>
      {maisDeUmBeneficio ? (
        <Input
          id="beneficio"
          label="Benefício"
          placeholder="Selecione o benefício"
          value={matricula}
          iconRight={<ChevronDown color={Colors.PRIMARY500} width={24} height={24} />}
          onClick={() => setOpenBottomsheetBeneficio(true)}
          error={Boolean(beneficioError)}
          infoText={beneficioError}
        />
      ) : (
        <></>
      )}

      <Input
        id="banco-origem"
        label="Banco de origem"
        placeholder="Selecione o banco"
        value={bancosNovo.find((banco) => banco.code === bancoSelecionado)?.description}
        iconRight={<ChevronRight color={Colors.PRIMARY500} width={24} height={24} />}
        onClick={() => navigate(routes.BANCO_CONTRATO_V2, { state: location.state })}
        error={Boolean(bancoError)}
        infoText={bancoError}
      />

      <Input
        id="contrato"
        value={numeroContrato}
        label="Número do contrato"
        maxLength={40}
        placeholder="Informe o número do contrato"
        onChange={(e) => {
          const numeroContratoDigitado = (e.target as HTMLInputElement).value
          setNumeroContrato(numeroContratoDigitado?.replace(/[^a-zA-Z0-9]/g, ''))
        }}
        error={Boolean(numeroContratoError)}
        infoText={numeroContratoError}
        disabled={isEdition}
      />
      <Input
        id="saldo-devedor"
        type="currency"
        onlyNumber
        maxLength={13}
        value={saldoDevedor || 'R$ 0,00'}
        label="Saldo devedor"
        placeholder="R$ 0,00"
        onChange={(e) => setSaldoDevedor((e.target as HTMLInputElement).value)}
        error={Boolean(saldoDevedorError)}
        infoText={saldoDevedorError}
      />
      <Input
        id="valor-parcela"
        type="currency"
        onlyNumber
        value={valorParcela || 'R$ 0,00'}
        maxLength={13}
        label="Valor da parcela"
        placeholder="R$ 0,00"
        onChange={(e) => setValorParcela((e.target as HTMLInputElement).value)}
        error={Boolean(valorParcelaError)}
        infoText={valorParcelaError}
      />
      <Input
        id="parcela-restante"
        type="number"
        onlyNumber
        value={parcelasRestantes}
        label="Parcelas remanescentes"
        placeholder="0"
        onChange={(e) => {
          const { value } = e.target as HTMLInputElement
          if (value.length <= 3 && /^\d*$/.test(value)) {
            setParcelasRestantes(value)
          }
        }}
        error={Boolean(parcelaRestanteError)}
        infoText={parcelaRestanteError}
      />
      {openBottomsheetBeneficio ? (
        <BottomSheet onClose={() => setOpenBottomsheetBeneficio(false)}>
          <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
            Selecione o benefício
          </Text>
          {matriculasDisponiveis.map((matriculaItem) => (
            <LineInfo
              key={matriculaItem}
              onClick={() => {
                dispatch(setMatricula(matriculaItem))
                setOpenBottomsheetBeneficio(false)
              }}
            >
              <Text variant="body-3" color="typography" colorWeight={500} bold>
                {matriculaItem}
              </Text>
              <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
            </LineInfo>
          ))}
        </BottomSheet>
      ) : (
        <></>
      )}
      {openBottomsheetErroBeneficio ? (
        <BottomsheetErroBeneficio
          setOpenBottomsheetErroBeneficio={setOpenBottomsheetErroBeneficio}
        />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}
export default AdicionarContrato
