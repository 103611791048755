import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import PdfFile from '@interco/icons/core/files/ic_file_pdf'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { Colors } from '@utils/colors'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import { requestMarginAccept } from '@store/portabilidade/consultaMarginQuery/actions'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'

import { BoxCustom, Line } from './styles'

interface OwnProps {
  setShowBottomSheetInss: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetInss = ({ setShowBottomSheetInss }: OwnProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { loadingQuery } = useSelector(
    (state: ApplicationState) => state.portabilidade.consultaMarginQuery,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'bottomsheet_inss',
      actions: ['selecionar_convenio'],
    })
  }, [])

  return (
    <BottomSheet onClose={() => setShowBottomSheetInss(false)} noSwipe>
      <Line>
        <Text variant="headline-h3" semiBold>
          Autorizar termo
        </Text>
      </Line>

      <Text variant="caption-1" color="typography" colorWeight={400}>
        Essa autorização nos ajuda a oferecer a melhor condição possível pra você.
      </Text>

      <BoxCustom
        onClick={() => {
          BridgeService.tic({
            name: 'btn_termo_autorizacao',
            action: 'visualizar_termo',
          })
          Tags.enviarTagueamentoApp({
            ref_figma: '3',
            name: 'CONSIGNADO_PORT_FLUXO',
            screen: 'Termo INSS',
            ref_type: 'Botao',
            content_action: 'Toque',
            action_id: 'Termo de Autorizacao INSS',
            product: `PORTABILIDADE_CONSIGNADO_INSS`,
          })
          navigate(routes.TERMO_AUTORIZACAO_V2)
        }}
        actived={false}
      >
        <Text
          variant="body-3"
          style={{
            width: '90%',
            color: Colors.GRAY500,
            fontWeight: 700,
            alignContent: 'flex-start',
          }}
        >
          <PdfFile height={24} width={24} color={Colors.GRAY500} style={{ marginRight: '16px' }} />
          Termo de Autorização INSS
        </Text>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </BoxCustom>

      <div style={{ margin: '24px 0 0 0 ' }}>
        <Text variant="caption-1" color="typography" colorWeight={500}>
          Ao continuar, você declara que aceita o termo acima.
        </Text>
        <Button
          fullWidth
          style={{ marginTop: '16px' }}
          isLoading={loading || loadingQuery}
          disabled={loading || loadingQuery}
          onClick={() => {
            setLoading(true)
            BridgeService.tic({
              name: 'btn_continuar',
              action: 'autorizar_termo_inss',
            })
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Termo INSS',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Continuar',
              product: `PORTABILIDADE_CONSIGNADO_INSS`,
            })
            dispatch(
              requestMarginAccept({
                convenioDescricao: 'inss',
                callback: () => {
                  dispatch(trackingRequest(StepPortabilidadeNovo.DOCUMENTACAO_AUTORIZADA))
                  navigate(routes.CARREGAMENTO_DADOS_V2)
                },
                callbackError: () => {
                  setLoading(false)
                  setShowBottomSheetInss(false)
                },
              }),
            )
          }}
        >
          Continuar
        </Button>
      </div>
    </BottomSheet>
  )
}

export default BottomsheetInss
