import { useCallback, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import oAuthAssistant, { oAuthScopes } from '@config/curity'
import { ApplicationState } from '@store/types'
// import routes from '@routes/routes'
import { setAccessToken } from '@store/session/actions'

const useAuth = () => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state: ApplicationState) => state.session)
  const [isLoading, setIsLoading] = useState(false)
  const getToken = useCallback(() => {
    oAuthAssistant?.init().then(() => {
      const token = oAuthAssistant?.getToken()
      if (token) {
        dispatch(setAccessToken(token))
      }
    })
  }, [dispatch])

  const authorize = useCallback(async () => {
    setIsLoading(true)

    try {
      await oAuthAssistant?.init()
      await oAuthAssistant?.authorize({
        scope: oAuthScopes,
        ui_locales: 'pt-BR',
      })
    } finally {
      setIsLoading(false)
    }
  }, [])

  // const logout = useCallback(async () => {
  //   setIsLoading(true)
  //   oAuthAssistant?.init().then(() => {
  //     oAuthAssistant?.logout('', false)
  //   })
  //   await dispatch(resetPortabilidadeStore())

  //   // TODO: mandar pra alguma home do simulador / landing page
  //   window.location.href = `${siteUrl}${routes.LOGIN}${loginParam}`
  // }, [dispatch])

  const value = useMemo(
    () => ({
      accessToken,
      authorize,
      // logout,
      getToken,
      isLoading,
    }),
    [authorize, accessToken, getToken, isLoading],
  )

  return value
}

export default useAuth
