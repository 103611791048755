import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Alert } from '@interco/inter-ui/components/Alert'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { ApplicationState } from '@store/types'
import Attention from '@interco/icons/core/status/ic_warning_circle'
import Refresh from '@interco/icons/core/action-navigation/ic_rotate_right'
import SolidClose from '@interco/icons/core/status/ic_close_circle_fill'
import CheckFill from '@interco/icons/core/status/ic_check_circle_fill'
import { Text } from '@interco/inter-ui/components/Text'
import { Box } from '@atoms'
import { ConveniosCodes } from '@utils/enums'
import { StepPortabilidadeNovo } from '@utils/enums'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/colors'
import routes from '@routes/routes'
import NovoPage from '@templates/NovoPage'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { postBiometriaDadoSave } from '@store/biometria/actions'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'
import { interWbSession, WbPermissions } from '@interco/inter-webview-bridge'

import { WrapText } from '../styles'
import BottomsheetTipoDoc from './BottomsheetTipoDoc'

const StatusDocumentos = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openTipoDoc, setOpenTipoDoc] = useState(false)
  const { isError, isDone } = useSelector(
    (state: ApplicationState) => state.portabilidade.fotoDocumentos,
  )
  const { isError: isErrorContraCheque, isDone: isDoneContraCheque } = useSelector(
    (state: ApplicationState) => state.portabilidade.contrachequeDocumentos,
  )
  const { convenioSelecionado, nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { accessToken } = useSelector((state: ApplicationState) => state.session)
  const { origem } = useSelector((state: ApplicationState) => state.ui.navigation)

  useEffect(() => {
    BridgeService.tac({
      name: 'envio_documento',
      actions: ['solicitar_documentos'],
    })
  }, [])

  const callBridgePermissions = async () => {
    await interWbSession.requestPermissions(WbPermissions.CAMERA)
    await interWbSession.requestPermissions(WbPermissions.AUDIO)
    await interWbSession.requestPermissions(WbPermissions.STORAGE)
  }

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      callBridgePermissions()
    }
  }, [dispatch])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Envio de documentos',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id:
        ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioSelecionado
          ? 'Envie os documentos abaixo'
          : 'Envie o seu documento de identificação',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [convenioSelecionado, nomeConvenioSelecionado])

  return (
    <NovoPage
      id="status-documentos"
      titleHeader="Envio de documentos"
      stickyFooter={
        <>
          {isError ? (
            <Alert customBackgroundColor={Colors.ERROR500} customIcon={<Attention color="white" />}>
              <Text
                variant="body-3"
                color="typography"
                colorWeight={100}
                bold
                style={{
                  color: Colors.WHITE,
                }}
              >
                Erro ao enviar
              </Text>
            </Alert>
          ) : (
            <></>
          )}
          {ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioSelecionado ? (
            <Button
              disabled={!isDone || !isDoneContraCheque}
              onClick={() => {
                BridgeService.tic({
                  name: 'btn_solicitar_biometria',
                  action: 'solicitar_biometria',
                })
                dispatch(
                  postBiometriaDadoSave({
                    callback: (biometricToken: string) => {
                      dispatch(trackingRequest(StepPortabilidadeNovo.ORIENTACOES_VIDEO_SELFIE))
                      window.location.href = BridgeService.isBrowser()
                        ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioSelecionado}&accessToken=${accessToken}&produto=PORTABILIDADE`
                        : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioSelecionado}&produto=PORTABILIDADE`
                    },
                  }),
                )
              }}
            >
              Continuar
            </Button>
          ) : (
            <></>
          )}
        </>
      }
    >
      <Text
        variant="headline-h2"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '0 0 16px 0',
        }}
      >
        {ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioSelecionado
          ? 'Envie os documentos abaixo'
          : 'Envie o seu documento de identificação'}
      </Text>
      <Text variant="body-3" color="typography" colorWeight={500}>
        Você pode anexar ou tirar uma foto frente e verso
      </Text>

      <Box
        key="foto"
        onClick={() => {
          Tags.enviarTagueamentoApp({
            ref_figma: '3',
            name: 'CONSIGNADO_PORT_FLUXO',
            screen: 'Envio de documentos',
            ref_type: 'Botao',
            content_action: 'Toque',
            action_id: 'Documento de identificação',
            product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
          })
          setOpenTipoDoc(true)
        }}
        actived={false}
        style={{
          marginTop: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {isError ? (
          <>
            <SolidClose height={24} width={24} color={Colors.ERROR500} />
            <WrapText style={{ width: '75%' }}>
              <Text variant="body-3" color="typography" colorWeight={500} bold>
                Documento de identificação
              </Text>
              <Text variant="caption-1" color="primary" colorWeight={500} bold>
                Conferir dicas pra foto
              </Text>
            </WrapText>
            <Refresh height={24} width={24} color={Colors.PRIMARY500} />
          </>
        ) : (
          <>
            {isDone ? <CheckFill height={24} width={24} color={Colors.SUCCESS500} /> : <></>}
            <WrapText style={{ width: '75%' }}>
              <Text variant="body-3" color="typography" colorWeight={500} bold>
                Documento de identificação
              </Text>
              <Text variant="caption-1">CNH, RG ou Carteira funcional</Text>
            </WrapText>
            <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
          </>
        )}
      </Box>

      {ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioSelecionado ? (
        <Box
          key="contracheque"
          onClick={() => {
            navigate(routes.MODO_ENVIO_CONTRA_CHEQUE_V2)
          }}
          actived={false}
          style={{
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isErrorContraCheque ? (
            <>
              <SolidClose height={24} width={24} color={Colors.ERROR500} />
              <WrapText style={{ width: '75%' }}>
                <Text variant="body-3" color="typography" colorWeight={500} bold>
                  Contra Cheque
                </Text>
                <Text variant="caption-1" color="primary" colorWeight={500} bold>
                  Conferir dicas pra foto
                </Text>
              </WrapText>
              <Refresh height={24} width={24} color={Colors.PRIMARY500} />
            </>
          ) : (
            <>
              {isDoneContraCheque ? (
                <CheckFill height={24} width={24} color={Colors.SUCCESS500} />
              ) : (
                <></>
              )}
              <WrapText style={{ width: '75%' }}>
                <Text variant="body-3" color="typography" colorWeight={500} bold>
                  Contracheque
                </Text>
              </WrapText>
              <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
      {openTipoDoc ? <BottomsheetTipoDoc setOpenTipoDoc={setOpenTipoDoc} /> : <></>}
    </NovoPage>
  )
}

export default StatusDocumentos
