import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { ApplicationState } from '@store/types'
import { Input } from '@interco/inter-ui/components/Input'
import { estadosRequest } from '@store/globalApi/actions'
import NovoPage from '@templates/NovoPage'
import {
  setEstadoSelecionado,
  setFonteSelecionada,
  setMatricula,
} from '@store/portabilidade/dadosProfissionais/actions'
import { buildEstados } from '@utils/functions'
import Select from '@atoms/SelectNovo'
import { transformEstadoToOption } from '@utils/transformers'
import routes from '@routes/routes'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'

import { Container } from './styles'

const DadosBasicos = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(false)
  const {
    estados = [],
    estadosLoading,
    fontesPagamentoLoading,
  } = useSelector((state: ApplicationState) => state.globalApi)
  const { estadoSelecionado, matricula } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'dados_manuais_siape',
      actions: ['consulta_margem_siape'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Autorizacao de margem',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Para prosseguir precisamos que preencha esses dados!',
      product: `PORTABILIDADE_CONSIGNADO_SIAPE`,
    })
  }, [])

  useEffect(() => {
    dispatch(estadosRequest())
  }, [dispatch])

  const callSimular = () => {
    BridgeService.tic({
      name: 'btn_continuar',
      action: 'continuar_dados_manuais',
    })
    Tags.enviarTagueamentoApp({
      ref_figma: '3',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Autorizacao de margem',
      ref_type: 'Botao',
      content_action: 'Toque',
      action_id: 'Continuar',
      product: `PORTABILIDADE_CONSIGNADO_SIAPE`,
    })
    Tags.enviarTagueamentoWeb({
      element_name: 'Continuar',
      section_name: 'Autorizacao de margem',
      element_action: 'click button',
      c_page: window.location.href,
      redirect_url: `${window.location.origin}${routes.CARREGAMENTO_DADOS_V2}`,
      step: 'null',
    })
    navigate(routes.CARREGAMENTO_DADOS_V2)
  }

  return (
    <NovoPage
      id="dados-manuais"
      stickyFooter={
        <Button
          disabled={disabled}
          onClick={() => {
            setDisabled(false)
            callSimular()
          }}
        >
          Continuar
        </Button>
      }
      positionFooter="sticky"
    >
      <Container>
        <Text
          variant="headline-h2"
          color="typography"
          colorWeight={500}
          semiBold
          style={{
            margin: '0 0 8px 0',
          }}
        >
          Para prosseguir precisamos que preencha esses dados!
        </Text>
        <Select
          name="fontePagamento"
          loading={fontesPagamentoLoading}
          label="Informe a sua fonte de pagamento"
          options={[{ text: 'Federal', value: '1' }]}
          value={{ text: 'Federal', value: '1' }}
          placeholder="Seleciona a fonte"
          onChange={(option) => {
            dispatch(setFonteSelecionada(option.value))
          }}
        />
        <Select
          name="estado"
          options={buildEstados(estados)}
          loading={estadosLoading}
          label="Informe o seu Estado"
          placeholder="Selecione o Estado"
          value={transformEstadoToOption(estados, String(estadoSelecionado))}
          onChange={(option) => {
            dispatch(setEstadoSelecionado(option.value ?? ''))
          }}
        />
        <Input
          id="matricula"
          label="Informe a sua matrícula"
          placeholder="Insira sua matrícula"
          value={matricula}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setMatricula(value))
          }}
        />
      </Container>
    </NovoPage>
  )
}

export default DadosBasicos
