import React from 'react'

import { useSelector } from 'react-redux'

import { cpfValue, formatCurrency } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import { CCBEnum } from '@utils/enums'
import { ConvertDateUTC, DateFormat } from '@utils/functions'

import { TextCCB } from './Clausulas'

const CCB = () => {
  const { numeroProposta, dadosProposta } = useSelector(
    (state: ApplicationState) => state.portabilidade.proposta,
  )

  const variant = 'web'
  return (
    <TemplatesPDF.CedulaCreditoBancario
      variant={variant}
      typeCCB={CCBEnum.CCB}
      header={{ numDoc: String(numeroProposta) }}
      emitente={{
        cpf: cpfValue(dadosProposta.cpf),
        nome: dadosProposta.nomeCliente,
        dataNascimento: dadosProposta.dataNascimento
          ? DateFormat(new Date(dadosProposta.dataNascimento), 'dd/MM/yyyy')
          : '',
        estadoCivil: dadosProposta?.estadoCivil,
        documentoIdentificacao: dadosProposta?.documentoIdentificacao,
        endereco: `${dadosProposta?.enderecoResidencial}, ${dadosProposta?.numeroResidencial} - ${dadosProposta?.bairroResidencial} - ${dadosProposta?.cidadeResidencial}/${dadosProposta?.estadoResidencial}`,
        naturalidade: dadosProposta?.cidadeResidencial,
        nacionalidade: dadosProposta?.nacionalidade,
        nomeMae: dadosProposta?.nomeMae,
        nomePai: dadosProposta?.nomePai,
        nomeConjuge: dadosProposta?.nomeConjuge,
        cpfConjuge: '',
        banco: dadosProposta?.banco,
        agencia: dadosProposta?.agencia,
        contaCorrente: dadosProposta?.conta,
      }}
      demonstrativoCalculo={{
        valorTotal: formatCurrency(
          parseFloat(dadosProposta?.valorTotalASerPago?.replace('.', '')?.replace(',', '.')),
        ),
        taxaJurosMensal: `${dadosProposta?.taxa}%`,
        taxaJurosAnual: dadosProposta?.taxaNominalAnual
          ? `${parseFloat(dadosProposta?.taxaNominalAnual).toFixed(2)}%`
          : '',
        valorSolicitado:
          formatCurrency(
            parseFloat(dadosProposta.valorSolicitado?.replace('.', '')?.replace(',', '.')),
          ) || 'R$: 0,00',
        percentualValorSolicitado: `${dadosProposta.valorSolicitadoPercentual}%`,
        cetMensal: `${dadosProposta.atributosCetMensal}%`,
        cetAnual: `${dadosProposta.atributosCetAnual}%`,
        tarifaCadastro:
          formatCurrency(
            parseFloat(dadosProposta.tarifaCadastro?.replace('.', '')?.replace(',', '.')),
          ) || 'R$: 0,00',
        percentualTarifaCadastro: `${dadosProposta.taxaTarifaCadastro}%`,
        dataEmissao: dadosProposta.dataEmissao
          ? DateFormat(new Date(`${dadosProposta.dataEmissao}T00:00`), 'dd/MM/yyyy')
          : '',
        iof: formatCurrency(Number(dadosProposta.valorIOF)),
        percentualIof: `${dadosProposta.percentualIOF}%`,
        liberacaoCredito: `${dadosProposta.tipoLiberacao}`,
        jurosContratados: formatCurrency(
          parseFloat(dadosProposta.jurosContratados?.replace('.', '')?.replace(',', '.')),
        ),
        percentualJurosContratados: `${dadosProposta.jurosContratadosPercentual}%`,
        valorMutuo: formatCurrency(
          parseFloat(dadosProposta?.valorDoMutuo?.replace('.', '').replace(',', '.')),
        ),
        pracaPagamento: `${dadosProposta.cidadeResidencial}/${dadosProposta.estadoResidencial}`,
        valorSeguro: formatCurrency(
          parseFloat(
            dadosProposta.valorTotalDestinadoPagamentoSeguro?.replace('.', '')?.replace(',', '.'),
          ),
        ),
        percentualValorSeguro: `${dadosProposta.percentualPropostaSeguroPrestamista}%`,
        seguro: dadosProposta.possuiSeguro ? 'Sim' : 'Não',
        matricula: dadosProposta.matricula,
        qtdeParcelasMensais: dadosProposta.quantidadeDeParcelas,
        valorParcelaMensal: formatCurrency(
          parseFloat(dadosProposta.valorParcela?.replace('.', '')?.replace(',', '.')),
        ),
        jurosCapitalizados: dadosProposta.jurosCapitalizados,
        previsaoPrimeiroDesconto: dadosProposta?.primeiroDesconto
          ? DateFormat(ConvertDateUTC(dadosProposta?.primeiroDesconto), 'MM/yyyy')
          : '',
        previsaoUltimoDesconto: dadosProposta?.ultimoDesconto
          ? DateFormat(ConvertDateUTC(dadosProposta?.ultimoDesconto), 'MM/yyyy')
          : '',
        textAcordo:
          '2.24 -  O (A) EMITENTE declara estar ciente e de acordo que, na hipótese de haver divergência no valor do contrato negociado, por motivo de indisponibilidade de margem integral para averbação do mútuo, o CREDOR poderá realizar a operação nas novas condições. Dessa forma, as condições solicitadas nessa proposta podem ser alteradas em razão do limite disponível de margem e, havendo alteração, o(a) EMITENTE concorda com a variação. Assim que a operação for aprovada, o(a) EMITENTE receberá uma comunicação do CREDOR detalhando as condições pactuadas e, caso haja discordância, o(a) EMITENTE  poderá solicitar o cancelamento em até 7 dias.',
      }}
      textoAutorizacao={{ text: TextCCB }}
      assinatura={{
        isDgAssinado: true,
      }}
      footer={{
        atualizado: new Date('15/08/2022'),
        codigo: 'CP001',
        edicao: '29º',
        contatos: [
          'www.bancointer.com.br - Central de Atendimento 3003-4070 (Capitais e Regiões Metropolitanas)',
          'SAC 0800 940 9999 - Ouvidoria 0800 940 7772 - Deﬁciente Fala e Audição 0800 979 7099',
        ],
      }}
    />
  )
}

export default CCB
