import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import { ErroType } from '@utils/types'

import { BuscarPropostaPayload } from '../types'
import {
  buscarProposta,
  buscarPropostaError,
  buscarPropostaSuccess,
  setNumeroProposta,
} from '../actions'

export default function* postCriarProposta(action: AnyAction) {
  const payload = action.payload as BuscarPropostaPayload

  const { accessToken } = yield* select((state: ApplicationState) => state.session)

  try {
    const { data } = yield* call(services.getBuscarProposta, accessToken)
    yield put(buscarPropostaSuccess(data.proposals))
    // Pega o numero da primeira proposta
    yield put(setNumeroProposta(data.proposals[0].proposal))
    if (typeof payload?.callback === 'function') {
      yield payload.callback()
    }
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(buscarPropostaError())
    if (erroTyped.status === HttpStatus.BAD_REQUEST) {
      if (typeof payload?.callbackError === 'function') {
        yield payload.callbackError()
      }
    } else {
      yield put(
        showError({
          title: 'Erro ao buscar proposta',
          message: 'Ocorreu um erro ao buscar proposta',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => buscarProposta(payload),
        }),
      )
    }
  }
}
