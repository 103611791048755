/**
 * Actions Types
 */

export enum ContratoAtualTypes {
  BANCOS_REQUEST = '@contratoAtual/BANCOS_REQUEST',
  SET_BANCO_SELECIONADO = '@contratoAtual/SET_BANCO_SELECIONADO',
  SET_NUMERO_CONTRATO = '@contratoAtual/SET_NUMERO_CONTRATO',
  SET_SALDO_DEVEDOR = '@contratoAtual/SET_SALDO_DEVEDOR',
  SET_VALOR_PARCELA = '@contratoAtual/SET_VALOR_PARCELA',
  SET_PARCELAS_RESTANTES = '@contratoAtual/SET_PARCELAS_RESTANTES',
  ADD_CONTRATO_ATUAL = '@contratoAtual/ADD_CONTRATO_ATUAL',

  RESET = 'RESET',
}

export interface ContratosTypes {
  numeroContrato: string
  valorParcela: string
  bancoSelecionado?: string | number
  parcelasRestantes: string
  saldoDevedor: string
}

/**
 * State Type
 */

export interface ContratoAtualState {
  readonly bancoSelecionado?: string | number
  readonly valorParcela: string
  readonly numeroContrato: string
  readonly saldoDevedor?: number
  readonly parcelasRestantes?: number
  readonly loading?: boolean
  readonly error?: boolean
  readonly contratosAtuais: ContratosTypes[]
}
