import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataType {
  cpf: string
  numeroProposta: string
}

export default async (data: DataType, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_CAPTURE_DOCUMENTS_WEB}`
    : process.env.REACT_APP_CAPTURE_DOCUMENTS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`

    const response = await BaseServices.request<CapturaDocumentoRequest>({
      headers,
      pathname,
      method,
      host,
    })
    return response
  }

  const response = await BaseServices.request<CapturaDocumentoRequest>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface CapturaDocumentoRequest {
  data: {
    idInteracao: string
    numeroProposta: string
    pendentes: []
    enviados: []
  }
}
