/**
 * Actions Types
 */

import { AnyAction } from 'redux'

export enum ErrorTypes {
  SHOW_ERROR = '@error/SHOW_ERROR',
  CLEAR_ERROR = '@error/CLEAR_ERROR',
}

/**
 * State Type
 */

export interface ErrorState {
  readonly showError?: boolean
  readonly message?: string
  readonly title?: string
  readonly actionCallback?: () => AnyAction
  readonly actionTitle?: string
  readonly block?: boolean
  readonly code?: string
  readonly functionCallback?: () => void
}
