import { SalvarPropostaRequest } from '@services/postSalvarProposta'
import { ApplicationState } from '@store/types'
import { TiposContaNovo } from '@utils/transformers'

export const buildProposta = ({ portabilidade }: ApplicationState): SalvarPropostaRequest => ({
  addressInfo: {
    zipCode: String(portabilidade.endereco.cep || ''),
    addressType: String(portabilidade.endereco.tipoLogradouro || ''),
    address: String(portabilidade.endereco.logradouro || ''),
    addressLine2: String(portabilidade.endereco.complemento || ''),
    number: Number(portabilidade.endereco.numero),
    neighborhood: String(portabilidade.endereco.bairro || ''),
    state: String(portabilidade.endereco.estadoSelecionado || ''),
    city: String(portabilidade.endereco.cidadeSelecionada || ''),
  },
  bankInfo: {
    originBank: String(portabilidade.dadosBancarios.bancoSelecionado || ''),
    accountType:
      TiposContaNovo.find(
        ({ value }) => value === String(portabilidade.dadosBancarios.tipoContaSelecionado),
      )?.text || 'Conta Corrente',
    agency: String(portabilidade.dadosBancarios.agencia || ''),
    account: String(portabilidade.dadosBancarios.conta || ''),
    accountDigit: String(portabilidade.dadosBancarios.digito || ''),
  },
})
