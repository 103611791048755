import { Reducer } from 'redux'

import { TipoPortabilidade } from '@utils/enums'

import { SimulacaoCompletaState, SimulacaoCompletaTypes } from './types'

const INITIAL_STATE: SimulacaoCompletaState = {
  errorCode: '',
  benefitNumber: '',
  refinancing: undefined,
  refinancingWithInsurance: undefined,
  portability: undefined,
  unavailableContracts: undefined,
  loading: false,
  loadingContratacaoCompleta: false,
  tipoPortabilidade: TipoPortabilidade.SEM_TROCO,
  seguro: false,
}

const reducer: Reducer<SimulacaoCompletaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoCompletaTypes.SIMULACAO_COMPLETA_REQUEST:
      return { ...state, loading: true }
    case SimulacaoCompletaTypes.SIMULACAO_COMPLETA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        tipoPortabilidade: action.payload.refinancing
          ? TipoPortabilidade.COM_TROCO
          : TipoPortabilidade.SEM_TROCO,
        seguro: !!action.payload.refinancing,
      }
    case SimulacaoCompletaTypes.SIMULACAO_COMPLETA_ERROR:
      return { ...state, loading: false }

    case SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_REQUEST:
      return { ...state, loadingContratacaoCompleta: true }
    case SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_SUCCESS:
      return {
        ...state,
        loadingContratacaoCompleta: false,
      }
    case SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_ERROR:
      return { ...state, loadingContratacaoCompleta: false }
    case SimulacaoCompletaTypes.SET_TIPO_PORTABILIDADE:
      return { ...state, tipoPortabilidade: action.payload }
    case SimulacaoCompletaTypes.SET_SEGURO_REFIN_PORTABILIDADE:
      return { ...state, seguro: action.payload }
    case SimulacaoCompletaTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
