import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Attachment from '@interco/icons/core/action-navigation/ic_attachment'
import CameraOn from '@interco/icons/core/media-communication/ic_photo'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
} from '@store/portabilidade/contrachequeDocumentos/actions'
import { ApplicationState } from '@store/types'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Loading } from '@atoms'
import routes from '@routes/routes'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { fileToBase64 } from '@utils/functions'

import { Container, CustomButton, DivLine, InputFile, LineDescription } from '../styles'
import { DotNumber } from '../ModoEnvio/styles'

const MaxFileSize = 2000
const ModoEnvioContracheque = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openLoading, setOpenLoading] = useState(false)
  const [bottomSheetQuantity, setBottomSheetQuantity] = useState(false)
  const [bottomSheetSize, setBottomSheetSize] = useState(false)
  const [uploadedCount, setUploadedCount] = useState(0)
  const [uploadedQuantity, setUploadedQuantity] = useState(0)
  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.portabilidade.contrachequeDocumentos,
  )
  const anexosSizes = anexos?.map((anexo) => anexo?.anexo?.size)
  const somaSizes =
    Number(anexosSizes.reduce((total, numero) => Number(total) + Number(numero), 0)) / 1000

  useEffect(() => {
    if (somaSizes > MaxFileSize) setBottomSheetSize(true)
  }, [somaSizes])

  const anexarDocumentos = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const files = Array.from(e.target.files)
      setUploadedQuantity(files.length)
      const size = files && files.reduce((sum, file) => sum + file.size, 0) / 1000
      if (files?.length > 2) {
        setBottomSheetQuantity(true)
      } else if ((size && size > MaxFileSize) || somaSizes + Number(size) > MaxFileSize) {
        setBottomSheetSize(true)
      } else {
        files?.forEach((file) => {
          const reader = new window.FileReader()
          reader.onloadend = async () => {
            if (file?.type === 'application/pdf') {
              dispatch(
                setAnexos({
                  anexo: {
                    id: uuidv4(),
                    nome: file.name,
                    tipoExtensao: file.type,
                    size: file.size,
                    blobs: [
                      {
                        height: 200,
                        width: 200,
                        data: await fileToBase64(file),
                      },
                    ],
                  },
                  tipo: 'CONTRACHEQUE',
                }),
              )

              setUploadedCount((prevCount) => prevCount + 1)
            } else {
              const image = new Image()
              image.src = reader.result as string
              image.onload = () => {
                dispatch(
                  setAnexos({
                    anexo: {
                      id: uuidv4(),
                      nome: file?.name || '',
                      tipoExtensao: file?.type || '',
                      size: file?.size,
                      blobs: [
                        {
                          height: image.height,
                          width: image.width,
                          data: image.src,
                        },
                      ],
                    },
                    tipo: 'CONTRACHEQUE',
                  }),
                )
                setUploadedCount((prevCount) => prevCount + 1)
              }
            }
          }

          if (file?.type === 'application/pdf') {
            reader.readAsArrayBuffer(file)
          } else {
            reader.readAsDataURL(file as Blob)
          }
        })
      }
    }
  }

  const sendPhoto = useCallback(() => {
    dispatch(setIsLoading(true))
    dispatch(setIsError(false))
    dispatch(
      sendToS3({
        tipo: 'CONTRACHEQUE',
        anexos,
        callback: () => {
          dispatch(trackingRequest(StepPortabilidadeNovo.ANEXO_DE_DOCUMENTOS_CONCLUIDO))
          navigate(routes.STATUS_DOCUMENTO_V2)
        },
      }),
    )
  }, [anexos, dispatch, navigate])

  useEffect(() => {
    if (anexos.length > 0 && uploadedQuantity === uploadedCount) {
      sendPhoto()
    }
  }, [anexos.length, sendPhoto, uploadedCount, uploadedQuantity])

  useEffect(() => {
    if (isDone) {
      navigate(routes.STATUS_DOCUMENTO_V2)
    }
  }, [navigate, isDone])

  return (
    <NovoPage
      id="modo-envio-contracheque"
      footerTwoButtonsSpace="180px"
      positionFooter="sticky"
      stickyContainerFooterBottom="16px"
      titleHeader="Foto do documento"
      stickyFooter={
        <>
          <CustomButton
            onClick={() => {
              dispatch(trackingRequest(StepPortabilidadeNovo.FOTOGRAFAR_DOCUMENTO))
              navigate(routes.FOTO_CONTRA_CHEQUE_V2)
            }}
          >
            <Text variant="body-3" color="typography" colorWeight={500} bold>
              Tirar uma foto agora
            </Text>
            <CameraOn width={24} height={24} color={Colors.PRIMARY500} />
          </CustomButton>
          <CustomButton
            onClick={() => {
              document.getElementById('input-camera')?.click()
            }}
          >
            <Text variant="body-3" color="typography" colorWeight={500} bold>
              Prefiro anexar a foto
            </Text>
            <Attachment width={24} height={24} color={Colors.PRIMARY500} />
            <InputFile
              id="input-camera"
              type="file"
              multiple
              accept="image/*, application/pdf"
              onChange={async (e) => {
                dispatch(trackingRequest(StepPortabilidadeNovo.ANEXAR_DOCUMENTO))
                anexarDocumentos(e)
              }}
            />
          </CustomButton>
        </>
      }
    >
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '0 0 24px 0',
        }}
      >
        Para enviar o documento, certifique-se que:
      </Text>
      <Container>
        <LineDescription>
          <DotNumber>
            <Text
              variant="caption-1"
              bold
              color="typography"
              colorWeight={100}
              style={{
                color: Colors.WHITE,
              }}
            >
              1
            </Text>
          </DotNumber>
          <DivLine>
            <Text variant="body-3">
              Caso escolha anexar, envie uma foto contendo frente e verso do documento
            </Text>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <DotNumber>
            <Text
              variant="caption-1"
              bold
              color="typography"
              colorWeight={100}
              style={{
                color: Colors.WHITE,
              }}
            >
              2
            </Text>
          </DotNumber>
          <DivLine>
            <Text variant="body-3">É possível ler todas as informações do documento na foto.</Text>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <DotNumber>
            <Text
              variant="caption-1"
              bold
              color="typography"
              colorWeight={100}
              style={{
                color: Colors.WHITE,
              }}
            >
              3
            </Text>
          </DotNumber>
          <DivLine>
            <Text variant="body-3">
              Não há reflexos na foto. Se precisar, retire o plástico protetor.
            </Text>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <DotNumber>
            <Text
              variant="caption-1"
              bold
              color="typography"
              colorWeight={100}
              style={{
                color: Colors.WHITE,
              }}
            >
              4
            </Text>
          </DotNumber>
          <DivLine>
            <Text variant="body-3">
              O documento está centralizado e que nada está na frente dele.
            </Text>
          </DivLine>
        </LineDescription>
      </Container>
      {isLoading || openLoading ? (
        <BottomSheet
          onClose={() => {
            setOpenLoading(false)
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loading width={30} height={30} />
          </div>
          <div
            style={{
              margin: '24px 0',
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            <Text variant="body-1" color="typography" colorWeight={500} bold>
              Carregando arquivo...
            </Text>
          </div>
        </BottomSheet>
      ) : (
        <></>
      )}
      {bottomSheetSize ? (
        <BottomSheet onClose={() => setBottomSheetSize(false)}>
          <Text
            variant="headline-h3"
            color="typography"
            colorWeight={500}
            semiBold
            style={{
              margin: '22px 0 16px 0',
            }}
          >
            Tamanho máximo excedido
          </Text>
          <Text variant="body-3">
            Os arquivos enviados devem somar no máximo 2MB de tamanho. Verifique o tamanho e tente
            novamente.
          </Text>
          <Button
            onClick={() => {
              setBottomSheetSize(false)
            }}
          >
            Fechar
          </Button>
        </BottomSheet>
      ) : (
        <></>
      )}
      {bottomSheetQuantity ? (
        <BottomSheet onClose={() => setBottomSheetQuantity(false)}>
          <Text
            variant="headline-h3"
            color="typography"
            colorWeight={500}
            semiBold
            style={{
              margin: '22px 0 16px 0',
            }}
          >
            Quantidade máxima excedida
          </Text>
          <Text variant="body-3">
            Envie no máximo 2 arquivos contendo frente e verso. Caso frente e verso não estejam
            presentes em apenas um arquivo.
          </Text>
          <Button
            onClick={() => {
              setBottomSheetQuantity(false)
            }}
          >
            Fechar
          </Button>
        </BottomSheet>
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default ModoEnvioContracheque
