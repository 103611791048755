import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/colors'
import NovoPage from '@templates/NovoPage'
import { requestMarginResult } from '@store/portabilidade/consultaMarginQuery/actions'
import { ConveniosCodes, ErrorCodesAPI } from '@utils/enums'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import Tags from '@utils/Tags'
import { sendPortabilityLeadRequest } from '@store/portabilidade/simulacao/actions'

import { FadeIn, LoadingComponent, TextContainer } from './styles'

interface PropsEnum {
  size: string
  bgColor: Colors
}

const enumLoading = {
  first: {
    size: '10%',
    bgColor: Colors.PRIMARY100,
  },
  second: {
    size: '30%',
    bgColor: Colors.PRIMARY200,
  },
  third: {
    size: '70%',
    bgColor: Colors.PRIMARY300,
  },
  fourth: {
    size: '100%',
    bgColor: Colors.PRIMARY400,
  },
}

const CarregamentoDados = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [enumLoad, setEnumLoad] = useState<PropsEnum>(enumLoading.first)
  const loading = false
  const { convenioSelecionado, nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  const callWeb = (redirectUrl: string) => {
    Tags.enviarTagueamentoWeb({
      element_name: '',
      section_name: 'Carregamentos de dados',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: `${window.location.origin}${redirectUrl}`,
      step: 'null',
    })
  }

  const callResult = useCallback(() => {
    dispatch(
      requestMarginResult({
        convenioDescricao: convenioSelecionado === ConveniosCodes.INSS ? 'inss' : 'siape',
        callback: (pendencia) => {
          if (pendencia === ErrorCodesAPI.R0001) {
            callWeb(routes.INSS_INDISPONIVEL_V2)
            navigate(routes.INSS_INDISPONIVEL_V2)
          } else if (pendencia === ErrorCodesAPI.R0002) {
            callWeb(routes.ENTRAREMOS_CONTATO_V2)
            navigate(routes.ENTRAREMOS_CONTATO_V2)
          } else if (pendencia === ErrorCodesAPI.R0003 || pendencia === ErrorCodesAPI.R0004) {
            callWeb(routes.NAO_LIBERADO_V2)
            navigate(routes.NAO_LIBERADO_V2, { state: pendencia })
          } else {
            dispatch(
              sendPortabilityLeadRequest({
                callback: (erroCode?: string) => {
                  if (erroCode === ErrorCodesAPI.R0002) {
                    callWeb(routes.ENTRAREMOS_CONTATO_V2)
                    navigate(routes.ENTRAREMOS_CONTATO_V2)
                  } else if (erroCode === ErrorCodesAPI.R0003 || erroCode === ErrorCodesAPI.R0004) {
                    callWeb(routes.NAO_LIBERADO_V2)
                    navigate(routes.NAO_LIBERADO_V2, { state: erroCode })
                  } else {
                    callWeb(routes.SIMULADOR_PORTABILIDADE)
                    navigate(routes.SIMULADOR_PORTABILIDADE)
                  }
                },
              }),
            )
          }
        },
      }),
    )
  }, [convenioSelecionado, dispatch, navigate])

  const callResultSiape = useCallback(() => {
    dispatch(
      sendPortabilityLeadRequest({
        callback: (erroCode?: string) => {
          if (erroCode === ErrorCodesAPI.R0002) {
            callWeb(routes.ENTRAREMOS_CONTATO_V2)
            navigate(routes.ENTRAREMOS_CONTATO_V2)
          } else if (erroCode === ErrorCodesAPI.R0003 || erroCode === ErrorCodesAPI.R0004) {
            callWeb(routes.NAO_LIBERADO_V2)
            navigate(routes.NAO_LIBERADO_V2, { state: erroCode })
          } else {
            callWeb(routes.SIMULADOR_PORTABILIDADE)
            navigate(routes.SIMULADOR_PORTABILIDADE)
          }
        },
      }),
    )
  }, [dispatch, navigate])

  useEffect(() => {
    BridgeService.tac({
      name: 'carregamento_dados',
      actions: ['autorizar_termo_inss', 'autorizar_termo_siape', 'continuar_dados_manuais'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Carregamentos de dados',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: 'Lembre-se: não solicitamos a devolução do valor depositado',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
    Tags.enviarTagueamentoWeb({
      element_name: 'Carregamento de dados',
      section_name: 'Carregamento de dados',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [nomeConvenioSelecionado])

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setEnumLoad(enumLoading.second)
    }, 2000)
    const intervalId2 = setTimeout(() => {
      setEnumLoad(enumLoading.third)
    }, 3000)
    const intervalId3 = setTimeout(() => {
      setEnumLoad(enumLoading.fourth)
    }, 6000)
    const intervalId4 = setTimeout(() => {
      BridgeService.tic({
        name: 'btn_continuar',
        action: 'retornar_margin_result',
      })
      if (convenioSelecionado === ConveniosCodes.INSS) {
        callResult()
      } else {
        callResultSiape()
      }
    }, 11000)

    return () => {
      clearTimeout(intervalId)
      clearTimeout(intervalId2)
      clearTimeout(intervalId3)
      clearTimeout(intervalId4)
    }
  }, [callResult, callResultSiape, convenioSelecionado, dispatch, navigate])

  return (
    <NovoPage
      id="carregamento-dados"
      showLeftIcon={false}
      footerTwoButtonsSpace="300px"
      stickyFooter={
        loading ? (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: não solicitamos a devolução do valor depositado
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent
              size={enumLoading.fourth.size}
              backgroundColor={enumLoading.fourth.bgColor}
            />
          </>
        ) : (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: não solicitamos a devolução do valor depositado
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent size={enumLoad.size} backgroundColor={enumLoad.bgColor} />
          </>
        )
      }
    >
      <div />
    </NovoPage>
  )
}
export default CarregamentoDados
