import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { BridgeService } from '@services/bridge'
// import { ConveniosCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import routes from '@routes/routes'
import { buscarDocumentos } from '@store/portabilidade/documentos/actions'
// import { validarRiscoEnderecoRequest } from '@store/portabilidade/endereco/actions'
import { buscarProposta } from '@store/portabilidade/proposta/actions'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'

import { FadeIn, LoadingComponent, TextContainer } from './styles'

interface PropsEnum {
  size: string
  bgColor: Colors
}

const enumLoading = {
  first: {
    size: '10%',
    bgColor: Colors.PRIMARY100,
  },
  second: {
    size: '30%',
    bgColor: Colors.PRIMARY200,
  },
  third: {
    size: '70%',
    bgColor: Colors.PRIMARY300,
  },
  fourth: {
    size: '100%',
    bgColor: Colors.PRIMARY400,
  },
}
const CarregamentoDadosFormalizacao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [enumLoad, setEnumLoad] = useState<PropsEnum>(enumLoading.first)
  // const { convenioSelecionado } = useSelector(
  //   (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  // )
  const { loading } = useSelector((state: ApplicationState) => state.portabilidade.documentos)

  useEffect(() => {
    BridgeService.tac({
      name: 'carregamento_dados_formalizacao',
      actions: ['solicitar_contratacao'],
    })
  }, [])

  // const callBuscarDocs = useCallback(() => {
  //   if (!BridgeService.isBrowser()) {
  //     dispatch(
  //       buscarDocumentos({
  //         callback: () => {
  //           navigate(routes.STATUS_DOCUMENTO_V2)
  //         },
  //       }),
  //     )
  //   } else if (convenioSelecionado === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
  //     dispatch(
  //       validarRiscoEnderecoRequest({
  //         callback: (naoLiberado: boolean) => {
  //           if (naoLiberado) {
  //             navigate(routes.NAO_LIBERADO_V2)
  //           } else {
  //             dispatch(
  //               buscarDocumentos({
  //                 callback: () => {
  //                   navigate(routes.STATUS_DOCUMENTO_V2)
  //                 },
  //               }),
  //             )
  //           }
  //         },
  //       }),
  //     )
  //   } else {
  //     dispatch(
  //       buscarDocumentos({
  //         callback: () => {
  //           navigate(routes.STATUS_DOCUMENTO_V2)
  //         },
  //       }),
  //     )
  //   }
  // }, [convenioSelecionado, dispatch, navigate])

  const callBuscarProposta = useCallback(() => {
    BridgeService.tic({
      name: 'btn_continuar',
      action: 'solicitar_documentos',
    })
    dispatch(
      buscarProposta({
        callback: () => {
          dispatch(
            buscarDocumentos({
              callback: () => {
                dispatch(trackingRequest(StepPortabilidadeNovo.INICIO_CAPTURA))
                navigate(routes.STATUS_DOCUMENTO_V2)
              },
            }),
          )
        },
        callbackError: () => {
          navigate(routes.NAO_LIBERADO_V2)
        },
      }),
    )
  }, [dispatch, navigate])

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setEnumLoad(enumLoading.second)
    }, 2000)
    const intervalId2 = setTimeout(() => {
      setEnumLoad(enumLoading.third)
    }, 3000)
    const intervalId3 = setTimeout(() => {
      setEnumLoad(enumLoading.fourth)
    }, 6000)
    const intervalId4 = setTimeout(() => {
      // callBuscarDocs()
      callBuscarProposta()
    }, 11000)

    return () => {
      clearTimeout(intervalId)
      clearTimeout(intervalId2)
      clearTimeout(intervalId3)
      clearTimeout(intervalId4)
    }
  }, [callBuscarProposta, navigate])

  return (
    <NovoPage
      id="carregamento-dados"
      showLeftIcon={false}
      footerTwoButtonsSpace="300px"
      stickyFooter={
        loading ? (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: não solicitamos a devolução do valor depositado
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent
              size={enumLoading.fourth.size}
              backgroundColor={enumLoading.fourth.bgColor}
            />
          </>
        ) : (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: não solicitamos a devolução do valor depositado
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent size={enumLoad.size} backgroundColor={enumLoad.bgColor} />
          </>
        )
      }
    >
      <div />
    </NovoPage>
  )
}
export default CarregamentoDadosFormalizacao
