import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const WrapText = styled.div`
  display: flex;
  flex-direction: column;
`
export const LineInfo = styled.div<{ removeBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin: 10px 0;
  border-bottom: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${Colors.GRAY200}`)};
`
export const Bolder = styled.b`
  font-weight: 700;
`
export const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const LineDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;
`
export const DivLine = styled.div`
  display: flex;
  width: 80%;
  margin-left: 16px;
`
export const CustomButton = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.GRAY200};
  border-radius: 8px;
`
export const InputFile = styled.input`
  display: none;
`
