import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'
import {
  deleteAnexo as deleteAnexoExtrato,
  setIsDone as setIsDoneExtrato,
  setIsError as setIsErrorExtrato,
  setIsLoading as setIsLoadingExtrato,
} from '@store/portabilidade/contrachequeDocumentos/actions'

import { Anexo } from '../types'
import { deleteAnexo, setIsDone, setIsError, setIsLoading } from '../actions'

interface Props {
  payload: {
    anexos: Anexo[]
    tipo: string
    callback?: () => void
  }
  type: string
}
export default function* sendToS3PDFSaga({ payload }: Props) {
  const tipoDocumentos = yield* select(
    (s: ApplicationState) => s.portabilidade.proposta.tipoDocumento,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  const regex = /data:.*base64,/
  try {
    const files = payload.anexos.flatMap((e) =>
      e.anexo.blobs.map((blob) => ({
        file: blob.data.replace(regex, ''),
        extension: `.${e.anexo.nome.split('.').pop()}` || '',
      })),
    )
    const type =
      tipoDocumentos?.filter((tipo) => tipo.idTipoDocumento === payload.tipo)[0]?.idTipoDocumento ||
      payload.tipo

    yield* call(
      services.postEnviarDocumentos,
      {
        files,
        type,
      },
      accessToken,
    )

    if (payload.tipo === 'RG_CNH') {
      yield put(setIsDone(true))
      yield put(setIsLoading(false))
    } else {
      yield put(setIsDoneExtrato(true))
      yield put(setIsLoadingExtrato(false))
    }
  } catch (error) {
    if (payload.tipo === 'RG_CNH') {
      yield put(setIsError(true))
      yield put(setIsLoading(false))
      yield put(deleteAnexo(0))
    } else {
      yield put(setIsErrorExtrato(true))
      yield put(setIsLoadingExtrato(false))
      yield put(deleteAnexoExtrato(0))
    }

    if (payload.callback) {
      yield payload.callback()
    }
  }
}
