import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import { configureStore } from '@reduxjs/toolkit'

import rootSaga from './rootSaga'
import ui from './ui'
import portabilidade from './portabilidade'
import session from './session'
import globalApi from './globalApi'
import token from './token'
import biometria from './biometria'

const sagaMiddleware = createSagaMiddleware()

export const reducers = combineReducers({
  ui: persistReducer({ key: 'ui', storage }, ui),
  globalApi: persistReducer({ key: 'globalApi', storage }, globalApi),
  portabilidade: persistReducer({ key: 'portabilidade', storage }, portabilidade),
  session: persistReducer({ key: 'session', storage }, session),
  token: persistReducer({ key: 'token', storage }, token),
  biometria: persistReducer({ key: 'biometria', storage }, biometria),
})

const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
