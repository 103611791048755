import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { Camera } from '@interco/cp-react-ui-lib'
import { useWindowSize } from '@hooks'
import { setHideFooter } from '@store/ui/navigation/actions'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
  setRollbackRouterError,
} from '@store/portabilidade/contrachequeDocumentos/actions'
import { ReviewFoto } from '@organisms/ReviewFoto'
import routes from '@routes/routes'
import NovoPage from '@templates/NovoPage'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'

const FotoContracheque = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [habilitaCamera, setHabilitaCamera] = useState(true)
  const [foto, setFoto] = useState('')
  const [fullScreenMobile, setFullScreenMobile] = useState(false)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.portabilidade.contrachequeDocumentos,
  )

  const size = useWindowSize()

  useEffect(() => {
    if (size.width < 680) {
      setFullScreenMobile(true)
      dispatch(setHideFooter(true))
    } else {
      setFullScreenMobile(false)
      dispatch(setHideFooter(false))
    }
    return () => {
      setFullScreenMobile(false)
    }
  }, [dispatch, size])

  useEffect(() => {
    if (isDone) {
      navigate(routes.STATUS_DOCUMENTO_V2)
    }
  }, [navigate, isDone])

  if (!BridgeService.isBrowser()) {
    interWbSession.editToolbar('', false)
  }

  const onConfirmReview = (fotografia: string) => {
    if (anexos.length === 1) {
      dispatch(setIsLoading(true))
      dispatch(setIsError(false))
      dispatch(setRollbackRouterError(routes.STATUS_DOCUMENTO_V2))
      dispatch(sendToS3({ tipo: 'CONTRACHEQUE', anexos }))
    } else {
      const img = new Image()
      img.onload = () => {
        dispatch(
          setAnexos({
            anexo: {
              id: uuidv4(),
              nome: `CONTRACHEQUE.jpeg`,
              tipoExtensao: 'jpeg',
              blobs: [
                {
                  height: img.height,
                  width: img.width,
                  data: fotografia,
                },
              ],
            },
            tipo: 'CONTRACHEQUE',
          }),
        )
        if (anexos.length === 1) {
          dispatch(setIsLoading(true))
          dispatch(setIsError(false))
          dispatch(setRollbackRouterError(routes.STATUS_DOCUMENTO_V2))
          dispatch(sendToS3({ tipo: 'CONTRACHEQUE', anexos }))
        }
      }
      img.src = fotografia
    }
  }

  const tipoTexto = () => 'Frente do Contra Cheque'

  return habilitaCamera ? (
    <NovoPage id="foto-contracheque" headerPosition="fixed">
      <Camera
        audio={false}
        mask="secondary"
        mode="photo"
        facing="back"
        mobile={isApp || fullScreenMobile}
        onCapturedPhoto={(fotografia) => {
          setHabilitaCamera(false)
          setFoto(String(fotografia))
        }}
        titleDescription={tipoTexto()}
      />
    </NovoPage>
  ) : (
    <ReviewFoto
      fotografia={foto}
      onConfirm={onConfirmReview}
      setHabilitaCamera={() => {
        setHabilitaCamera(true)
      }}
      isLoading={isLoading}
      forceIsMobile={fullScreenMobile}
    />
  )
}

export default FotoContracheque
