import React from 'react'

import { useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import Tags from '@utils/Tags'
import { ApplicationState } from '@store/types'
import { UnavailableType } from '@store/portabilidade/simulacaoCompleta/types'

import { Container } from './styles'
import { Header } from '../styles'

interface OwnProps {
  contratos: UnavailableType[]
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetContratoIndisponivel = ({ contratos, setShowBottomSheet }: OwnProps) => {
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  return (
    <BottomSheet onClose={() => setShowBottomSheet(false)} noSwipe>
      <Container>
        <Header>
          <Text variant="body-2" bold colorWeight={500}>
            {contratos.length > 1
              ? 'Contratos indisponíveis para Portabilidade'
              : 'Contrato indisponível para Portabilidade'}
          </Text>
        </Header>
        <Text variant="body-3">
          Identificamos que o contrato <strong>{contratos[0].contractNumber}</strong> não é aceito
          devido a políticas internas de crédito. O outro contrato selecionado está de acordo.
        </Text>

        <Button
          fullWidth
          style={{ marginTop: '8px' }}
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Contrato indisponível para Portabilidade',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Entendi',
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            setShowBottomSheet(false)
          }}
        >
          Entendi
        </Button>
      </Container>
    </BottomSheet>
  )
}
export default BottomsheetContratoIndisponivel
