/**
 * Converte um number para string de moeda(Real)
 * @param value Number
 * @returns R$ 999,99
 */
export const formatCurrency = (value: number | string | undefined) =>
  !value
    ? 'R$ 0,00'
    : value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      })

/**
 * Retorna o valor convertido de uma string para number
 * @param value String
 * @returns  999.99
 */
export const currencyToFloat = (value: string) => {
  if (value) {
    const numValue = Number(value.replace(/[^\d]+/g, '')) / 100
    return parseFloat(numValue.toFixed(2))
  }
  return 0
}

/**
 * Adiciona mascara de telefone sobre o valor passado
 * @param value String
 * @returns (00) 00000-0000
 */
export const phoneMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
    p = p.replace(/(\d{5}|\d{4})(\d{4})$/, '$1-$2') // Coloca hífen entre o quarto e o quinto dígitos
  }
  return p
}

/**
 * Adiciona mascara de cpf sobre o valor passado
 * @param value String
 * @returns 000.000.000-00
 */
export const cpfMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/(\d{3})(\d)/, '$1.$2') // Primeira sequencia de três digitos
    p = p.replace(/(\d{3})(\d)/, '$1.$2') // Segunda sequencia de três digitos
    p = p.replace(/(\d{3})(\d{1,2})$/, '$1-$2') // Terceira sequencia e coloca hifem e os dois ultimos digitos
  }
  return p
}

/**
 * Adiciona mascara de data sobre o valor passado
 * @param value String
 * @returns 00/00/0000
 */
export const dataMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/(\d{2})(\d)/, '$1/$2')
    p = p.replace(/(\d{2})(\d)/, '$1/$2')
    p = p.replace(/(\d{4})(\d)/, '$1')
  }
  return p
}

/**
 * Remove mascaras diversas, deixando apenas digitos
 * @param value String
 * @returns (00) 00000-0000
 */
export const removeMask = (value: string) => value.replace(/\D/g, '')

/**
 * Converte um number para string de moeda(Real) sem cifrão
 * @param value Number
 * @returns 999,99
 */
export const formatCurrencySemCifrao = (value: number | '' | undefined) =>
  !value
    ? '0,00'
    : value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

/**
 * Adiciona mascara de prec-cp sobre o valor passado
 * @param value String
 * @returns 00 0000000
 */
export const precCPMask = (value: string | number | undefined) => {
  let precCPValue = String(value)
  if (precCPValue) {
    precCPValue = precCPValue.replace(/\D/g, '') // Remove tudo o que não é dígito
    precCPValue = precCPValue.replace(/^(\d{2})(\d)/g, '$1 $2') // Separa os dois primeiros dígitos
  }
  return precCPValue
}

/**
 * Adiciona mascara de inss
 * @param value String
 */
export const inssMask = (value: string) => {
  if (value) {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{9})(\d)/, '$1-$2')
  }
  return value
}

/**
 * Adiciona máscara para matrícula do SIAPE
 * @param value String
 */
export const siapeMask = (value: string) => {
  value = value.replace(/\D/g, '') // Remove tudo o que não é dígito
  const valueRemoveZeros = value.replace(/^0+/, '') // Remove os zeros a esquerda
  let valueReturn = ''
  if (valueRemoveZeros) {
    // Trabalhando com o substring end em 7 pra que o caso da matricula '1234567' não fique com zero no inicio
    valueReturn = '0000000'.substring(valueRemoveZeros.toString().length, 7) + valueRemoveZeros
  }
  if (valueReturn.startsWith('00')) {
    valueReturn = `0${valueReturn}`
  }
  return valueReturn
}

export const numberMask = (value: string) => {
  value = value.replace(/\D/g, '')
  return value
}

/**
 * Adiciona mascara de cep sobre o valor passado
 * @param value String
 * @returns 00.000-000
 */

export const cepMask = (value: string) => {
  let p = String(value)
  if (p) {
    p = p.replace(/\D/g, '') // Remove tudo o que não é dígito
    p = p.replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
    p = p.replace(/\.(\d{3})(\d)/, '.$1-$2') // Coloca hífen entre o quinto e o sexto dígitos
  }
  return p
}

/**
 * Adiciona máscara para agencia
 * @param value String
 */
export const agenciaMask = (value: string) => {
  value = value.replace(/\D/g, '') // Remove tudo o que não é dígito
  return value
}
