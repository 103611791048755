import React from 'react'

import { useSelector } from 'react-redux'

import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'

const DeclaracaoResidencia = () => {
  const { dadosProposta } = useSelector((state: ApplicationState) => state.portabilidade.proposta)
  const { email } = useSelector((state: ApplicationState) => state.portabilidade.contato)

  const variant = 'web'

  return (
    <TemplatesPDF.PageDeclaracaoResidencia
      variant={variant}
      pessoa={{
        nome: dadosProposta.nomeCliente,
        cpf: dadosProposta.cpf,
        nacionalidade: dadosProposta?.nacionalidade,
        estado: dadosProposta?.estadoResidencial,
        celular: dadosProposta?.telefoneCelular,
        email,
        endereco: `${dadosProposta?.enderecoResidencial}, ${dadosProposta?.numeroResidencial} - ${dadosProposta?.bairroResidencial} - ${dadosProposta?.cidadeResidencial}/${dadosProposta?.estadoResidencial}`,
      }}
      contato={{
        cep: '30180-121',
        estado: 'Belo Horizonte',
        endereco: 'Avenida Barbacena, 1219 – Santo Agostinho',
        telefone: '55 31 2101-7000',
      }}
      text={[
        {
          text: `Declaro ainda, que fui devidamente alertado quanto à importância desta informação, o que garante o 
            recebimento das correspondências eventualmente enviadas, e estou ciente de que a falsidade da presente 
            declaração pode implicar na sanção penal prevista no Art. 299 do Código Penal, conforme transcrição 
            abaixo:`,
        },
        {
          text: `“ Art. 299 – Omitir, em documento público ou particular, declaração que nele deveria constar, ou nele
             inserir ou fazer inserir Declaração falsa ou diversa da que devia ser escrita, com o fim de prejudicar 
             direito, criar obrigação ou alterar a verdade sobre o fato juridicamente relevante” “Pena: reclusão de 1 
             (um) a 5 (cinco) anos e multa, se o documento é público e reclusão de 1 (um) a 3 (três) anos, se o 
             documento é particular.”`,
          paddingLeft: '70px',
        },
        {
          text: `Comprometo-me, por fim, a manter os dados cadastrais junto ao Banco Inter atualizados durante a 
            vigência desta contratação, incluindo, mas não se limitando a endereço, telefone, e-mail e outros dados 
            referentes à minha localização e efetiva recepção de documentos e informações relevantes, inclusive dados 
            cadastrais e econômicos.`,
        },
      ]}
      footer={{
        atualizado: new Date('2020-11-12'),
        codigo: 'CP090',
        edicao: '1a',
        divulgacao: 'Pública',
        contatos: [
          'Central de Atendimento 3003-4070 (Capitais e Regiões Metropolitanas)',
          'Demais Localidades: 0800 9400007',
          'SAC Cartão Inter 08009409999 www.bancointer.com.br',
          'Ouvidoria 08009407772 Deficiente Fala e Audição 0800 979 7099',
        ],
      }}
      assinatura={{
        isDgAssinado: true,
        cidade: 'Belo Horizonte',
        data: new Date(),
      }}
    />
  )
}

export default DeclaracaoResidencia
