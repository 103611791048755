import { call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { showError } from '@store/ui/error/actions'
import postBeginSession from '@services/postBeginSession'

import { requestSession, setLoadingSession } from '../actions'
import { SessionCallback } from '../types'

export default function* postBeginSessionSaga(action: AnyAction) {
  const payload = action.payload as SessionCallback
  try {
    yield put(setLoadingSession(true))
    yield* call(postBeginSession)
    yield put(setLoadingSession(false))
    payload.callback()
  } catch (error) {
    yield put(setLoadingSession(false))
    yield put(
      showError({
        title: 'Erro ao criar sessão',
        message: 'Ocorreu um erro ao criar sessão',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => requestSession(payload),
      }),
    )
  }
}
