import { BancoNovoApi } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export default (accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_GET_BANCOS_NOVO}`
    : process.env.REACT_APP_GET_BANCOS_NOVO
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<BancoNovoApi>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
