import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'

import { Dot, OrangeText } from '../../styles'

interface OwnProps {
  setOpenBottomsheet: React.Dispatch<React.SetStateAction<boolean>>
}

const ondeEncontrarDadosList = [
  'No contrato de empréstimo que deseja trazer para o Inter.',
  'No seu Documento Descritivo de Crédito (DDC)',
]

const OndeEncontrar = ({ setOpenBottomsheet }: OwnProps) => {
  const navigate = useNavigate()
  return (
    <BottomSheet onClose={() => setOpenBottomsheet(false)} noSwipe>
      <div
        style={{
          margin: '0 0 24px 0',
        }}
      >
        <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
          Você pode encontrar os dados:
        </Text>
      </div>

      <>
        {ondeEncontrarDadosList.map((informacao, index) => (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                margin: '0 0 16px 0',
              }}
            >
              <Dot />
              <Text variant="body-3">
                {informacao}
                <br />
                {ondeEncontrarDadosList.length === index + 1 ? (
                  <OrangeText
                    onClick={() => {
                      navigate(routes.DDC)
                    }}
                  >
                    Saiba como solicitar
                  </OrangeText>
                ) : (
                  ''
                )}
              </Text>
            </div>
          </>
        ))}
      </>

      <Button
        fullWidth
        style={{ marginTop: '8px' }}
        onClick={() => {
          setOpenBottomsheet(false)
        }}
      >
        Entendi
      </Button>
    </BottomSheet>
  )
}
export default OndeEncontrar
