import React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/colors'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { Header } from '@atoms/Header'

import {
  CustomGrid,
  LeftButton,
  LineButtons,
  LineButtonsWeb,
  LineDescription,
  PageImage,
  RightButton,
  VisualizarFoto,
  RootPage,
} from './styles'

interface ReviewProps {
  fotografia: string
  setHabilitaCamera: () => void
  onConfirm: (arg: string) => void
  isLoading: boolean
  forceIsMobile: boolean
}

interface ReviewPropsComp {
  fotografia: string
  setHabilitaCamera: () => void
  onConfirm: (arg: string) => void
  isLoading: boolean
}

const MobileView = ({ fotografia, setHabilitaCamera, onConfirm, isLoading }: ReviewPropsComp) => (
  <>
    <Header showLeftIcon headerPosition="fixed">
      Foto de documento
    </Header>
    <PageImage
      id="foto-review"
      isApp
      hideHeader
      hasProgressStep={false}
      fotografia={fotografia}
      stickyFooter={
        <>
          <LineDescription>
            <Text
              variant="headline-h3"
              color="typography"
              colorWeight={500}
              semiBold
              style={{
                textAlign: 'center',
                color: Colors.WHITE,
              }}
            >
              Sua foto ficou nítida e bem iluminada?
            </Text>
            <Text
              variant="body-3"
              style={{
                textAlign: 'center',
                color: Colors.WHITE,
              }}
            >
              Lembre-se de que não pode ter reflexos.
            </Text>
          </LineDescription>
          <LineButtons>
            <LeftButton
              size="small"
              fullWidth
              variant="secondary"
              onClick={() => {
                setHabilitaCamera()
              }}
              disabled={isLoading}
            >
              Tirar outra
            </LeftButton>
            <div style={{ width: '16px' }} />
            <RightButton
              size="small"
              variant="primary"
              fullWidth
              onClick={() => {
                onConfirm(fotografia)
              }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Usar esta
            </RightButton>
          </LineButtons>
        </>
      }
    >
      <CustomGrid>
        <Text
          variant="headline-h3"
          color="typography"
          colorWeight={500}
          semiBold
          style={{
            textAlign: 'center',
            color: Colors.WHITE,
          }}
        >
          Revisar foto
        </Text>
      </CustomGrid>
    </PageImage>
  </>
)

const WebView = ({ fotografia, setHabilitaCamera, onConfirm, isLoading }: ReviewPropsComp) => (
  <RootPage id="review-web" isApp={false} hideHeader={false} hasProgressStep={false} fullPage>
    <LineDescription>
      <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
        Sua foto ficou nítida e bem iluminada?
      </Text>
      <Text variant="body-3" color="typography" colorWeight={500}>
        Lembre-se de que não pode ter reflexos.
      </Text>
    </LineDescription>
    <VisualizarFoto src={fotografia} alt="Fotografia" />
    <LineButtonsWeb>
      <Button
        variant="secondary"
        fullWidth
        onClick={() => {
          setHabilitaCamera()
        }}
        disabled={isLoading}
        size="small"
      >
        Tirar outra
      </Button>
      <div style={{ width: '24px' }} />
      <Button
        fullWidth
        variant="primary"
        onClick={() => {
          onConfirm(fotografia)
        }}
        disabled={isLoading}
        isLoading={isLoading}
        size="small"
      >
        Usar esta
      </Button>
    </LineButtonsWeb>
  </RootPage>
)

export const ReviewFoto = ({
  fotografia,
  setHabilitaCamera,
  onConfirm,
  isLoading,
  forceIsMobile,
}: ReviewProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!BridgeService.isBrowser()) {
    interWbSession.editToolbar('', false)
  }

  if (isApp || forceIsMobile) {
    return (
      <MobileView
        fotografia={fotografia}
        setHabilitaCamera={setHabilitaCamera}
        onConfirm={onConfirm}
        isLoading={isLoading}
      />
    )
  }

  return (
    <WebView
      fotografia={fotografia}
      setHabilitaCamera={setHabilitaCamera}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  )
}
