import { BaseServices, HttpMethod } from '@services/base'

export default () => {
  const pathname = process.env.REACT_APP_POST_BEGIN_SESSION
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request({
    pathname,
    method,
    host,
  })
}
