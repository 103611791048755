import styled, { keyframes } from 'styled-components'

import ISafeOutlineIcon from '@interco/icons/build-v4/orangeds/SM/i-safe'

export const ISafeOutline = styled(ISafeOutlineIcon)`
  path {
    stroke: var(--primary500);
    stroke-width: 2;
    fill: none;

    &:last-of-type {
      stroke-width: 1.2;
    }
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  & > svg:first-of-type {
    position: absolute;
    z-index: var(--zIndexMaximum);
  }
`

const spinner = keyframes`
  0% { stroke-dashoffset: 306; }
  50% { stroke-dasharray: 40,134; }
  to {
    stroke-dasharray: 1,174;
    stroke-dashoffset: 132;
  }
`

export const Svg = styled.svg`
  color: var(--primary500);
  overflow: visible;
`

export const Circle = styled.circle`
  animation: 1.5s linear 0s infinite normal none running ${spinner};
`
