import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { GlobalApiTypes } from '@store/globalApi/types'
import {
  getBancos,
  getBancosNovo,
  getCidades,
  getConvenios,
  getEstados,
  getFontesPagamento,
  getOrgaos,
  getUpags,
} from '@store/globalApi/sagas'
import getDadosBancariosInssSaga from '@store/globalApi/sagas/getDadosBancariosInssSaga'
import { getBancos as getBancosPortabilidade } from '@store/portabilidade/dadosBancarios/sagas'

import { SimulacaoTypes } from './simulacao/types'
import {
  postSalvarLead,
  postTrackPortabilidade,
  postSimularPortabilidadeSaga,
  postSendPortabilityLeadSaga,
} from './simulacao/sagas'
import { ValidaTypes } from './validacao/types'
import { getConvenioByTokenSaga, postAutorizacaoISafeSaga, postValida } from './validacao/sagas'
import { SituacaoFuncionalTypes } from './situacaoFuncional/types'
import { getDadosBeneficiarioInssSaga, postValidarMatriculaSaga } from './dadosProfissionais/sagas'
import { DadosProfissionaisTypes } from './dadosProfissionais/types'
import { ConsultMarginQueryTypes } from './consultaMarginQuery/types'
import { marginQueryAcceptSaga, marginQueryResultSaga } from './consultaMarginQuery/sagas'
import { ConsultaMargemGeralTypes } from './consultaMargemGeral/types'
import {
  getCacheSiapeSaga,
  postAtualizarCacheSiapeSaga,
  postConsultarMargemGeralSaga,
} from './consultaMargemGeral/sagas'
import { PropostaTypes } from './proposta/types'
import {
  getBuscarProposta,
  postDetalhesPropostaSaga,
  postSalvarPropostaSaga,
} from './proposta/sagas'
import { FotoDocumentosTypes } from './fotoDocumentos/types'
import { sendToS3PDF } from './fotoDocumentos/sagas'
import { ContrachequeDocumentosTypes } from './contrachequeDocumentos/types'
import { DocumentosTypes } from './documentos/types'
import {
  buscarDocumentosSaga,
  postCapturaDocumentosSaga,
  postDocumentosAssinaturaSaga,
} from './documentos/sagas'
import { DocumentosAssinaturaTypes } from './documentosAssinatura/types'
import { finalizarPropostaSaga } from './documentosAssinatura/sagas'
import { EnderecoTypes } from './endereco/types'
import { getEndereco, postRiscoEndereco } from './endereco/sagas'
import {
  postContratacaoCompletaSaga,
  postSimularPortabilidadeCompletaSaga,
} from './simulacaoCompleta/sagas'
import { SimulacaoCompletaTypes } from './simulacaoCompleta/types'
import { DadosBancariosTypes } from './dadosBancarios/types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(GlobalApiTypes.BANCOS_REQUEST, getBancos)
  yield* takeLatest(DadosBancariosTypes.BANCOS_REQUEST, getBancosPortabilidade)
  yield* takeLatest(GlobalApiTypes.BANCOS_REQUEST_NOVO, getBancosNovo)
  yield* takeLatest(GlobalApiTypes.FONTES_REQUEST, getFontesPagamento)
  yield* takeLatest(GlobalApiTypes.CONVENIOS_REQUEST, getConvenios)
  yield* takeLatest(GlobalApiTypes.ESTADOS_REQUEST, getEstados)
  yield* takeLatest(GlobalApiTypes.CIDADES_REQUEST, getCidades)
  yield* takeLatest(GlobalApiTypes.ORGAOS_REQUEST, getOrgaos)
  yield* takeLatest(DadosProfissionaisTypes.UPAGS_REQUESTS, getUpags)
  yield* takeLatest(ValidaTypes.VALIDA_REQUEST, postValida)
  yield* takeLatest(DadosProfissionaisTypes.VALIDAR_MATRICULA, postValidarMatriculaSaga)
  yield* takeLatest(SituacaoFuncionalTypes.VALIDAR_MATRICULA, postValidarMatriculaSaga)
  yield* takeLatest(SimulacaoTypes.SALVAR_LEAD_REQUEST, postSalvarLead)
  yield* takeLatest(SimulacaoTypes.TRACKING_REQUEST, postTrackPortabilidade)
  yield* takeLatest(
    DadosProfissionaisTypes.DADOS_BENEFICIARIO_INSS_REQUEST,
    getDadosBeneficiarioInssSaga,
  )
  yield* takeLatest(ConsultMarginQueryTypes.REQUEST_MARGIN_ACCEPT, marginQueryAcceptSaga)
  yield* takeLatest(ValidaTypes.REQUEST_CONVENIO_BY_TOKEN, getConvenioByTokenSaga)
  yield* takeLatest(ValidaTypes.POST_ISAFE_AUTORIZACAO, postAutorizacaoISafeSaga)
  yield* takeLatest(ConsultMarginQueryTypes.REQUEST_MARGIN_RESULT, marginQueryResultSaga)
  yield* takeLatest(ConsultaMargemGeralTypes.ATUALIZAR_SIAPE_CACHE, postAtualizarCacheSiapeSaga)
  yield* takeLatest(ConsultaMargemGeralTypes.GET_SIAPE_CACHE, getCacheSiapeSaga)
  yield* takeLatest(
    ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_REQUEST,
    postConsultarMargemGeralSaga,
  )
  yield* takeLatest(SimulacaoTypes.SIMULACAO_REQUEST_PORTABILITY, postSimularPortabilidadeSaga)
  yield* takeLatest(
    SimulacaoCompletaTypes.SIMULACAO_COMPLETA_REQUEST,
    postSimularPortabilidadeCompletaSaga,
  )
  yield* takeLatest(
    SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_REQUEST,
    postContratacaoCompletaSaga,
  )
  yield* takeLatest(SimulacaoTypes.SEND_PORTABILITY_LEAD_REQUEST, postSendPortabilityLeadSaga)
  yield* takeLatest(PropostaTypes.BUSCAR_PROPOSTAS, getBuscarProposta)
  yield* takeLatest(GlobalApiTypes.DADOS_BANCARIOS_INSS_REQUEST, getDadosBancariosInssSaga)
  yield* takeLatest(PropostaTypes.SALVAR_PROPOSTA_REQUEST, postSalvarPropostaSaga)
  yield* takeLatest(PropostaTypes.DADOS_PROPOSTA_REQUEST, postDetalhesPropostaSaga)
  yield* takeLatest(DocumentosTypes.CAPTURA_DOCUMENTOS_REQUEST, postCapturaDocumentosSaga)
  yield* takeLatest(DocumentosTypes.DOCUMENTOS_ASSINATURA_REQUEST, postDocumentosAssinaturaSaga)
  yield* takeLatest(DocumentosTypes.BUSCAR_DOCUMENTOS_SAGA, buscarDocumentosSaga)
  yield* takeLatest(FotoDocumentosTypes.SEND_TO_S3_SAGA, sendToS3PDF)
  yield* takeLatest(ContrachequeDocumentosTypes.SEND_TO_S3_SAGA_CONTRACHEQUE, sendToS3PDF)
  yield* takeLatest(DocumentosAssinaturaTypes.FINALIZAR_PROPOSTA, finalizarPropostaSaga)
  yield* takeLatest(EnderecoTypes.ENDERECO_REQUEST, getEndereco)
  yield* takeLatest(EnderecoTypes.RISCO_ENDERECO_REQUEST, postRiscoEndereco)
}
