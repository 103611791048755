import React, {
  ReactNode,
  memo,
  PropsWithChildren,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react'

import InputMask from 'react-input-mask'
import type { Props as ReactInputMaskProps } from 'react-input-mask'

import HideIcon from '@interco/icons/build-v4/orangeds/XL/hide'
import ShowIcon from '@interco/icons/build-v4/orangeds/XL/show'

import { currencyMask } from '../../utils'
import { MAX_STRENGTH_LEVEL } from '../StrongPassword'
import * as S from './styles'
import { dynamicMask } from './common-mask'

export * from './common-mask'

type IconClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void

type InputType =
  | 'text'
  | 'number'
  | 'email'
  | 'password'
  | 'tel'
  | 'search'
  | 'url'
  | 'currency'
  | 'time'
  | 'color'

export type PasswordLevel = 0 | 1 | 2 | 3 | 4

export type InputComponentProps = {
  disabled?: boolean
  error?: boolean
  helper?: ReactNode
  list?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  infoText?: string
  name?: string
  readOnly?: boolean
  success?: boolean
  type?: InputType
  currency?: 'BRL' | 'USD'
  value?: string | number
  dataTestId?: string
  maxLength?: number
  onlyNumber?: boolean
  onClickIconLeft?: IconClick
  onClickIconRight?: IconClick
  placeholder?: string
  /** Works only in input type password */
  passwordLevel?: PasswordLevel
  visibilityPasswordIcon?: boolean
} & ({ label?: never; id?: never } | { label?: string; id: string })

export type DefaultInputProps<T = HTMLInputElement> = Partial<
  Omit<
    HTMLInputElement,
    'form' | 'style' | 'capture' | 'enterKeyHint' | 'value' | 'list' | 'min' | 'max'
  >
> &
  Omit<DefaultHTMLAttrs<T>, 'css' | 'value' | 'defaultValue'> &
  InputComponentProps

export type InputProps = DefaultInputProps & {
  mask?: ReactInputMaskProps['mask'] | string[]
  maskPlaceholder?: ReactInputMaskProps['maskPlaceholder']
}

type InputReference = { current: HTMLInputElement }
type ChangeEvent = React.ChangeEvent<HTMLInputElement>
type EventHandler = (event: ChangeEvent) => void

const passwordIconProps = {
  width: 24,
  height: 24,
  color: 'var(--highlight-color)',
}

const InputIcon = (
  icon: ReactNode,
  Wrapper: ({ children }: PropsWithChildren<DefaultHTMLAttrs>) => JSX.Element,
  onClick?: IconClick,
) => <Wrapper onClick={onClick}>{icon}</Wrapper>

const DefaultInput = React.forwardRef(
  (
    {
      id,
      disabled = false,
      error = false,
      helper,
      iconLeft,
      iconRight,
      infoText,
      label,
      list = '',
      name,
      placeholder,
      readOnly = false,
      success = false,
      type = 'text',
      className,
      style,
      dataTestId = '',
      onClickIconLeft,
      onClickIconRight,
      passwordLevel,
      visibilityPasswordIcon = false,
      ...attrs
    }: DefaultInputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(false)
    const inputReference = useRef<HTMLInputElement>(null)
    const inputType = useMemo<InputType>(() => {
      if (type !== 'password') return type
      return showPassword ? 'text' : 'password'
    }, [showPassword, type])

    const passwordIcon = useMemo(() => {
      if (!visibilityPasswordIcon) {
        return undefined
      }

      return showPassword ? (
        <ShowIcon {...passwordIconProps} />
      ) : (
        <HideIcon {...passwordIconProps} />
      )
    }, [showPassword, visibilityPasswordIcon])

    const onClickIconWrapper =
      (onClickIcon: IconClick | undefined) =>
      (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        onClickIcon?.(event)
        inputReference.current?.focus()
      }

    useEffect(() => {
      if (attrs.currency && inputReference.current?.value) {
        ;(inputReference as InputReference).current.value = currencyMask(
          inputReference.current?.value as string,
          attrs.currency,
        )
      }
    }, [attrs.currency, type])

    return (
      <S.Container
        disabled={disabled}
        className={className}
        style={style}
        data-testid={dataTestId || id}
      >
        {(label || helper) && (
          <S.Title>
            <S.Label
              htmlFor={id}
              error={error}
              success={success}
              disabled={disabled}
              data-testid={`${dataTestId || id}_input-label`}
            >
              {label}
            </S.Label>

            {passwordLevel === undefined && helper && (
              <span data-testid={`${dataTestId || id}_input-helper`}>{helper}</span>
            )}

            {passwordLevel !== undefined && (
              <S.PasswordLevelContainer>
                {Array.from(new Array(MAX_STRENGTH_LEVEL)).map((_, index) => {
                  const currentLevelBlock = index + 1
                  return (
                    <S.PasswordLevelBlock
                      key={`password-level-${currentLevelBlock}`}
                      data-testid="password-level-block"
                      level={
                        passwordLevel && currentLevelBlock <= passwordLevel ? passwordLevel : 0
                      }
                    />
                  )
                })}
              </S.PasswordLevelContainer>
            )}
          </S.Title>
        )}

        <S.Box error={error} success={success} disabled={disabled}>
          {iconLeft && InputIcon(iconLeft, S.IconLeft, onClickIconWrapper(onClickIconLeft))}
          <S.Input
            id={id}
            name={name}
            type={inputType}
            placeholder={placeholder}
            disabled={disabled}
            hasIconRight={Boolean(iconRight)}
            hasIconLeft={Boolean(iconLeft)}
            list={list || undefined}
            readOnly={readOnly}
            ref={(reference) => {
              if (!reference) return
              ;(inputReference as InputReference).current = reference

              if (ref && typeof ref === 'function') {
                ref(reference)
              }

              if (ref) {
                ;(ref as InputReference).current = reference
              }
            }}
            {...attrs}
          />
          {(passwordIcon &&
            InputIcon(passwordIcon, S.IconRight, () => setShowPassword(!showPassword))) ||
            (iconRight && InputIcon(iconRight, S.IconRight, onClickIconWrapper(onClickIconRight)))}
        </S.Box>
        {infoText && (
          <S.InfoText
            error={error}
            success={success}
            disabled={disabled}
            data-testid={`${dataTestId || id}_input-info`}
          >
            {infoText}
          </S.InfoText>
        )}
      </S.Container>
    )
  },
)

const InputWithMask = React.forwardRef(
  (
    {
      mask,
      maskPlaceholder = null,
      disabled,
      value,
      onChange,
      onInput,
      onBlur,
      currency,
      onlyNumber = false,
      placeholder,
      type,
      readOnly = false,
      onFocus,
      ...attrs
    }: InputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const handleInputChange = useCallback(
      (callback?: EventHandler) => (event: ChangeEvent) => {
        const { target } = event
        // storybook has an addon that replaces the value of onlyNumber
        // so it is necessary to compare a boolean with true
        if (onlyNumber === true) {
          target.value = target.value.replace(/\D/g, '')
        }

        if (target.value !== value) {
          callback?.(event)
        }
      },
      [onlyNumber, value],
    )

    const handleCurrencyChange = useCallback(
      (callback?: EventHandler) => (event: ChangeEvent) => {
        const { target } = event
        if (target.value !== value) {
          target.value = currencyMask(target.value, currency)
          callback?.(event)
        }
      },
      [currency, value],
    )

    const onSelectInput = useCallback((event) => {
      const { target } = event
      const { value: valueInput } = target
      target.setSelectionRange(valueInput.length, valueInput.length)
    }, [])

    if (mask) {
      const isStringArray = !!(
        Array.isArray(mask) && mask.every((item) => typeof item === 'string')
      )

      return (
        <InputMask
          mask={isStringArray ? dynamicMask(mask as string[], (value || '').toString()) : mask}
          maskPlaceholder={maskPlaceholder}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleInputChange(onChange)}
          onInput={handleInputChange(onInput)}
          onFocus={onFocus}
          readOnly={readOnly}
        >
          <DefaultInput
            disabled={disabled}
            ref={ref}
            type={type}
            placeholder={placeholder}
            {...attrs}
          />
        </InputMask>
      )
    }

    if (type === 'currency')
      return (
        <DefaultInput
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          onSelect={onSelectInput}
          onChange={handleCurrencyChange(onChange)}
          onInput={handleCurrencyChange(onInput)}
          ref={ref}
          currency={currency}
          placeholder={placeholder}
          type="tel"
          readOnly={readOnly}
          {...attrs}
        />
      )

    return (
      <DefaultInput
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleInputChange(onChange)}
        onInput={handleInputChange(onInput)}
        ref={ref}
        placeholder={placeholder}
        type={type}
        readOnly={readOnly}
        {...attrs}
      />
    )
  },
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-input-since-v1-0-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3462&t=Hp2setzf2h2sKImc-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Input } from '@interco/inter-ui/components/Input'
 *
 * export const MyPage = () => {
 *   return (
 *     <Input
 *       id="infoText"
 *       label="E-mail"
 *       placeholder="Ex.: email@email.com"
 *       type="email"
 *     />
 *   )
 * }
 * ```
 */
export const Input = memo(InputWithMask)
