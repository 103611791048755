import React, { useCallback, useMemo, useState } from 'react'

import CloseIcon from '@interco/icons/build-v4/orangeds/MD/exit'
import SearchIcon from '@interco/icons/build-v4/orangeds/XL/search'

import { Component, ClearButton, Icon } from './styles'
import type { SearchInputProps } from './types'
import { Input } from '../Input'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-searchinput-since-3-4-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/FS12HnaqvY6B4K9mkTCSrr/DS-%2F-Components-%2F-Desktop?node-id=1295-27400&t=RabwOuj1svlNaWmz-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { SearchInput } from '@interco/inter-ui/components/SearchInput'
 *
 * const Page: React.FC = () => {
 *   const [query, setQuery] = useState('')
 *
 *   const handleInputChange = (e) => setQuery(e.target.value)
 *
 *   return (
 *     <SearchInput value={query} onChange={handleInputChange} />
 *   )
 * }
 * ```
 */
export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ disabled = false, isFullWidth, value, onFocus, onBlur, onClear, ...inputProps }, ref) => {
    const [isFocused, setIsFocused] = useState(false)

    const showClearButton = useMemo(
      () =>
        disabled
          ? false
          : (isFocused && !!value && String(value).length > 0 && !!onClear) ||
            (!!value && String(value).length > 0 && !!onClear),
      [isFocused, disabled, value, onClear],
    )

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(true)
      onFocus?.(event)
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(false)
      onBlur?.(event)
    }

    const handleClear = useCallback(() => {
      onClear?.()
    }, [onClear])

    const Clear = useMemo(
      () => () => {
        if (!showClearButton) return null

        return (
          <ClearButton role="button" onClick={handleClear}>
            <CloseIcon color="var(--primary500)" />
          </ClearButton>
        )
      },
      [showClearButton, handleClear],
    )

    const LeftIcon = useMemo<JSX.Element>(
      () => (
        <Icon role="img">
          <SearchIcon height={24} width={24} color="var(--primary500)" />
        </Icon>
      ),
      [],
    )

    return (
      <Component
        role="group"
        aria-label="Search input"
        aria-disabled={disabled}
        isDisabled={disabled}
        isFullWidth={isFullWidth}
      >
        <Input
          role="searchbox"
          ref={ref}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          iconLeft={LeftIcon}
          value={value}
          {...inputProps}
        />
        <Clear />
      </Component>
    )
  },
)
