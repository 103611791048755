import { combineReducers } from 'redux'

import { ContatoState } from './contato/types'
import contato from './contato'
import { ContratoAtualState } from './contratoAtual/types'
import contratoAtual from './contratoAtual'
import { DadosPessoaisState } from './dadosPessoais/types'
import dadosPessoais from './dadosPessoais'
import { SimulacaoState } from './simulacao/types'
import simulacao from './simulacao'
import { DadosProfissionaisState } from './dadosProfissionais/types'
import dadosProfissionais from './dadosProfissionais'
import { SituacaoFuncionalState } from './situacaoFuncional/types'
import situacaoFuncional from './situacaoFuncional'
import { ConsultaMarginQueryState } from './consultaMarginQuery/types'
import consultaMarginQuery from './consultaMarginQuery'
import { ConsultaMargemGeralState } from './consultaMargemGeral/types'
import consultaMargemGeral from './consultaMargemGeral'
import { PropostaState } from './proposta/types'
import proposta from './proposta'
import { DocumentosState } from './documentos/types'
import documentos from './documentos'
import { DocumentosAssinaturaState } from './documentosAssinatura/types'
import documentosAssinatura from './documentosAssinatura'
import { FotoDocumentosState } from './fotoDocumentos/types'
import { ContrachequeDocumentosState } from './contrachequeDocumentos/types'
import fotoDocumentos from './fotoDocumentos'
import contrachequeDocumentos from './contrachequeDocumentos'
import { EnderecoState } from './endereco/types'
import endereco from './endereco'
import { SimulacaoCompletaState } from './simulacaoCompleta/types'
import simulacaoCompleta from './simulacaoCompleta'
import { DadosBancariosState } from './dadosBancarios/types'
import dadosBancarios from './dadosBancarios'
import { ListaDocumentosState } from './listaDocumentos/types'
import listaDocumentos from './listaDocumentos'

export interface PortabilidadeState {
  contato: ContatoState
  contratoAtual: ContratoAtualState
  dadosPessoais: DadosPessoaisState
  simulacao: SimulacaoState
  simulacaoCompleta: SimulacaoCompletaState
  situacaoFuncional: SituacaoFuncionalState
  dadosProfissionais: DadosProfissionaisState
  consultaMarginQuery: ConsultaMarginQueryState
  consultaMargemGeral: ConsultaMargemGeralState
  proposta: PropostaState
  listaDocumentos: ListaDocumentosState
  documentos: DocumentosState
  documentosAssinatura: DocumentosAssinaturaState
  fotoDocumentos: FotoDocumentosState
  contrachequeDocumentos: ContrachequeDocumentosState
  endereco: EnderecoState
  dadosBancarios: DadosBancariosState
}

export default combineReducers({
  contato,
  contratoAtual,
  dadosPessoais,
  simulacao,
  simulacaoCompleta,
  situacaoFuncional,
  dadosProfissionais,
  consultaMarginQuery,
  consultaMargemGeral,
  proposta,
  documentos,
  documentosAssinatura,
  fotoDocumentos,
  contrachequeDocumentos,
  endereco,
  dadosBancarios,
  listaDocumentos,
})
