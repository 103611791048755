import { action, Action } from 'typesafe-actions'

import {
  SimulacaoCompletaTypes,
  SimulacaoCompletaRequestPayload,
  SimulacaoCompletaState,
  ContratacaoCompletaRequestPayload,
} from './types'

export const simulacaoCompletaRequest = (payload: SimulacaoCompletaRequestPayload): Action =>
  action(SimulacaoCompletaTypes.SIMULACAO_COMPLETA_REQUEST, payload)

export const simulacaoCompletaSuccess = (payload: SimulacaoCompletaState): Action =>
  action(SimulacaoCompletaTypes.SIMULACAO_COMPLETA_SUCCESS, payload)

export const simulacaoCompletaError = (): Action =>
  action(SimulacaoCompletaTypes.SIMULACAO_COMPLETA_ERROR)

export const contratacaoCompletaRequest = (payload: ContratacaoCompletaRequestPayload): Action =>
  action(SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_REQUEST, payload)

export const contratacaoCompletaSuccess = (payload: SimulacaoCompletaState): Action =>
  action(SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_SUCCESS, payload)

export const contratacaoCompletaError = (): Action =>
  action(SimulacaoCompletaTypes.CONTRATACAO_COMPLETA_ERROR)

export const setTipoPortabilidade = (payload: string): Action =>
  action(SimulacaoCompletaTypes.SET_TIPO_PORTABILIDADE, payload)

export const setSeguroRefinPortabilidade = (payload: boolean): Action =>
  action(SimulacaoCompletaTypes.SET_SEGURO_REFIN_PORTABILIDADE, payload)
