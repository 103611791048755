import { action, Action } from 'typesafe-actions'

import { StepPortabilidade, StepPortabilidadeNovo } from '@utils/enums'

import {
  SalvarLeadRequestPayload,
  SendPortabilityLeadPayload,
  SimulacaoPortabilidadeRequestPayload,
  SimulacaoRequestPayload,
  SimulacaoResponse,
  SimulacaoTypes,
  SimulateDataResponse,
} from './types'

export const simulacaoRequest = (payload: SimulacaoRequestPayload): Action =>
  action(SimulacaoTypes.SIMULACAO_REQUEST, payload)

export const simulacaoSucces = (payload: SimulacaoResponse): Action =>
  action(SimulacaoTypes.SIMULACAO_SUCCESS, payload)

export const simulacaoError = (): Action => action(SimulacaoTypes.SIMULACAO_ERROR)

export const clearSimulacao = (): Action => action(SimulacaoTypes.CLEAR_SIMULACAO)

export const salvarLeadRequest = (payload: SalvarLeadRequestPayload): Action =>
  action(SimulacaoTypes.SALVAR_LEAD_REQUEST, payload)

export const salvarLeadSuccess = (): Action => action(SimulacaoTypes.SALVAR_LEAD_SUCCESS)

export const salvarLeadError = (): Action => action(SimulacaoTypes.SALVAR_LEAD_ERROR)

export const trackingRequest = (payload: StepPortabilidade | StepPortabilidadeNovo): Action =>
  action(SimulacaoTypes.TRACKING_REQUEST, payload)

export const simulacaoPortabilidadeRequest = (
  payload: SimulacaoPortabilidadeRequestPayload,
): Action => action(SimulacaoTypes.SIMULACAO_REQUEST_PORTABILITY, payload)

export const simulacaoPortabilidadeSuccess = (payload: SimulateDataResponse): Action =>
  action(SimulacaoTypes.SIMULACAO_SUCCESS_PORTABILITY, payload)

export const simulacaoPortabilidadeError = (): Action =>
  action(SimulacaoTypes.SIMULACAO_ERROR_PORTABILITY)

export const setDadosSimulacaoAlterados = (payload: boolean): Action =>
  action(SimulacaoTypes.SET_DADOS_SIMULACAO_ALTERADOS, payload)
export const sendPortabilityLeadRequest = (payload: SendPortabilityLeadPayload): Action =>
  action(SimulacaoTypes.SEND_PORTABILITY_LEAD_REQUEST, payload)

export const sendPortabilityLeadSuccess = (): Action =>
  action(SimulacaoTypes.SEND_PORTABILITY_LEAD_SUCCESS)

export const sendPortabilityLeadError = (): Action =>
  action(SimulacaoTypes.SEND_PORTABILITY_LEAD_ERROR)
