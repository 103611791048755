import React from 'react'

import { useSelector } from 'react-redux'

import { PagePdf, TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'

const Portabilidade = () => {
  const variant = 'web'
  const { dadosProposta } = useSelector((state: ApplicationState) => state.portabilidade.proposta)

  return (
    <PagePdf variant={variant}>
      <TemplatesPDF.PagePortabilidade
        variant={variant}
        banco={variant}
        emitente={{
          cpf: dadosProposta.cpf,
          nome: dadosProposta.nomeCliente,
          dtNascimento: dadosProposta.dataNascimento
            ? new Date(dadosProposta.dataNascimento)
            : new Date(),
          matricula: dadosProposta.matricula,
          docIdentificacao: dadosProposta.documentoIdentificacao,
          telefone: `${dadosProposta.dddCelular} ${dadosProposta.telefoneCelular}`,
          endereco: `${dadosProposta?.enderecoResidencial}, ${dadosProposta?.numeroResidencial} - ${dadosProposta?.bairroResidencial} - ${dadosProposta?.cidadeResidencial}/${dadosProposta?.estadoResidencial}`,
        }}
        dadosEmprestimo={{
          instituicao: dadosProposta.bancoPortadoDescricao,
          numContrato: dadosProposta.codigoContratoOriginalPortabilidade,
        }}
        assinatura={{
          isDgAssinado: true,
          cidade: 'Belo Horizonte',
          data: new Date(),
        }}
        declaracaoConcordancia={{
          isDgAssinado: true,
          numeroContrato: dadosProposta.codigoContratoOriginalPortabilidade,
          bancoCredor: dadosProposta.bancoPortadoDescricao,
        }}
        footer={{
          atualizado: new Date('2022-11-02'),
          codigo: 'CP010',
          edicao: '11ª',
          divulgacao: 'Pública',
          contatos: [
            'Central de Atendimento 3003-4070 (Capitais e Regiões Metropolitanas)',
            'Demais Localidades: 0800 9400007',
            'SAC Cartão Inter 08009409999 www.bancointer.com.br',
            'Ouvidoria 08009407772 Deficiente Fala e Audição 0800 979 7099',
          ],
        }}
      />
    </PagePdf>
  )
}
export default Portabilidade
