import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { HttpStatus } from '@services/base'
import { ErroType } from '@utils/types'

import { finalizarPropostaErro, finalizarPropostaSuccess } from '../actions'

export default function* postFinalizarPropostaSaga({ payload }: AnyAction) {
  const { numeroProposta } = yield* select(
    (state: ApplicationState) => state.portabilidade.proposta,
  )
  const { cpf } = yield* select((state: ApplicationState) => state.portabilidade.dadosPessoais)
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  const { email, telefone } = yield* select(
    (state: ApplicationState) => state.portabilidade.contato,
  )
  try {
    yield services.postPropostaFinalizar(
      {
        codigoProposta: String(numeroProposta),
        cpf,
        email,
        telefone,
        produto: 'Portabilidade',
      },
      accessToken,
    )
    yield put(finalizarPropostaSuccess())
    yield payload.callback()
  } catch (error) {
    yield put(finalizarPropostaErro())
    const erroTyped = error as ErroType
    if (erroTyped.status === HttpStatus.NOT_FOUND) {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro ao validar o envio da biometria, por favor tente novamente.',
          functionCallback: () => payload.callbackTryAgain(),
          actionTitle: 'Refazer biometria',
          block: true,
        }),
      )
    }
  }
}
