import { Reducer } from 'redux'

import { EnderecoState, EnderecoTypes } from './types'

const INITIAL_STATE: EnderecoState = {
  endereco: [],
  enderecoError: false,
  enderecoLoading: false,

  cep: '',
  tipoLogradouro: 'Rua',
  logradouro: '',
  bairro: '',
  numero: '',
  complemento: '',
  estadoSelecionado: '',
  cidadeSelecionada: '',

  estados: [],
  estadosError: false,
  estadosLoading: false,

  cidades: [],
  cidadesError: false,
  cidadesLoading: false,
}

const reducer: Reducer<EnderecoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EnderecoTypes.ENDERECO_SUCCESS:
      return {
        ...state,
        enderecoError: false,
        enderecoLoading: false,
        endereco: action.payload,
      }
    case EnderecoTypes.ENDERECO_REQUEST:
      return { ...state, enderecoError: false, enderecoLoading: true }

    case EnderecoTypes.ENDERECO_LOADING:
      return { ...state, enderecoError: false, enderecoLoading: true }
    case EnderecoTypes.ENDERECO_ERROR:
      return { ...state, enderecoLoading: false, enderecoError: true }

    case EnderecoTypes.SET_CEP:
      return { ...state, cep: action.payload }
    case EnderecoTypes.SET_TIPO_LOGRADOURO:
      return { ...state, tipoLogradouro: action.payload }
    case EnderecoTypes.SET_LOGRADOURO:
      return { ...state, logradouro: action.payload }
    case EnderecoTypes.SET_BAIRRO:
      return { ...state, bairro: action.payload }
    case EnderecoTypes.SET_NUMERO:
      return { ...state, numero: action.payload }
    case EnderecoTypes.SET_COMPLEMENTO:
      return { ...state, complemento: action.payload }
    case EnderecoTypes.SET_ESTADO_SELECIONADO:
      return { ...state, estadoSelecionado: action.payload }
    case EnderecoTypes.SET_CIDADE_SELECIONADA:
      return { ...state, cidadeSelecionada: action.payload }
    case EnderecoTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
