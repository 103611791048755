import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { BancoApi } from '@store/globalApi/types'

import { Banco } from '../types'
import { bancosError, bancosLoading, bancosRequest, bancosSuccess } from '../actions'

const bancosBuild = (data: BancoApi): Banco[] =>
  data &&
  data?.bancos.map(({ nome, codigo, ispb }) => ({
    nome,
    codigo,
    ispb,
  }))

export default function* getBancos() {
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    yield put(bancosLoading())
    const response = yield* call(services.getBancos, accessToken)
    yield put(bancosSuccess(bancosBuild(response.data)))
  } catch (error) {
    yield put(bancosError())
    yield put(
      showError({
        title: 'Erro ao buscar os bancos',
        message: 'Ocorreu um erro na listagem dos bancos.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => bancosRequest(),
      }),
    )
  }
}
