import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'
import { Banco, Cidade, Convenio, Estado, Orgao, Upag } from '@store/globalApi/types'
import { Banco as BancoNovo } from '@store/portabilidade/dadosBancarios/types'

export const transformBancoToOption = (
  bancos: Banco[] | BancoNovo[],
  bancoSelecionado?: string | number,
) => {
  let optionBancoSelecionado = {
    value: 0,
    text: '',
  }

  bancos.map((banco) => {
    if (banco.codigo === bancoSelecionado || banco.nome === bancoSelecionado) {
      optionBancoSelecionado = {
        value: Number(banco.codigo),
        text: banco.nome,
      }
    }
    return optionBancoSelecionado
  })
  return optionBancoSelecionado
}

export const transformConvenioToOption = (
  convenios: Convenio[] | undefined,
  convenioSelecionado: number,
) => {
  let optionConvenioSelecionado = {
    value: 0,
    text: '',
  }

  convenios &&
    convenios.map((convenio) => {
      if (convenio.codigo === convenioSelecionado) {
        optionConvenioSelecionado = {
          value: convenio.codigo,
          text: convenio.descricao,
        }
      }
      return optionConvenioSelecionado
    })

  return optionConvenioSelecionado
}

export const transformCidadeToOption = (
  cidades: Cidade[] | undefined,
  cidadeSelecionada: string,
) => {
  let optionCidadeSelecionada = {
    value: '',
    text: '',
  }

  cidades &&
    cidades.map((cidade) => {
      if (cidade.nome === cidadeSelecionada.toUpperCase()) {
        optionCidadeSelecionada = {
          value: cidade.codigo,
          text: cidade.nome,
        }
      }
      return optionCidadeSelecionada
    })
  return optionCidadeSelecionada
}

export const transformEstadoToOption = (
  estados: Estado[] | undefined,
  estadoSelecionado: string,
) => {
  let optionEstadoSelecionado = {
    value: '',
    text: '',
  }

  estados &&
    estados.map((cidade) => {
      if (cidade.uf === estadoSelecionado) {
        optionEstadoSelecionado = {
          value: cidade.uf,
          text: cidade.nome,
        }
      }
      return optionEstadoSelecionado
    })

  return optionEstadoSelecionado
}

export const transformOrgaoToOption = (orgaos: Orgao[] | undefined, orgaoSelecionado: number) => {
  let optionOrgaoSelecionado: { value: string | number; text: string } = {
    value: '',
    text: '',
  }

  orgaos &&
    orgaos.map((orgao) => {
      if (orgao.codigo === orgaoSelecionado) {
        optionOrgaoSelecionado = {
          value: orgao.codigo,
          text: orgao.descricao,
        }
      }
      return optionOrgaoSelecionado
    })

  return optionOrgaoSelecionado
}

export const transformUpagToOption = (upags: Upag[], upagSelecionado: string | undefined) => {
  let optionUpagSelecionada = {
    value: '',
    text: '',
  }

  upags.map((upag) => {
    if (`${upag.sigla} - ${upag.nome} - ${upag.codigoUpag}` === upagSelecionado) {
      optionUpagSelecionada = {
        value: `${upag.sigla} - ${upag.nome} - ${upag.codigoUpag}`,
        text: `${upag.sigla} - ${upag.nome}`,
      }
    }
    return optionUpagSelecionada
  })

  return optionUpagSelecionada
}

export const transformSituacaoToOption = (
  situacoesFuncionais: Option[],
  situacaoSelecionada: string,
) => {
  let optionSituacaoSelecionado: { value: string | number; text: string } = {
    value: '',
    text: '',
  }

  situacoesFuncionais.map((situacao) => {
    if (String(situacao.value) === situacaoSelecionada) {
      optionSituacaoSelecionado = {
        value: situacao.value,
        text: situacao.text,
      }
    }
    return optionSituacaoSelecionado
  })

  return optionSituacaoSelecionado
}

export const transformPatenteToOption = (
  patentes: {
    value: string | number
    text: string
  }[],
  patenteSelecionado: string,
) => {
  let optionPatenteSelecionado: { value: string | number; text: string } = {
    value: '',
    text: '',
  }

  patentes.map((patente) => {
    if (String(patente.value) === patenteSelecionado) {
      optionPatenteSelecionado = {
        value: patente.value,
        text: patente.text,
      }
    }
    return optionPatenteSelecionado
  })

  return optionPatenteSelecionado
}

export const transformMotivoToOption = (motivo: string) => ({
  value: motivo,
  text: motivo,
})

export const buildCidadesNovo = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({
    value: cidade.nome,
    text: cidade.nome,
  }))

export const buildEstadosNovo = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, text: uf }))

export const buildBancosNovo = (bancos: BancoNovo[]) =>
  bancos?.map(({ nome, codigo }) => ({ value: codigo, text: nome }))

export const TiposContaNovo = [
  {
    value: '1',
    text: 'Conta Corrente',
  },
  {
    value: '2',
    text: 'Conta Poupança',
  },
]

export const transformTipoContaToOption = (
  lista: { value: string; text: string }[],
  tipoContaSelecionado: string,
) => {
  const tipoContaSelecionadoNovo = lista.find((item) => item.value === tipoContaSelecionado)

  if (tipoContaSelecionadoNovo) {
    const optionSelecionada = {
      value: tipoContaSelecionadoNovo.value,
      text: tipoContaSelecionadoNovo.text,
    }

    return optionSelecionada
  }

  return { value: '', text: '' }
}
