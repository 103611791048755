import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Colors } from '@utils/colors'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { Separator } from '@atoms'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { StepPortabilidadeNovo } from '@utils/enums'
import { ApplicationState } from '@store/types'
import IcInfoCircle from '@interco/icons/core/status/ic_info_circle'
import routes from '@routes/routes'
import Tags from '@utils/Tags'
import { formatCurrency } from '@utils/masks'
import { Alert } from '@interco/inter-ui/components/Alert'
import { BridgeService } from '@services/bridge'

import { Box, BoxLine, BoxTitle, BoxWithoutChange } from './styles'

const ResultadoSimulacao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { refinancing, portability } = useSelector(
    (state: ApplicationState) => state.portabilidade.simulacao,
  )
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'resultado_simulacao',
      actions: ['simular_portabilidade'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: `Resultado - ${refinancing ? 'com troco' : 'sem troco'}`,
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: refinancing
        ? 'Os valores são aproximados, caem na sua conta e podem variar por convênio.'
        : 'Os valores são aproximados e podem variar por convênio.',
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [nomeConvenioSelecionado, refinancing])

  return (
    <NovoPage
      id="dados-basicos"
      stickyContainerFooterBottom="12px"
      positionFooter="sticky"
      footerTwoButtonsSpace="210px"
      titleHeader="Simulação"
      stickyFooter={
        <div
          style={{
            borderTop: `1px solid ${Colors.GRAY200}`,
            margin: '0 -24px',
            padding: '16px 24px',
          }}
        >
          <Button
            fullWidth
            style={{ marginTop: '8px' }}
            onClick={() => {
              dispatch(trackingRequest(StepPortabilidadeNovo.RESULTADO_SIMULACAO))
              BridgeService.tic({
                name: 'btn_continuar',
                action: 'trazer_contrato_resultado',
              })
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: `Resultado - ${refinancing ? 'com troco' : 'sem troco'}`,
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Continuar e trazer contrato',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              Tags.enviarTagueamentoWeb({
                element_name: 'Continuar e trazer contrato',
                section_name: 'Resultado da simulacao',
                element_action: 'click button',
                c_page: window.location.href,
                redirect_url: `${window.location.origin}${routes.ENTRAREMOS_CONTATO_V2}`,
                step: 'null',
              })
              if (BridgeService.isBrowser()) {
                // navigate(routes.ENTRAREMOS_CONTATO_V2)
                navigate(routes.DICAS_CONTRATO_V2)
              } else {
                navigate(routes.DICAS_CONTRATO_V2)
              }
            }}
          >
            Continuar e trazer contrato
          </Button>
          <Button
            variant="secondary"
            fullWidth
            style={{ marginTop: '16px' }}
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: `Resultado - ${refinancing ? 'com troco' : 'sem troco'}`,
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Simular novamente',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              navigate(routes.SIMULADOR_PORTABILIDADE)
            }}
          >
            Simular novamente
          </Button>
        </div>
      }
    >
      <Box>
        <BoxTitle>
          <Tag size="small" theme="yellow" intensity="light">
            Refinanciamento
          </Tag>
          <Text variant="headline-h2" semiBold colorWeight={500}>
            Contrato com troco
          </Text>
        </BoxTitle>
        {refinancing ? (
          <>
            <BoxLine>
              <Text variant="body-3">Troco</Text>
              <Text variant="body-3" bold colorWeight={500} style={{ color: Colors.SUCCESS500 }}>
                {formatCurrency(refinancing?.availableAmount)}
              </Text>
            </BoxLine>
            <BoxLine>
              <Text variant="body-3">Parcelas</Text>
              <Text variant="body-3" bold colorWeight={500}>
                {refinancing?.numberOfInstallments}x de{' '}
                {formatCurrency(refinancing?.installmentAmount)}
              </Text>
            </BoxLine>
            <BoxLine>
              <Text variant="body-3">Novo saldo devedor</Text>
              <Text variant="body-3" bold colorWeight={500}>
                {formatCurrency(refinancing?.requestedAmount)}
              </Text>
            </BoxLine>
          </>
        ) : (
          <BoxWithoutChange>
            <IcInfoCircle style={{ width: '100%' }} height={30} width={30} color={Colors.GRAY400} />
            <Text variant="body-3">
              No momento, não foi possível oferecer troco devido à margem total utilizada ou às
              políticas internas de crédito.
            </Text>
          </BoxWithoutChange>
        )}
      </Box>
      <Separator
        variant="small"
        height="1px"
        color={Colors.GRAY200}
        style={{ marginTop: '32px', marginBottom: '32px' }}
      />
      <Box>
        <BoxTitle>
          <Tag size="small" theme="yellow" intensity="light">
            Portabilidade
          </Tag>
          <Text variant="headline-h2" semiBold colorWeight={500}>
            Contrato sem troco
          </Text>
        </BoxTitle>
        <BoxLine>
          <Text variant="body-3">Parcelas</Text>
          <Text variant="body-3" bold colorWeight={500}>
            {portability?.numberOfInstallments}x de {formatCurrency(portability?.installmentAmount)}
          </Text>
        </BoxLine>
        <BoxLine>
          <Text variant="body-3">Saldo devedor</Text>
          <Text variant="body-3" bold colorWeight={500}>
            {formatCurrency(portability?.requestedAmount)}
          </Text>
        </BoxLine>
      </Box>
      <div style={{ marginTop: 'auto' }}>
        <Alert type="warning">
          {refinancing
            ? 'Os valores são aproximados, caem na sua conta e podem variar por convênio.'
            : 'Os valores são aproximados e podem variar por convênio.'}
        </Alert>
      </div>
    </NovoPage>
  )
}
export default ResultadoSimulacao
