import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { setDocumentos, setIdIteracao } from '@store/portabilidade/documentosAssinatura/actions'

import { hideLoading, postDocumentosAssinatura, showLoading } from '../actions'

interface Props {
  noRemoveLoader: boolean
  actionCallbackError: {
    message: string
    actionCallback: () => AnyAction
    actionTitle: string
  }
  type: string
}

export default function* postDocumentosAssinaturaSaga({
  noRemoveLoader,
  actionCallbackError = {
    message: 'Aguarde um instante e tente novamente.',
    actionCallback: () => postDocumentosAssinatura(),
    actionTitle: 'Tentar Novamente',
  },
}: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  try {
    let docs = []
    yield put(showLoading())
    const { data: dataDocumentos, status } = yield services.postDocumentosAssinatura(
      {
        cpf: appState.portabilidade.dadosPessoais.cpf,
        numeroProposta: String(appState.portabilidade.proposta.numeroProposta),
      },
      appState.session.accessToken,
    )

    if (dataDocumentos) {
      const docsEnviados = dataDocumentos.enviados.map((doc: Record<string, unknown>) => ({
        ...doc,
        isDone: true,
      }))
      docs = docsEnviados.concat(dataDocumentos?.pendentes)
      yield put(setDocumentos(docs))
      yield put(setIdIteracao(dataDocumentos?.idInteracao))
    }
    if (!noRemoveLoader) {
      yield put(hideLoading())
    }
    if (dataDocumentos && status === 200) return true
    yield put(showError(actionCallbackError))
    return false
  } catch (error) {
    yield put(hideLoading())
    yield put(showError(actionCallbackError))
    return false
  }
}
