import { call, put, select } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import postBiometriaDados, { IBiometriaSave } from '@services/postBiometriaDados'

import { postBiometriaDadoSave, setBiometricToken, setLoadingLiveness } from '../actions'

interface Props {
  payload: {
    callback: (biometricToken: string) => void
  }
  type: string
}

export default function* postBiometriaDadosSaga({ payload }: Props) {
  const appState = yield* select((state: ApplicationState) => state)
  const numeroProposta = String(appState.portabilidade.proposta.numeroProposta)
  const { accessToken } = yield* select((s: ApplicationState) => s.session)
  const body: IBiometriaSave = {
    proposalCode: numeroProposta ? String(numeroProposta) : '',
    isFullCaptureFlow: true,
  }
  yield put(setLoadingLiveness(true))
  try {
    const { data } = yield* call(postBiometriaDados, body, accessToken)

    yield put(setBiometricToken(data.biometricToken))
    yield put(setLoadingLiveness(false))
    yield payload.callback(data.biometricToken)
  } catch (error) {
    yield put(setLoadingLiveness(false))
    yield put(
      showError({
        title: 'Ocorreu um erro ao carregar dados para biometria',
        message: 'Por favor tente novamente.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => postBiometriaDadoSave(payload),
        block: true,
      }),
    )
  }
}
