import { call, put, select } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import getBiometricsValidate from '@services/getBiometricsValidate'

import { getValidateBiometrics, setLoadingBimetricsValidate } from '../actions'

interface Props {
  payload: {
    callback: (isValid: boolean) => void
  }
  type: string
}

export default function* postBiometriaDadosSaga({ payload }: Props) {
  const { biometricToken } = yield* select((s: ApplicationState) => s.biometria)
  const { accessToken } = yield* select((s: ApplicationState) => s.session)
  yield put(setLoadingBimetricsValidate(true))
  try {
    const { data } = yield* call(getBiometricsValidate, biometricToken, accessToken)
    yield put(setLoadingBimetricsValidate(false))
    yield payload.callback(data.isValid)
  } catch (error) {
    yield put(setLoadingBimetricsValidate(false))
    yield put(
      showError({
        title: 'Ocorreu um erro ao validar biometria',
        message: 'Por favor tente novamente.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => getValidateBiometrics(payload),
        block: true,
      }),
    )
  }
}
