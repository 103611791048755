import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div:first-of-type {
    margin-bottom: 12px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
