import React, { useMemo, useRef } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { Loading } from '@atoms'
import { Header } from '@atoms/Header'

import { PageVisualizarPdf, PdfGrid } from './styles'
import Autorizacao from './Autorizacao'
import Ccb from './CCB'
import DeclaracaoResidencia from './DeclaracaoResidencia'
import SeguroPrestamista from './SeguroPrestamista'
import SeguroPrestamistaInss from './SeguroPrestamistaInss'
import CCBExercito from './CCBExercito'
import PublicoVulneravel from './PublicoVulneravel'
import ADF from './ADF'
import Portabilidade from './Portabilidade'
import Refinanciamento from './Refinanciamento'
import CapaCCB from './CapaCCB'
import PortabilidadeNovo from './PortabilidadeNovo'
import SeguroPrestamistaRefin from './SeguroPrestamistaRefin'

const VisualizadorTermo = () => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const ref = useRef<HTMLDivElement>(null)

  const PdfAssinatura = useMemo(
    () => ({
      AUTORIZACAO_CONSULTA_MARGEM: <Autorizacao />,
      CCB: <Ccb />,
      DECLARACAO_RESIDENCIA: <DeclaracaoResidencia />,
      SEGURO_PRESTAMISTA: <SeguroPrestamista />,
      SEGURO_PRESTAMISTA_INSS: <SeguroPrestamistaInss />,
      CCB_EXERCITO: <CCBExercito />,
      ADF: <ADF />,
      TERMO_PORTABILIDADE: <Portabilidade />,
      TERMO_REFINANCIAMENTO: <Refinanciamento />,
      PUBLICO_VULNERAVEL: <PublicoVulneravel />,
      CCB_CAPA: <CapaCCB />,
      SEGURO_PRESTAMISTA_REFINANCIAMENTO: <SeguroPrestamistaRefin />,
      TERMO_SOLICITACAO_PORTABILIDADE: <PortabilidadeNovo />,
    }),
    [],
  )

  const { loading, documentoRevisao, tituloDocumentoRevisao } = useSelector(
    (state: ApplicationState) => state.portabilidade.documentosAssinatura,
  )
  const isPublicoVulneravel = documentoRevisao === 'PUBLICO_VULNERAVEL'
  const isAdf = documentoRevisao === 'ADF'

  if (loading) return <Loading height="150px" />

  return (
    <>
      {isApp ? <Header showLeftIcon>{tituloDocumentoRevisao}</Header> : <></>}
      <PageVisualizarPdf id="visualizar-documentos-assinatura">
        <PdfGrid ref={ref} isCartilha={isPublicoVulneravel} isAdf={isAdf} isWeb={!isApp}>
          {PdfAssinatura[documentoRevisao]}
        </PdfGrid>
      </PageVisualizarPdf>
    </>
  )
}
export default VisualizadorTermo
