/**
 * Actions Types
 */

export enum SimulacaoTypes {
  SIMULACAO_REQUEST = '@simulacao/SIMULACAO_REQUEST',
  SIMULACAO_SUCCESS = '@simulacao/SIMULACAO_SUCCESS',
  SIMULACAO_ERROR = '@simulacao/SIMULACAO_ERROR',
  SALVAR_LEAD_REQUEST = '@simulacao/SALVAR_LEAD_REQUEST',
  SALVAR_LEAD_SUCCESS = '@simulacao/SALVAR_LEAD_SUCCESS',
  SALVAR_LEAD_ERROR = '@simulacao/SALVAR_LEAD_ERROR',
  TRACKING_REQUEST = '@simulacao/TRACKING_REQUEST',
  CLEAR_SIMULACAO = '@simulacao/CLEAR_SIMULACAO',

  SIMULACAO_REQUEST_PORTABILITY = '@simulacao/SIMULACAO_REQUEST_PORTABILITY',
  SIMULACAO_SUCCESS_PORTABILITY = '@simulacao/SIMULACAO_SUCCESS_PORTABILITY',
  SIMULACAO_ERROR_PORTABILITY = '@simulacao/SIMULACAO_ERROR_PORTABILITY',

  SET_DADOS_SIMULACAO_ALTERADOS = '@simulacao/SET_DADOS_SIMULACAO_ALTERADOS',

  SEND_PORTABILITY_LEAD_REQUEST = '@simulacao/SEND_PORTABILITY_LEAD_REQUEST',
  SEND_PORTABILITY_LEAD_SUCCESS = '@simulacao/SEND_PORTABILITY_LEAD_SUCCESS',
  SEND_PORTABILITY_LEAD_ERROR = '@simulacao/SEND_PORTABILITY_LEAD_ERROR',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface SimulacaoState {
  readonly valorParcela: number
  readonly novoValorParcela: number
  readonly valorEconomia: number
  readonly validar: boolean
  readonly taxaNominalMensal: number
  readonly novoTaxaNominalMensal: number
  readonly valorSolicitado: number
  readonly valorFinanciado: number
  readonly leadEnviado: boolean
  readonly loading?: boolean
  readonly error?: boolean
  readonly saldoDevedor: number
  readonly refinancing?: RefinI
  readonly portability?: PortI
  readonly errorSimulacaoPort: boolean
  readonly loadingSimulacaoPort: boolean
  readonly dadosSimulacaoAlterados: boolean
  readonly errorSendPortabilityLead: boolean
  readonly loadingSendPortabilityLead: boolean
}

/**
 * Actions Payload Types
 */
export interface CallbackSimulacaoType {
  valida: boolean
  naoLiberado: boolean
}
export type SimulacaoRequestPayload = {
  callback: (params: CallbackSimulacaoType) => void
}
export type SalvarLeadRequestPayload = {
  callback: () => void
}

export type SimulacaoPortabilidadeRequestPayload = {
  debtBalance: number
  installmentValue: number
  callback: (erroCode: string) => void
}

export type SendPortabilityLeadPayload = {
  callback: (erroCode?: string) => void
}

export interface InfoParcelas {
  qtdeParcelas: number
  valorSolicitado: number
  valorSolicitadoPercentual: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  percentualIOF: number
  valorFinanciado: number
  cETAm: number
  cETAe: number
  taxaNominalAm: number
  taxaNominalAa: number
  jurosContratados: number
  jurosContratadosPercentual: number
}

export interface SimulacaoResponse {
  simulacoes: InfoParcelas[]
}

export interface RefinI {
  availableAmount: number
  numberOfInstallments: number
  installmentAmount: number
  requestedAmount: number
}

export interface PortI {
  numberOfInstallments: number
  installmentAmount: number
  requestedAmount: number
}

export interface SimulateDataResponse {
  refinancing: RefinI | null
  portability: PortI | null
  errorCode: string | null
}
