import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import {
  sendPortabilityLeadError,
  sendPortabilityLeadSuccess,
} from '@store/portabilidade/simulacao/actions'
import { ConveniosCodes, ErrorCodesAPI } from '@utils/enums'
import { ApplicationState } from '@store/types'
import postSendPortabilityLead from '@services/postSendPortabilityLead'

import { SendPortabilityLeadPayload } from '../types'

export default function* postSendPortabilityLeadSaga(action: AnyAction) {
  const payload = action.payload as SendPortabilityLeadPayload
  const { convenioSelecionado } = yield* select(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    const convenioUrl = convenioSelecionado === ConveniosCodes.INSS ? 'inss' : 'siape'
    yield call(postSendPortabilityLead, convenioUrl, accessToken)
    yield put(sendPortabilityLeadSuccess())
    yield payload.callback()
  } catch (error) {
    yield put(sendPortabilityLeadError())
    yield payload.callback(ErrorCodesAPI.R0003)
  }
}
