/**
 * Actions Types
 */

export enum ListaDocumentosTypes {
  SET_LISTA = '@portabilidade/listaDocumenntos/SET_LISTA',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ListaDocumentosState {
  readonly CCB: DocumentoPattern
  readonly DECLARACAO_RESIDENCIA: DocumentoPattern
  readonly AUTORIZACAO_CONSULTA_MARGEM: DocumentoPattern
  readonly SEGURO_PRESTAMISTA: DocumentoPattern
  readonly SEGURO_PRESTAMISTA_INSS: DocumentoPattern
  readonly CCB_EXERCITO: DocumentoPattern
  readonly PUBLICO_VULNERAVEL: DocumentoPattern
  readonly ADF: DocumentoPattern
  readonly TERMO_PORTABILIDADE: DocumentoPattern
  readonly TERMO_REFINANCIAMENTO: DocumentoPattern
  readonly CCB_CAPA: DocumentoPattern
  readonly TERMO_SOLICITACAO_PORTABILIDADE: DocumentoPattern
  readonly SEGURO_PRESTAMISTA_REFINANCIAMENTO: DocumentoPattern
}
export interface DocumentoPattern {
  text: string
  checkMessage: string
  description: string
  pdf: unknown
  isLoading: boolean
  isDone: boolean
  isError: boolean
}

export type TipoDocs =
  | 'CCB'
  | 'DECLARACAO_RESIDENCIA'
  | 'AUTORIZACAO_CONSULTA_MARGEM'
  | 'SEGURO_PRESTAMISTA'
  | 'SEGURO_PRESTAMISTA_INSS'
  | 'CCB_EXERCITO'
  | 'PUBLICO_VULNERAVEL'
  | 'ADF'
  | 'TERMO_PORTABILIDADE'
  | 'TERMO_REFINANCIAMENTO'
  | 'CCB_CAPA'
  | 'TERMO_SOLICITACAO_PORTABILIDADE'
  | 'SEGURO_PRESTAMISTA_REFINANCIAMENTO'

export interface TipoDocsPayload {
  tipo: TipoDocs
  email?: string
  telefone?: string
}
