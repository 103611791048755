import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import getConvenioByToken from '@services/getConvenioByToken'
import {
  setConvenioSelecionado,
  setNomeConvenioSelecionado,
} from '@store/portabilidade/dadosProfissionais/actions'

import { ConvenioTokenPayload } from '../types'
import { convenioByTokenRequest } from '../actions'

export default function* getConvenioByTokenSaga(action: AnyAction) {
  const payload = action.payload as ConvenioTokenPayload
  try {
    const { accessToken } = yield* select((state: ApplicationState) => state.session)
    const { data } = yield* call(getConvenioByToken, String(accessToken))
    yield put(setConvenioSelecionado(data.covenant))
    yield put(setNomeConvenioSelecionado(data.covenantName))
    yield payload.callback(data.covenant)
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao consultar dados',
        message: 'Ocorreu um erro ao consultar os dados do convênio',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => convenioByTokenRequest(payload),
        block: true,
      }),
    )
  }
}
