/**
 * Actions Types
 */

import { TipoDocs } from '../listaDocumentos/types'

export enum DocumentosAssinaturaTypes {
  SET_DOCUMENTOS = '@portabilidade/documentosAssinatura/SET_DOCUMENTOS',
  SET_ID_ITERACAO = '@portabilidade/documentosAssinatura/SET_ID_ITERACAO',
  SET_PROPOSTA_ATUALIZADA = '@portabilidade/documentosAssinatura/SET_PROPOSTA_ATUALIZADA',
  SET_DOCUMENTOS_GERADOS = '@portabilidade/documentosAssinatura/SET_DOCUMENTOS_GERADOS',
  SET_DOCUMENTO_REVISAO = '@portabilidade/documentosAssinatura/SET_DOCUMENTO_REVISAO',
  SET_CHECK_MESSAGE_REVISAO = '@portabilidade/documentosAssinatura/SET_CHECK_MESSAGE_REVISAO',
  SET_TITLE_DOCUMENTO_REVISAO = '@portabilidade/documentosAssinatura/SET_TITLE_DOCUMENTO_REVISAO',
  RESET = 'RESET',
  SET_LOADING = '@portabilidade/documentosAssinatura/SET_LOADING',

  ATUALIZAR_PROPOSTA = '@portabilidade/documentosAssinatura/ATUALIZAR_PROPOSTA',
  GERAR_DOCUMENTO_MERGE_SAGA = '@portabilidade/documentosAssinatura/GERAR_DOCUMENTO_MERGE_SAGA',
  FINALIZAR_PROPOSTA = '@portabilidade/documentosAssinatura/FINALIZAR_PROPOSTA',
  FINALIZAR_PROPOSTA_ERROR = '@portabilidade/documentosAssinatura/FINALIZAR_PROPOSTA_ERROR',
  FINALIZAR_PROPOSTA_SUCCESS = '@portabilidade/documentosAssinatura/FINALIZAR_PROPOSTA_SUCCESS',
  BUSCAR_DOCUMENTOS_ASSINATURA_REQUEST = '@portabilidade/documentosAssinatura/BUSCAR_DOCUMENTOS_ASSINATURA_REQUEST',
}

/**
 * State Type
 */

export interface DocumentosAssinaturaState {
  readonly documentos: {
    idTipoDocumento: TipoDocs
  }[]
  readonly propostaAtualizada: boolean
  readonly documentosGerados: boolean
  readonly idIteracao: number | string
  readonly documentoRevisao: TipoDocs
  readonly checkMessageRevisao: string
  readonly tituloDocumentoRevisao: string
  readonly loading: boolean
  readonly loadingFinalizarProposta: boolean
  readonly erroFinalizarProposta: boolean
  readonly propostaFinalizada: boolean
}

export type FinalizarPropostaPayload = {
  callback: () => void
  callbackTryAgain?: () => void
}

export interface BuscarDocumentosAssinaturaPayload {
  callback: () => void
}
