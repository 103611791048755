import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'

interface Props {
  setOpenBottomsheetErroBeneficio: React.Dispatch<React.SetStateAction<boolean>>
}
const BottomsheetErroBeneficio = ({ setOpenBottomsheetErroBeneficio }: Props) => (
  <BottomSheet onClose={() => setOpenBottomsheetErroBeneficio(false)}>
    <Text
      variant="headline-h3"
      color="typography"
      colorWeight={500}
      semiBold
      style={{
        margin: '0 0 16px 0',
      }}
    >
      Erro na seleção do benefício
    </Text>
    <Text variant="body-3">
      No momento, só é possível adicionar contratos do mesmo convênio e benefício. Altere o
      benefício e tente nomente.
    </Text>
    <div style={{ marginBottom: '16px' }} />
    <Text variant="body-3">
      Assim que concluir esta contratação, você poderá retornar para adicionar os demais contratos
      que estão vinculados ao outro benefício.
    </Text>
    <Button
      fullWidth
      onClick={() => setOpenBottomsheetErroBeneficio(false)}
      style={{ marginTop: '24px' }}
    >
      Entendi
    </Button>
  </BottomSheet>
)

export default BottomsheetErroBeneficio
