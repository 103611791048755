import styled from 'styled-components'

import { Colors } from '@utils/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const GroupRadio = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;

  > div {
    width: 100%;
    height: 64px;
    padding: 16px;
    border: 1px solid ${Colors.PRIMARY200};
    border-radius: 8px;
  }
`

export const SeguroRefin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > label {
    padding: 16px;
    height: 107px;
    border: 1px solid ${Colors.PRIMARY200};
    border-radius: 8px;
    margin-bottom: 0;
  }
`

export const SwitchLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div > span {
    margin: 0;
  }
`
