import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NovoPage from '@templates/NovoPage'
import { Text } from '@interco/inter-ui/components/Text'
import IcDocument from '@interco/icons/core/files/ic_document'
import IcEdit from '@interco/icons/core/action-navigation/ic_edit'
import { Colors } from '@utils/colors'
import { contractsMapper, toTitleCase } from '@utils/functions'
import { ApplicationState } from '@store/types'
import { Box } from '@atoms'
import { formatCurrency } from '@utils/masks'
import routes from '@routes/routes'
import { Button } from '@interco/inter-ui/components/Button'
import { ContratosTypes } from '@store/portabilidade/contratoAtual/types'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'
import { ErrorCodesAPI, StepPortabilidadeNovo } from '@utils/enums'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { simulacaoCompletaRequest } from '@store/portabilidade/simulacaoCompleta/actions'

import { ColumnLeft, Div, Line } from './styles'
import BottomsheetExcluir from './Bottomsheets/BottomsheetExcluir'

const ContratosCadastrados = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openBottomsheet, setOpenBottomsheet] = useState(false)
  const [contratoSelecionado, setContratoSelecionado] = useState<ContratosTypes>()
  const { bancosNovo = [] } = useSelector((state: ApplicationState) => state.globalApi)
  const { bancoSelecionado, contratosAtuais } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const { loading } = useSelector(
    (state: ApplicationState) => state.portabilidade.simulacaoCompleta,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'contratos_cadastrados',
      actions: ['confirmar_contrato'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Tela com contrato cadastrado',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: `Contratos que deseja trazer de ${toTitleCase(
        bancosNovo?.find((item) => item.code === bancoSelecionado)?.description || '',
      )}`,
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })
  }, [bancoSelecionado, bancosNovo, nomeConvenioSelecionado])

  const callTagWeb = (route: string) => {
    Tags.enviarTagueamentoWeb({
      element_name: 'Continuar',
      section_name: 'Tela com contrato cadastrado',
      element_action: 'click button',
      c_page: window.location.href,
      redirect_url: route,
      step: 'null',
    })
  }

  return (
    <NovoPage
      id="contratos-cadastrados"
      positionFooter="sticky"
      stickyContainerFooterBottom="10px"
      stickyFooter={
        <Div>
          <Button
            variant="primary"
            fullWidth
            disabled={loading}
            isLoading={loading}
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Tela com contrato cadastrado',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Continuar',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              BridgeService.tic({
                name: 'btn_continuar_contrato_cadastrado',
                action: 'continuar_contrato_cadastrado',
              })
              dispatch(trackingRequest(StepPortabilidadeNovo.CONTRATO_CADASTRADO))
              dispatch(
                simulacaoCompletaRequest({
                  originBank: String(bancoSelecionado),
                  contracts: contractsMapper(contratosAtuais),
                  callback: (erroCode?: string) => {
                    if (erroCode === ErrorCodesAPI.R0002) {
                      callTagWeb(`${window.location.origin}${routes.ENTRAREMOS_CONTATO_V2}`)
                      navigate(routes.ENTRAREMOS_CONTATO_V2)
                    } else if (
                      erroCode === ErrorCodesAPI.R0003 ||
                      erroCode === ErrorCodesAPI.R0004
                    ) {
                      callTagWeb(`${window.location.origin}${routes.NAO_LIBERADO_V2}`)
                      navigate(routes.NAO_LIBERADO_V2, { state: erroCode })
                    } else {
                      callTagWeb(
                        `${window.location.origin}${routes.CARREGAMENTO_DADOS_CONTRATOS_V2}`,
                      )
                      navigate(routes.CARREGAMENTO_DADOS_CONTRATOS_V2)
                    }
                  },
                }),
              )
            }}
          >
            Continuar
          </Button>
          {/* <Button
            variant="secondary"
            fullWidth
            style={{ marginTop: '16px' }}
            onClick={() => {
              Tags.enviarTagueamentoApp({
                ref_figma: '3',
                name: 'CONSIGNADO_PORT_FLUXO',
                screen: 'Tela com contrato cadastrado',
                ref_type: 'Botao',
                content_action: 'Toque',
                action_id: 'Adicionar outro contrato',
                product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
              })
              navigate(routes.DICAS_CONTRATO_V2)
            }}
          >
            Adicionar outro contrato
          </Button> */}
        </Div>
      }
    >
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '0 0 16px 0',
        }}
      >
        {contratosAtuais && contratosAtuais?.length > 1
          ? 'Contratos que deseja trazer'
          : 'Contrato que deseja trazer'}
      </Text>
      <Line>
        <IcDocument width={24} height={24} color={Colors.GRAY500} style={{ marginRight: '4px' }} />
        <Text
          variant="headline-h3"
          semiBold
          style={{
            margin: '8px 0 8px 0',
          }}
        >
          {nomeConvenioSelecionado?.toUpperCase()}
        </Text>
      </Line>
      {/* <Text variant="body-3" bold>
        Benefício: {matricula}
      </Text> */}
      <>
        {contratosAtuais?.map((contratoAtual) => (
          <Box
            key={contratoAtual.numeroContrato}
            justify="space-between"
            margin="16px 0 0 0"
            direction="row"
            align="center"
          >
            <ColumnLeft>
              <Text variant="body-3" color="typography" colorWeight={500} bold>
                Valor da parcela
              </Text>
              <Text variant="body-3" color="typography" colorWeight={500}>
                {formatCurrency(contratoAtual.valorParcela)}
              </Text>
              <Text variant="body-3">Nº do contrato: {contratoAtual.numeroContrato}</Text>
              <Text variant="body-3">
                Banco:{' '}
                {toTitleCase(
                  bancosNovo?.find((item) => item.code === bancoSelecionado)?.description || '',
                )}
              </Text>
              <Text
                variant="body-3"
                color="primary"
                colorWeight={500}
                bold
                onClick={() => {
                  Tags.enviarTagueamentoApp({
                    ref_figma: '3',
                    name: 'CONSIGNADO_PORT_FLUXO',
                    screen: 'Tela com contrato cadastrado',
                    ref_type: 'Botao',
                    content_action: 'Toque',
                    action_id: 'Excluir',
                    product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                  })
                  setContratoSelecionado(contratoAtual)
                  setOpenBottomsheet(true)
                }}
              >
                Excluir
              </Text>
            </ColumnLeft>
            <IcEdit
              width={24}
              height={24}
              color={Colors.PRIMARY500}
              onClick={() => {
                Tags.enviarTagueamentoApp({
                  ref_figma: '3',
                  name: 'CONSIGNADO_PORT_FLUXO',
                  screen: 'Tela com contrato cadastrado',
                  ref_type: 'Botao',
                  content_action: 'Toque',
                  action_id: 'Editar',
                  product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
                })
                navigate(routes.ADICIONAR_CONTRATO_V2, { state: contratoAtual })
              }}
            />
          </Box>
        ))}
      </>
      {openBottomsheet ? (
        <BottomsheetExcluir
          setOpenBottomsheet={setOpenBottomsheet}
          contratoSelecionado={contratoSelecionado}
        />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}

export default ContratosCadastrados
