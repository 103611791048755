import { BaseServices, HttpMethod } from '@services/base'

export default (accessToken: string) => {
  const pathname = `${process.env.REACT_APP_GET_CONVENIO_TOKEN}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers = { Authorization: `Bearer ${accessToken}` }

  const response = BaseServices.request<ResponseType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface ResponseType {
  covenant: number
  covenantName: string
}
