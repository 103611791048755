enum routes {
  CONTATO = '/contato',
  DADOS_RECEBIDOS = '/dados-recebidos',
  DADOS_PROFISSIONAIS = '/dados-profissionais',
  CONVENIO_FORCAS_ARMADAS = '/convenio-forcas-armadas',
  INSS_SIMPLIFICADO = '/inss-simplificado',
  AUTORIZACAO_CONSULTA_INSS = '/inss-simplificado/autorizacao-consulta',
  SITUACAO_FUNCIONAL = '/situacao-funcional',
  DADOS_PESSOAIS = '/dados-pessoais',
  SELECAO_GRUPO = '/selecao-grupo',
  TOKEN = '/token',
  NAO_LIBERADO = '/nao-liberado',
  ONBOARDING = '/onboarding',
  RESULTADO = '/resultado',
  ROOT = '/',

  ONBOARDING_V2 = '/portabilidade/onboarding',
  SELECAO_GRUPO_V2 = '/portabilidade/selecao-grupo',
  TERMO_AUTORIZACAO_V2 = '/portabilidade/termo-autorizacao',
  INSS_INDISPONIVEL_V2 = '/portabilidade/inss-indisponivel',
  NAO_LIBERADO_V2 = '/portabilidade/nao-liberado',
  ENTRAREMOS_CONTATO_V2 = '/portabilidade/entraremos-em-contato',
  CARREGAMENTO_DADOS_V2 = '/portabilidade/carregamento-dados',
  VERIFICACAO_REDIRECIONAMENTO_V2 = '/portabilidade/verificacao',
  DADOS_BASICOS_V2 = '/portabilidade/dados-basicos',
  VALIDA_TELEFONE_V2 = '/portabilidade/valida-telefone',
  VALIDA_EMAIL_V2 = '/portabilidade/valida-email',
  CONSULTA_MARGEM_SIAPE_V2 = '/portabilidade/siape/consulta-margem-siape',
  DADOS_MANUAIS_SIAPE_V2 = '/portabilidade/siape/dados-manuais',
  FEEDBACK_RESULTADO_MANUAL_SIAPE_V2 = '/portabilidade/siape/resultado-manual',
  SIMULADOR_PORTABILIDADE = '/portabilidade/simulador',
  RESULTADO_SIMULACAO = '/portabilidade/resultado-simulacao',
  REVISAO_SIMULACAO = '/portabilidade/revisao-simulacao',
  DDC = '/portabilidade/ddc',
  BANCO_CONTRATO_V2 = '/portabilidade/banco-contrato',
  DICAS_CONTRATO_V2 = '/portabilidade/dicas-contrato',
  ADICIONAR_CONTRATO_V2 = '/portabilidade/adicionar-contrato',
  CONTRATOS_CADASTRADOS_V2 = '/portabilidade/contratos-cadastrados',
  CARREGAMENTO_DADOS_CONTRATOS_V2 = '/portabilidade/carregamento-dados-contratos',
  CARREGAMENTO_DADOS_FORMALIZACAO_V2 = '/portabilidade/carregamento-dados-formalizacao',
  STATUS_DOCUMENTO_V2 = '/portabilidade/status-documento',
  MODO_ENVIO_V2 = '/portabilidade/modo-envio',
  MODO_ENVIO_CONTRA_CHEQUE_V2 = '/portabilidade/modo-envio-contracheque',
  FOTO_DOCUMENTO_V2 = '/portabilidade/foto-documento',
  FOTO_CONTRA_CHEQUE_V2 = '/portabilidade/foto-contracheque',
  INFORMACOES_PESSOAIS_V2 = '/portabilidade/informacoes-pessoais',
  ENDERECO_V2 = '/portabilidade/endereco',
  DADOS_BANCARIOS_V2 = '/portabilidade/dados-bancarios',
  REVISAO_CONTRATACAO_V2 = '/portabilidade/revisao-contratacao',
  TERMO_VISUALIZACAO_V2 = '/portabilidade/termo-visualizacao',
  TERMOS_CONTRATACAO_V2 = '/portabilidade/termos',
  SUCESSO_V2 = '/portabilidade/sucesso',
}
export default routes
