import { Reducer } from 'redux'

import { DocumentosTypes, DocumentosState } from './types'

const INITIAL_STATE: DocumentosState = {
  tipoDocumento: '',
  loading: false,
  etapaBusca: 0,
  erroUpdateProposal: false,
}

const reducer: Reducer<DocumentosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DocumentosTypes.SHOW_LOADING:
      return { ...state, loading: true }
    case DocumentosTypes.HIDE_LOADING:
      return { ...state, loading: false }
    case DocumentosTypes.SET_ETAPA_BUSCA:
      return { ...state, etapaBusca: action.payload }
    case DocumentosTypes.SET_ERRO_UPDATE_PROPOSAL:
      return { ...state, erroUpdateProposal: action.payload }
    case DocumentosTypes.SET_TIPO_DOCUMENTO:
      return { ...state, tipoDocumento: action.payload }
    case DocumentosTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
