import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postHireCompletePortability from '@services/postHireCompletePortability'

import { ContratacaoCompletaRequestPayload } from '../types'
import { contratacaoCompletaError, contratacaoCompletaSuccess } from '../actions'

export default function* postContratacaoCompletaSaga(action: AnyAction) {
  const payload = action.payload as ContratacaoCompletaRequestPayload
  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  try {
    const { data } = yield call(
      postHireCompletePortability,
      {
        moneyChange: payload.moneyChange,
        withInsurance: payload.withInsurance,
      },
      accessToken,
    )
    yield put(contratacaoCompletaSuccess(data))
    yield payload.callback()
  } catch (error) {
    yield put(contratacaoCompletaError())
  }
}
