import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/colors'
import NovoPage from '@templates/NovoPage'
import { ApplicationState } from '@store/types'
import Tags from '@utils/Tags'
import { BridgeService } from '@services/bridge'
import routes from '@routes/routes'

import { FadeIn, LoadingComponent, TextContainer } from './styles'

interface PropsEnum {
  size: string
  bgColor: Colors
}

const enumLoading = {
  first: {
    size: '10%',
    bgColor: Colors.PRIMARY100,
  },
  second: {
    size: '30%',
    bgColor: Colors.PRIMARY200,
  },
  third: {
    size: '70%',
    bgColor: Colors.PRIMARY300,
  },
  fourth: {
    size: '100%',
    bgColor: Colors.PRIMARY400,
  },
}

const CarregamentoDadosContratos = () => {
  const navigate = useNavigate()
  const [enumLoad, setEnumLoad] = useState<PropsEnum>(enumLoading.first)
  const loading = false
  const { convenioSelecionado, nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useEffect(() => {
    BridgeService.tac({
      name: 'carregamento_dados_contratos',
      actions: ['continuar_contrato_cadastrado'],
    })
  }, [])

  useEffect(() => {
    Tags.enviarTagueamentoApp({
      ref_figma: '4',
      name: 'CONSIGNADO_PORT_FLUXO',
      screen: 'Carregamento de dados',
      ref_type: 'Feedback',
      content_action: 'Dado carregado',
      action_id: `Lembre-se: o Inter não trabalha com intermediários`,
      product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
    })

    Tags.enviarTagueamentoWeb({
      element_name: 'Confirmar',
      section_name: 'Carregamento de dados',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: `${window.location.origin}${routes.REVISAO_SIMULACAO}`,
      step: 'null',
    })
  }, [nomeConvenioSelecionado])

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setEnumLoad(enumLoading.second)
    }, 2000)
    const intervalId2 = setTimeout(() => {
      setEnumLoad(enumLoading.third)
    }, 3000)
    const intervalId3 = setTimeout(() => {
      setEnumLoad(enumLoading.fourth)
    }, 6000)
    const intervalId4 = setTimeout(() => {
      BridgeService.tic({
        name: 'btn_continuar',
        action: 'retornar_resultado_simulacao',
      })
      navigate(routes.REVISAO_SIMULACAO)
    }, 8000)

    return () => {
      clearTimeout(intervalId)
      clearTimeout(intervalId2)
      clearTimeout(intervalId3)
      clearTimeout(intervalId4)
    }
  }, [convenioSelecionado, navigate])

  return (
    <NovoPage
      id="carregamento-dados"
      showLeftIcon={false}
      footerTwoButtonsSpace="300px"
      stickyFooter={
        loading ? (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: o Inter não trabalha com intermediários
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent
              size={enumLoading.fourth.size}
              backgroundColor={enumLoading.fourth.bgColor}
            />
          </>
        ) : (
          <>
            <TextContainer size={enumLoad.size}>
              <FadeIn>
                <Text variant="headline-h1" colorWeight={500}>
                  Lembre-se: o Inter não trabalha com intermediários
                </Text>
              </FadeIn>
            </TextContainer>
            <LoadingComponent size={enumLoad.size} backgroundColor={enumLoad.bgColor} />
          </>
        )
      }
    >
      <div />
    </NovoPage>
  )
}
export default CarregamentoDadosContratos
