import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { Tag } from '@interco/inter-ui/components/Tag'
import { formatCurrency } from '@utils/masks'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Separator } from '@atoms'
import { Colors } from '@utils/colors'
import routes from '@routes/routes'
import { PortabilityContractsType } from '@store/portabilidade/simulacaoCompleta/types'
import Tags from '@utils/Tags'
import { ApplicationState } from '@store/types'
import { TipoPortabilidade } from '@utils/enums'
import { formatDecimalBr } from '@utils/functions'

import { BoxLine, Container, Header } from './styles'
import BottomsheetSaibaMais from './BottomsheetSaibaMais'

interface OwnProps {
  portabilityType: string | undefined
  portabilities: PortabilityContractsType[] | undefined
  benefitNumber: string
  canChangeContract?: boolean
}

const DetalhesPortabilidade = ({
  portabilityType,
  portabilities,
  benefitNumber,
  canChangeContract = false,
}: OwnProps) => {
  const navigate = useNavigate()
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const [showBottomsheetSaibaMais, setShowBottomsheetSaibaMais] = useState(false)
  return (
    <Container>
      <Header>
        <Text variant="body-2" bold colorWeight={500}>
          {Number(portabilities?.length) > 1 ? 'Meus contratos' : 'Meu contrato'}
        </Text>
        <Tag size="small" theme="yellow" intensity="light">
          Portabilidade
        </Tag>
      </Header>
      <BoxLine
        style={{ marginBottom: portabilityType === TipoPortabilidade.SEM_TROCO ? '24px' : '0' }}
      >
        <Text variant="body-3">Benefício</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {benefitNumber}
        </Text>
      </BoxLine>
      {portabilities?.map((portability) => (
        <>
          <BoxLine>
            <Text variant="body-3">Nº do contrato</Text>
            <Text variant="body-3" bold colorWeight={500} style={{ color: '#00AA4F' }}>
              {portability.contractNumber}
            </Text>
          </BoxLine>
          {portabilityType === TipoPortabilidade.SEM_TROCO ? (
            <>
              <BoxLine>
                <Text variant="body-3">Parcelas mensais </Text>
                <Text variant="body-3" bold colorWeight={500}>
                  {portability.numberOfInstallments}x {formatCurrency(portability.installmentValue)}
                </Text>
              </BoxLine>
              <BoxLine>
                <Text variant="body-3">Taxa nominal (a.m.)</Text>
                <Text variant="body-3" bold colorWeight={500}>
                  {formatDecimalBr(portability.nominalRateAM)}%
                </Text>
              </BoxLine>
              <BoxLine>
                <Text variant="body-3">Saldo devedor</Text>
                <Text variant="body-3" bold colorWeight={500}>
                  {formatCurrency(portability.debtBalance)}
                </Text>
              </BoxLine>
              <Text
                color="primary"
                variant="body-3"
                bold
                colorWeight={500}
                onClick={() => setShowBottomsheetSaibaMais(true)}
              >
                Saiba mais
              </Text>
              {showBottomsheetSaibaMais ? (
                <BottomsheetSaibaMais
                  portability={portability}
                  setShowBottomSheet={setShowBottomsheetSaibaMais}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ))}
      <Separator
        variant="small"
        height="1px"
        color={Colors.GRAY200}
        style={{ marginTop: '8px', marginBottom: '8px' }}
      />
      {canChangeContract ? (
        <Text
          color="primary"
          variant="body-3"
          bold
          colorWeight={500}
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '3',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: 'Resultado da simulacao - revisao',
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: 'Alterar contrato',
              product: `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`,
            })
            navigate(routes.CONTRATOS_CADASTRADOS_V2)
          }}
        >
          {Number(portabilities?.length) > 1 ? 'Alterar contratos' : 'Alterar contrato'}
        </Text>
      ) : (
        <></>
      )}
      {portabilityType === TipoPortabilidade.SEM_TROCO ? (
        <>
          <Alert type="warning">Os valores são aproximados e podem variar por convênio.</Alert>
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default DetalhesPortabilidade
