import React, { useState, CSSProperties } from 'react'

import { createPortal } from 'react-dom'
import styled from 'styled-components'

import CloseIcon from '@interco/icons/build-v4/bidis/v2/navigation/close'
import { useDomReady } from '@/common/hooks'

import * as S from './styles'

export type BaseModalProps = {
  visible: boolean
  title?: string | React.ReactNode
  preHeader?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  onClose?: () => void
  onClickOutside?: () => void
  noBackdrop?: boolean
  width?: number | string
  modalContainerStyle?: CSSProperties
  backdropStyle?: CSSProperties
  style?: CSSProperties
  portalSelector?: string
  bottomFade?: boolean
  disableBehindBodyScroll?: boolean
}

const Icon = styled(CloseIcon)`
  fill: var(--modal-close-icon-color);
`

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/modals-base-modal-since-v2-0-20--basic)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/FS12HnaqvY6B4K9mkTCSrr/DS-%2F-Components-%2F-Desktop?node-id=2539-7198&t=RabwOuj1svlNaWmz-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState, useCallback } from 'react'
 *
 * import { BaseModal as Modal } from '@interco/inter-ui/components/Modal/BaseModal'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * export const MyPage = () => {
 *   const [visible, setVisible] = useState<boolean>(false)
 *
 *   const closeModal = useCallback(() => {
 *     setVisible(false)
 *   }, [setVisible])
 *
 *   const onModalConfirm = useCallback(() => {
 *     closeModal()
 *     // Other actions
 *   }, [closeModal])
 *
 *   return (
 *     <>
 *       <Button onClick={() => setVisible(true)}>Open Modal</Button>
 *
 *       <Modal
 *         visible={visible}
 *         onClose={closeModal}
 *         onClickOutside={closeModal}
 *         title="Sample Title"
 *         footer={<div>This is the footer</div>}
 *       >
 *         Modal Content
 *       </Modal>
 *     </>
 *   )
 * }
 * ```
 */
export const BaseModal = React.forwardRef(
  (
    {
      visible,
      title,
      preHeader,
      header,
      footer,
      children,
      onClose,
      onClickOutside,
      noBackdrop = false,
      width = 640,
      style = {},
      modalContainerStyle = {},
      backdropStyle = {},
      portalSelector = 'body',
      bottomFade = true,
      disableBehindBodyScroll = false,
    }: BaseModalProps,
    modalRef?: React.Ref<HTMLDivElement>,
  ) => {
    const domReady = useDomReady()
    const [shakeWarningActive, setShakeWarningActive] = useState(false)
    const handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {
      if (e.target === e.currentTarget) {
        if (onClickOutside) onClickOutside()
        else {
          setShakeWarningActive(true)
          setTimeout(() => setShakeWarningActive(false), 700)
        }
      }
    }

    if (!domReady) return null

    const portalElement = document.querySelector(portalSelector)

    if (typeof window !== 'undefined' && window.document && disableBehindBodyScroll) {
      document.body.style.overflow = visible ? 'hidden' : 'auto'
    }

    return visible && portalElement
      ? createPortal(
          <S.Backdrop
            data-testid="modal-backdrop"
            onClick={handleClickOutside}
            transparent={noBackdrop}
            style={backdropStyle}
          >
            <S.MainContainer
              role="dialog"
              aria-modal="true"
              data-testid="modal-main-container"
              shakeWarning={shakeWarningActive}
              style={{ width, ...modalContainerStyle }}
            >
              <S.ContentWrapper data-testid="modal-content-wrapper" style={style} ref={modalRef}>
                {preHeader && <S.PreHeader data-testid="modal-preheader">{preHeader}</S.PreHeader>}
                {!header && title && (
                  <S.Header data-testid="modal-header">
                    <S.Title data-testid="modal-title">{title}</S.Title>
                  </S.Header>
                )}
                {header && <S.Header data-testid="modal-header">{header}</S.Header>}
                <S.Body data-testid="modal-body">{children}</S.Body>
                {footer && <S.Footer data-testid="modal-footer">{footer}</S.Footer>}
                {onClose && (
                  <S.CloseButton onClick={onClose} data-testid="modal-close-btn">
                    <Icon width={24} height={24} />
                  </S.CloseButton>
                )}
              </S.ContentWrapper>
              {bottomFade && <S.BottomFade data-testid="modal-bottom-fade" />}
            </S.MainContainer>
          </S.Backdrop>,
          portalElement,
        )
      : null
  },
)
