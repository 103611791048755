import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { SearchInput } from '@interco/inter-ui/components/SearchInput'
import IcSearchError from '@interco/icons/core/action-navigation/ic_search_error'
import IcWarningCircle from '@interco/icons/core/status/ic_warning_circle'
import IcCheckCircleFill from '@interco/icons/core/status/ic_check_circle_fill'
import { Text } from '@interco/inter-ui/components/Text'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import IcPillar from '@interco/icons/core/travel-mobility/ic_pillar'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import { ApplicationState } from '@store/types'
import { setBancoSelecionado } from '@store/portabilidade/contratoAtual/actions'
import routes from '@routes/routes'
import { logoByBank } from '@utils/functions'
import { BancosCodes, ConveniosCodes } from '@utils/enums'
import { BancoNovo } from '@store/globalApi/types'

import { CircleIcon, Line, LineInfo } from './styles'

const BancoContrato = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [query, setQuery] = useState('')
  const { bancosNovo = [], bancosError } = useSelector((state: ApplicationState) => state.globalApi)
  const { bancoSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const [filteredBancos, setFilteredBancos] = useState<BancoNovo[]>(bancosNovo)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value
    setQuery(newQuery)

    // Filtra os bancos com base na nova query
    const newFilteredBancos = bancosNovo.filter((banco) =>
      banco.description.toLowerCase().includes(newQuery.toLowerCase()),
    )

    setFilteredBancos(newFilteredBancos)
  }

  const navigateNextPage = (codigo: number) => {
    dispatch(setBancoSelecionado(codigo))
    navigate(routes.ADICIONAR_CONTRATO_V2, { state: location.state })
  }

  return (
    <NovoPage id="banco-contrato">
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '22px 0',
        }}
      >
        Selecione o banco que está seu contrato do{' '}
        {convenioSelecionado === ConveniosCodes.INSS ? 'INSS' : 'SIAPE'}
      </Text>
      <SearchInput value={query} onChange={handleInputChange} isFullWidth />
      {filteredBancos.length ? (
        <>
          {filteredBancos
            .filter((codigo) => String(codigo.code) !== BancosCodes.INTER)
            .map(({ code, description }) => (
              <LineInfo key={code} onClick={() => navigateNextPage(code)}>
                <Line>
                  {logoByBank(code) ? (
                    <img src={logoByBank(code)} alt={`logo-${description}`} width="32px" />
                  ) : (
                    <CircleIcon>
                      <IcPillar width={16} height={16} />
                    </CircleIcon>
                  )}
                  <Text
                    variant="body-3"
                    color="typography"
                    colorWeight={500}
                    bold
                    style={{
                      margin: '0 0 0 16px',
                    }}
                  >
                    {description}
                  </Text>
                </Line>
                <>
                  {bancoSelecionado === String(code) ? (
                    <IcCheckCircleFill width={24} height={24} color={Colors.SUCCESS500} />
                  ) : (
                    <></>
                  )}
                  <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
                </>
              </LineInfo>
            ))}
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
          {bancosError ? (
            <IcWarningCircle
              width={32}
              height={32}
              color={Colors.GRAY400}
              style={{ alignSelf: 'center' }}
            />
          ) : (
            <IcSearchError
              width={32}
              height={32}
              color={Colors.GRAY400}
              style={{ alignSelf: 'center' }}
            />
          )}
          <Text
            color="typography"
            colorWeight={500}
            semiBold
            variant="headline-h3"
            style={{ textAlign: 'center', margin: '16px 0 8px 0' }}
          >
            {bancosError
              ? 'Consulta indisponível no momento'
              : `Sem resultados para "${
                  query.length > 20 ? `${query.substring(0, 20)}...` : query
                }"`}
          </Text>
          <Text variant="body-3" style={{ textAlign: 'center' }}>
            {bancosError ? 'Tente novamente mais tarde.' : 'Tente fazer a busca novamente.'}
          </Text>
        </div>
      )}
    </NovoPage>
  )
}
export default BancoContrato
