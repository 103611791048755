import { Reducer } from 'redux'

import { SimulacaoState, SimulacaoTypes } from './types'

const INITIAL_STATE: SimulacaoState = {
  valorParcela: 0,
  saldoDevedor: 0,
  novoValorParcela: 0,
  validar: false,
  valorEconomia: 0,
  taxaNominalMensal: 0,
  novoTaxaNominalMensal: 0,
  valorSolicitado: 0,
  valorFinanciado: 0,
  leadEnviado: false,
  loading: false,
  error: false,
  errorSimulacaoPort: false,
  loadingSimulacaoPort: false,
  dadosSimulacaoAlterados: false,
  errorSendPortabilityLead: false,
  loadingSendPortabilityLead: false,
}

const reducer: Reducer<SimulacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoTypes.SIMULACAO_REQUEST:
      return { ...state, loading: true, error: false }
    case SimulacaoTypes.SIMULACAO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      }
    case SimulacaoTypes.SIMULACAO_ERROR:
      return { ...state, loading: false, error: true }

    case SimulacaoTypes.SALVAR_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        leadEnviado: false,
      }
    case SimulacaoTypes.SALVAR_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        leadEnviado: true,
      }
    case SimulacaoTypes.SALVAR_LEAD_ERROR:
      return { ...state, loading: false, error: true }
    case SimulacaoTypes.SIMULACAO_REQUEST_PORTABILITY:
      return { ...state, loadingSimulacaoPort: true, errorSimulacaoPort: false }
    case SimulacaoTypes.SIMULACAO_SUCCESS_PORTABILITY:
      return {
        ...state,
        loadingSimulacaoPort: false,
        errorSimulacaoPort: false,
        ...action.payload,
      }
    case SimulacaoTypes.SIMULACAO_ERROR_PORTABILITY:
      return { ...state, loadingSimulacaoPort: false, errorSimulacaoPort: true }
    case SimulacaoTypes.SET_DADOS_SIMULACAO_ALTERADOS:
      return { ...state, dadosSimulacaoAlterados: action.payload }
    case SimulacaoTypes.SEND_PORTABILITY_LEAD_REQUEST:
      return { ...state, loadingSendPortabilityLead: true, errorSendPortabilityLead: false }
    case SimulacaoTypes.SEND_PORTABILITY_LEAD_SUCCESS:
      return {
        ...state,
        loadingSendPortabilityLead: false,
        errorSendPortabilityLead: false,
      }
    case SimulacaoTypes.SEND_PORTABILITY_LEAD_ERROR:
      return { ...state, loadingSendPortabilityLead: false, errorSendPortabilityLead: true }
    case SimulacaoTypes.CLEAR_SIMULACAO:
      return INITIAL_STATE
    case SimulacaoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
