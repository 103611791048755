/**
 * Actions Types
 */

export enum PropostaTypes {
  DADOS_PROPOSTA_SUCCESS = '@portabilidade/proposta/DADOS_PROPOSTA_SUCCESS',
  DADOS_PROPOSTA_ERROR = '@portabilidade/proposta/DADOS_PROPOSTA_ERROR',
  DADOS_PROPOSTA_REQUEST = '@portabilidade/proposta/DADOS_PROPOSTA_REQUEST',

  BUSCAR_PROPOSTAS = '@portabilidade/proposta/BUSCAR_PROPOSTAS',
  BUSCAR_PROPOSTAS_SUCCESS = '@portabilidade/proposta/BUSCAR_PROPOSTAS_SUCCESS',
  BUSCAR_PROPOSTAS_ERROR = '@portabilidade/proposta/BUSCAR_PROPOSTAS_ERROR',
  SET_NUMERO_PROPOSTA = '@portabilidade/proposta/SET_NUMERO_PROPOSTA',

  SET_ID_ITERACAO = '@portabilidade/proposta/criacao/SET_ID_ITERACAO',
  SET_TIPO_DOCUMENTO = '@portabilidade/proposta/criacao/SET_TIPO_DOCUMENTO',
  SET_DOCUMENTO_ID = '@portabilidade/proposta/criacao/SET_DOCUMENTO_ID',

  SALVAR_PROPOSTA_REQUEST = '@portabilidade/proposta/SALVAR_PROPOSTA_REQUEST',
  SALVAR_PROPOSTA_SUCCESS = '@portabilidade/proposta/SALVAR_PROPOSTA_SUCCESS',
  SALVAR_PROPOSTA_ERROR = '@portabilidade/proposta/SALVAR_PROPOSTA_ERROR',

  EDITAR_LEAD = '@portabilidade/documentosAssinatura/EDITAR_LEAD',

  RESET = 'RESET',
}

export interface DocumentosEnvioType {
  idDocumento?: string
  idTipoDocumento: string
  nomeTipoDocumento?: string
  status?: string
}

export interface ProposalType {
  contract: string
  proposal: string
}

export interface PropostaState {
  readonly dadosProposta: PropostaApi
  readonly dadosPropostaError: boolean
  readonly loadingProposta: boolean
  readonly numeroProposta?: string | number

  readonly tipoDocumento: DocumentosEnvioType[] | undefined
  readonly idIteracao: string
  readonly documentoId: string

  readonly loadingSalvarProposta: boolean
  readonly propostaEnviada: boolean
  readonly erroSalvarProposta: boolean

  readonly proposals?: ProposalType[]
}

export interface PropostaApi {
  numeroParcelas: number
  valorParcela: string
  taxa: string
  cet: string
  percentualIOF: string
  valorIOF: string
  valorLiberado: string
  total: string
  primeiroDesconto: string
  ultimoDesconto: string
  taxaNominalContrato: string
  taxaNominalAnual: string
  jurosContratadosPercentual: string
  jurosContratados: string
  valorTotalDestinadoPagamentoSeguro: string
  percentualPropostaSeguroPrestamista: string
  tarifaCadastro: string
  taxaTarifaCadastro: string
  valorSolicitado: string
  valorSolicitadoPercentual: string
  valorOperacao: string
  valorDoMutuo: string
  atributosCetMensal: string
  atributosCetAnual: string
  dataNascimento: string
  nomeMae: string
  valorTotalASerPago: string
  quantidadeDeParcelas: number
  matricula: string
  tipoLiberacao: string
  jurosCapitalizados: boolean
  estipulante: string
  possuiSeguro: boolean
  dataEmissao: string
  enderecoResidencial: string
  cidadeResidencial: string
  estadoResidencial: string
  bairroResidencial: string
  numeroResidencial: string
  nomeCliente: string
  cpf: string
  nomeConjuge: string
  banco: string
  agencia: string
  conta: string
  nomePai: string
  cidadeNaturalidade: string
  estadoNaturalidade: string
  estadoCivil: string
  nacionalidade: string
  documentoIdentificacao: string
  taxaEfetivaMensal: string
  taxaEfetivaAnual: string
  orgao: string
  complementoResidencial: string
  cepResidencial: string
  cepResidencialComMascara: string
  dddCelular: string
  telefoneCelular: string
  codigoContratoOriginalPortabilidade: string
  bancoPortadoDescricao: string
  digitoContaCorrente: string
  profissao: string
  escolaridade: string
  contratosRefin: ContratosRefinState[]
  saldoDevedorTotalRefin: string
  email: string
  cnpjFinanceiraCredorPortabilidade: string
  prazoPortabilidade: string
  cnpjJLojaMaster: string
  vlrTotalDevedor: string
  valorSaldoDevedorPortabilidade: string
}

export interface ContratosRefinState {
  numeroContratoRefin: string
  valorParcelaContratoRefin: string
  quantidadeParcelaContratoRefin: number
  numeroPropostaRefin: string
  saldoDevedorRefin: string
}

export interface DadosPropostaPayload {
  callback: () => void
}
export interface CallbackSalvarProposta {
  callback: () => void
}

export interface BuscarPropostaPayload {
  callback?: () => void
  callbackError?: () => void
}

export type EditarLeadPayload = {
  numeroProposta?: string
  situacao?: string
  callback?: () => void
}
