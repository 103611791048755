import React, { useState } from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Tag } from '@interco/inter-ui/components/Tag'
import { formatCurrency } from '@utils/masks'
import { Alert } from '@interco/inter-ui/components/Alert'
import { RefinancingType } from '@store/portabilidade/simulacaoCompleta/types'
import { formatDecimalBr } from '@utils/functions'

import { BoxLine, Container, Header } from './styles'
import BottomsheetSaibaMais from './BottomsheetSaibaMais'

interface OwnProps {
  refinancing?: RefinancingType
}

const DetalhesRefin = ({ refinancing }: OwnProps) => {
  const [showBottomsheetSaibaMais, setShowBottomsheetSaibaMais] = useState(false)
  return (
    <Container>
      <Header>
        <Text variant="body-2" bold colorWeight={500}>
          Detalhes
        </Text>
        <Tag size="small" theme="yellow" intensity="light">
          Refinanciamento
        </Tag>
      </Header>
      <BoxLine>
        <Text variant="body-3">Troco</Text>
        <Text variant="body-3" bold colorWeight={500} style={{ color: '#00AA4F' }}>
          {formatCurrency(refinancing?.moneyChange)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Parcelas mensais </Text>
        <Text variant="body-3" bold colorWeight={500}>
          {refinancing?.numberOfInstallments}x {formatCurrency(refinancing?.installmentValue)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Taxa nominal (a.m.)</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatDecimalBr(refinancing?.nominalRateAM)}%
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Novo saldo devedor</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatCurrency(refinancing?.requestedAmount)}
        </Text>
      </BoxLine>
      <Text
        color="primary"
        variant="body-3"
        bold
        colorWeight={500}
        onClick={() => setShowBottomsheetSaibaMais(true)}
      >
        Saiba mais
      </Text>
      <Alert type="warning">
        Os valores são aproximados, caem na sua conta e podem variar por convênio.
      </Alert>
      {showBottomsheetSaibaMais ? (
        <BottomsheetSaibaMais
          refinancing={refinancing}
          setShowBottomSheet={setShowBottomsheetSaibaMais}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}

export default DetalhesRefin
