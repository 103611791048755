import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import NovoPage from '@templates/NovoPage'
import { Box } from '@atoms'
import routes from '@routes/routes'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import Tags from '@utils/Tags'
import { salvarPropostaRequest } from '@store/portabilidade/proposta/actions'
import { ApplicationState } from '@store/types'
import { TipoPortabilidade } from '@utils/enums'
import { contratacaoCompletaRequest } from '@store/portabilidade/simulacaoCompleta/actions'

import { ColumnLeft } from './styles'

const InformacoesPessoais = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loadingSalvarProposta } = useSelector(
    (state: ApplicationState) => state.portabilidade.proposta,
  )
  const { logradouro, bairro, numero, complemento, estadoSelecionado, cidadeSelecionada, cep } =
    useSelector((state: ApplicationState) => state.portabilidade.endereco)
  const { bancoSelecionado, agencia, conta, digito, bancos } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosBancarios,
  )
  const { tipoPortabilidade, seguro, loadingContratacaoCompleta } = useSelector(
    (state: ApplicationState) => state.portabilidade.simulacaoCompleta,
  )

  return (
    <NovoPage
      id="informacoes-pessoais"
      positionFooter="sticky"
      stickyContainerFooterBottom="10px"
      stickyFooter={
        <Button
          variant="primary"
          isLoading={loadingSalvarProposta || loadingContratacaoCompleta}
          disabled={loadingSalvarProposta || loadingContratacaoCompleta}
          fullWidth
          onClick={() => {
            Tags.enviarTagueamentoWeb({
              element_name: 'Confirmar informações',
              section_name: 'Confirme informações',
              element_action: 'click button',
              c_page: window.location.href,
              redirect_url: `${window.location.origin}${routes.INFORMACOES_PESSOAIS_V2}`,
              step: 'null',
            })
            dispatch(
              salvarPropostaRequest({
                callback: () => {
                  dispatch(
                    contratacaoCompletaRequest({
                      withInsurance:
                        tipoPortabilidade === TipoPortabilidade.COM_TROCO ? seguro : false,
                      moneyChange: Boolean(tipoPortabilidade === TipoPortabilidade.COM_TROCO),
                      callback: () => {
                        navigate(routes.CARREGAMENTO_DADOS_FORMALIZACAO_V2)
                      },
                    }),
                  )
                },
              }),
            )
          }}
        >
          Confirmar informações
        </Button>
      }
    >
      <Text variant="headline-h1" semiBold colorWeight={500}>
        Confirme suas informações
      </Text>

      <Box justify="space-between" margin="16px 0 0 0" direction="row" align="center">
        <ColumnLeft>
          <Text variant="body-3" bold colorWeight={500} style={{ marginBottom: '8px' }}>
            Endereço
          </Text>
          <Text variant="caption-1">
            {logradouro} {numero} {complemento}, {bairro}
          </Text>
          <Text variant="caption-1">
            {cidadeSelecionada}, {estadoSelecionado}
          </Text>
          <Text variant="caption-1">CEP {cep}</Text>
        </ColumnLeft>
        <Text
          style={{ cursor: 'pointer' }}
          color="primary"
          variant="body-3"
          bold
          colorWeight={500}
          onClick={() => navigate(routes.ENDERECO_V2)}
        >
          Alterar
        </Text>
      </Box>

      <Box justify="space-between" margin="16px 0 0 0" direction="row" align="center">
        <ColumnLeft>
          <Text variant="body-3" bold colorWeight={500} style={{ marginBottom: '8px' }}>
            Dados bancários
          </Text>
          <Text variant="caption-1">
            {bancos?.find(({ codigo }) => codigo === bancoSelecionado)?.nome}
          </Text>
          <Text variant="caption-1">Agência {agencia}</Text>
          <Text variant="caption-1">
            Conta {conta}-{digito}
          </Text>
        </ColumnLeft>
        <Text
          style={{ cursor: 'pointer' }}
          color="primary"
          variant="body-3"
          bold
          colorWeight={500}
          onClick={() => navigate(routes.DADOS_BANCARIOS_V2)}
        >
          Alterar
        </Text>
      </Box>
    </NovoPage>
  )
}

export default InformacoesPessoais
