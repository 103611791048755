import { BaseServices, HttpMethod } from '@services/base'

export default (data: TrackPortabilidadeRequest) => {
  const pathname = process.env.REACT_APP_TRACKING_PORTABILIDADE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface TrackPortabilidadeRequest {
  clientId: string
  step: string
  eventType: string
  sessionId: string
  actionType: string
  banco: string
  numeroContrato: string
  valorSaldoDevedor: number
  valorParcela: number
  quantidadeParcelas: number
  fontePagamento: string
  estado: string
  cidade: string
  convenio: string
  situacaoFuncional: string
  siglaUPAG: string
  matricula: string
  salarioBruto: number
  nome: string
  documento: string
  dataNascimento: string
  telefone: string
  email: string
  autenticouSMS: boolean
  houveEconomia: boolean
  valorParcelaAtual: number
  valorNovaParcela: number
  origem: string
  produto: string
  convenioId: number
}
