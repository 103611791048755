import { BaseServices, HttpMethod } from './base'

export interface RiscoEnderecoType {
  idConvenio: number
  estado: string
  cidade: string
}

export default async ({ idConvenio, estado, cidade }: RiscoEnderecoType) => {
  const pathname = process.env.REACT_APP_RISCO_ENDERECO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const data = {
    idConvenio,
    endereco: {
      estado,
      cidade,
    },
  }

  const response = await BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}
