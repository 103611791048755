import { BaseServices, HttpMethod } from '@services/base'
import { SimulateDataResponse } from '@store/portabilidade/simulacao/types'

import { BridgeService } from './bridge'

export interface SimulateData {
  debtBalance: number
  installmentValue: number
}

export default (convenioSelecionado: string, data: SimulateData, accessToken?: string) => {
  const pathname = accessToken
    ? `portal/bff/v1/${process.env.REACT_APP_SIMULATE_PORTABILITY}/${convenioSelecionado}`
    : `${process.env.REACT_APP_SIMULATE_PORTABILITY}/${convenioSelecionado}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  return BaseServices.request<SimulateDataResponse>({
    headers,
    pathname,
    method,
    host,
    data,
  })
}
