import { Reducer } from 'redux'

import { SessionState, SessionTypes } from './types'

const INITIAL_STATE: SessionState = {
  sessionId: '',
  sessionCreateDate: undefined,
  utmSource: '',
  utmMedium: '',
  utmContent: '',
  utmCampaign: '',
  facebookId: '',
  gaClientId: '',
  gaClid: '',
  accessToken: undefined,
  loadingSession: false,
}

const reducer: Reducer<SessionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SessionTypes.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
        sessionCreateDate: new Date(),
      }
    case SessionTypes.SET_SESSION_CREATE_DATE:
      return { ...state, sessionCreateDate: action.payload }
    case SessionTypes.SET_UTM_SOURCE:
      return { ...state, utmSource: action.payload }
    case SessionTypes.SET_UTM_MEDIUM:
      return { ...state, utmMedium: action.payload }
    case SessionTypes.SET_UTM_CONTENT:
      return { ...state, utmContent: action.payload }
    case SessionTypes.SET_UTM_CAMPAIGN:
      return { ...state, utmCampaign: action.payload }
    case SessionTypes.SET_FACEBOOK_ID:
      return { ...state, facebookId: action.payload }
    case SessionTypes.SET_GA_CLIENT_ID:
      return { ...state, gaClientId: action.payload }
    case SessionTypes.SET_GA_CLID:
      return { ...state, gaClid: action.payload }
    case SessionTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload }
    case SessionTypes.SET_LOADING_REQUEST_SESSION:
      return { ...state, loadingSession: action.payload }
    case SessionTypes.RESET_REQUEST:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
