import { action, Action } from 'typesafe-actions'

import {
  PropostaTypes,
  PropostaApi,
  DocumentosEnvioType,
  CallbackSalvarProposta,
  BuscarPropostaPayload,
  EditarLeadPayload,
  ProposalType,
} from './types'

export const dadosPropostaRequest = (): Action => action(PropostaTypes.DADOS_PROPOSTA_REQUEST)
export const dadosPropostaSucess = (payload: PropostaApi): Action =>
  action(PropostaTypes.DADOS_PROPOSTA_SUCCESS, payload)
export const dadosPropostaError = (): Action => action(PropostaTypes.DADOS_PROPOSTA_ERROR)

export const setNumeroProposta = (payload: string | number): Action =>
  action(PropostaTypes.SET_NUMERO_PROPOSTA, payload)

export const buscarProposta = (payload?: BuscarPropostaPayload): Action =>
  action(PropostaTypes.BUSCAR_PROPOSTAS, payload)

export const buscarPropostaSuccess = (payload: ProposalType[]): Action =>
  action(PropostaTypes.BUSCAR_PROPOSTAS_SUCCESS, payload)

export const buscarPropostaError = (): Action => action(PropostaTypes.BUSCAR_PROPOSTAS_ERROR)

export const setTipoDocumento = (payload: DocumentosEnvioType[]): Action =>
  action(PropostaTypes.SET_TIPO_DOCUMENTO, payload)

export const setDocumentoId = (payload: string): Action =>
  action(PropostaTypes.SET_DOCUMENTO_ID, payload)

export const setIdIteracao = (payload: string): Action =>
  action(PropostaTypes.SET_ID_ITERACAO, payload)

export const salvarPropostaRequest = (payload: CallbackSalvarProposta): Action =>
  action(PropostaTypes.SALVAR_PROPOSTA_REQUEST, payload)

export const salvarPropostaSuccess = (): Action => action(PropostaTypes.SALVAR_PROPOSTA_SUCCESS)

export const salvarPropostaError = (): Action => action(PropostaTypes.SALVAR_PROPOSTA_ERROR)

export const editarLead = (payload: EditarLeadPayload): Action =>
  action(PropostaTypes.EDITAR_LEAD, payload)
