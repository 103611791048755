import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import routes from '@routes/routes'
import imageOnboarding from '@images/imageOnboardingNova.png'
import { Page } from '@templates'
import { ApplicationState } from '@store/types'
import { useTrackingPortabilidade } from '@hooks'
import { StepPortabilidade } from '@utils/enums'
import { Colors } from '@utils/colors'
import Paragraph from '@atoms/Paragraph'
import { Button } from '@interco/inter-ui/components/Button'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import BancoDropdown from '@organisms/InputsDropdown/BancoDropdown'
import { setBancoSelecionado, setvalorParcela } from '@store/portabilidade/contratoAtual/actions'
import { transformBancoToOption } from '@utils/transformers'
import Help from '@interco/icons/core/status/ic_help_circle'
import { dialogVariants, VariantDialog } from '@utils/bottomSheetVariants'
import { Dialog, ListaDescricao } from '@molecules'
import Wallet from '@interco/icons/core/finance/ic_wallet'
import MoneyDown from '@interco/icons/core/finance/ic_coin_arrow_down_in'
import Agenda from '@interco/icons/core/action-navigation/ic_calendar'
import Smartphones from '@interco/icons/core/media-communication/ic_smartphone'
import { bancosRequest } from '@store/globalApi/actions'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import { Image } from '@atoms/Image'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import GoogleTag from '@utils/GoogleTag'

import { BoxGrid, Div, ValorParcela } from './styles'

const Onboarding = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { bancoSelecionado, valorParcela } = useSelector(
    (state: ApplicationState) => state.portabilidade.contratoAtual,
  )
  const { bancos = [] } = useSelector((state: ApplicationState) => state.globalApi)
  const [open, setOpen] = useState(false)
  const [variant, setVariant] = useState<VariantDialog>()

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Portabilidade de Consignado', true)
    }
  }, [])

  const callGoogleTag = (buttonName: string) => {
    GoogleTag.simuladorConsignadoTag({
      only_app: true,
      content_name: buttonName,
      tela: 'Onboarding Portabilidade',
      content_action: 'Clique',
      element_action: 'click button',
      c_page: window.location.href,
      input_name_1: 'Banco atual do seu empréstimo, select',
      input_value_1: bancoSelecionado
        ? bancos?.find((banco) => banco.codigo === bancoSelecionado)?.nome
        : 'null',
      input_name_2: 'Valor das parcelas, input',
      input_value_2: valorParcela ? String(valorParcela) : 'null',
    })
  }

  useEffect(() => {
    if (!bancos || bancos.length === 0) {
      dispatch(bancosRequest())
    }
  }, [bancos, dispatch])

  useTrackingPortabilidade(StepPortabilidade.CONTRATO_ATUAL)

  const disabled = () => !bancoSelecionado || !valorParcela

  return (
    <Page
      id="page-onboarding"
      stickyFooter={
        <>
          <Button
            variant="primary"
            disabled={disabled()}
            onClick={() => {
              callGoogleTag('Trazer Consignado')
              navigate(routes.DADOS_PESSOAIS)
              dispatch(trackingRequest(StepPortabilidade.CONTINUAR_CONTRATO_ATUAL))
            }}
            fullWidth
            icon={<ChevronRight color={disabled() ? Colors.GRAY300 : Colors.WHITE} />}
          >
            Trazer Consignado
          </Button>
        </>
      }
    >
      <Image src={imageOnboarding} alt="Mulher sorrindo" />
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="14px 0 8px 0"
      >
        Traga seu Consignado para o Inter
      </Paragraph>
      <Paragraph
        fontWeight="normal"
        fontSize="14px"
        lineHeight="17px"
        color={Colors.GRAY400}
        textAlign="justify"
        margin="0 0 32px"
      >
        Pra te mostrar uma simulação de portabilidade mais personalizada, precisamos de alguns
        dados. Só leva 1 minuto.
      </Paragraph>
      <BoxGrid padding="16px">
        <Paragraph
          fontWeight="normal"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="center"
        >
          Banco atual do seu empréstimo
        </Paragraph>
        <BancoDropdown
          id="dropdown-banco"
          bancoSelecionado={transformBancoToOption(bancos, bancoSelecionado)}
          onChange={(option) => {
            dispatch(setBancoSelecionado(option.value))
            callGoogleTag('Banco atual do seu empréstimo')
          }}
        />
        <Div>
          <Paragraph
            fontWeight="normal"
            fontSize="14px"
            lineHeight="17px"
            color={Colors.GRAY500}
            textAlign="center"
            margin="0 0 8px"
          >
            Valor das parcelas
          </Paragraph>
          <Help
            height={16}
            width={16}
            color={Colors.ALERT500}
            onClick={() => {
              setOpen(true)
              setVariant(dialogVariants.valorParcela)
            }}
          />
        </Div>
        <ValorParcela
          id="valor-parcela-onboarding"
          valorParcela={valorParcela}
          onChange={(e) => dispatch(setvalorParcela(e))}
        />
        <Button
          variant="primary"
          disabled={disabled()}
          onClick={() => {
            callGoogleTag('Simular agora')
            navigate(routes.DADOS_PESSOAIS)
            dispatch(trackingRequest(StepPortabilidade.CONTINUAR_CONTRATO_ATUAL))
          }}
          fullWidth
          icon={<ChevronRight color={disabled() ? Colors.GRAY300 : Colors.WHITE} />}
        >
          Simular agora
        </Button>
      </BoxGrid>

      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.GRAY500}
        margin="40px 0 0"
      >
        Vantagens de trazer seu Consignado para o Inter
      </Paragraph>
      <Paragraph
        fontWeight="normal"
        fontSize="14px"
        lineHeight="17px"
        color={Colors.GRAY500}
        margin="16px 0 24px"
      >
        Você recebe o dinheiro rápido, pode escolher como quer usar o valor e conta ainda com muito
        mais benefícios!
      </Paragraph>

      <ListaDescricao iconLeft={<Wallet height={25} width={25} color={Colors.PRIMARY500} />}>
        <Paragraph
          variant="inter"
          fontWeight="700"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="0"
        >
          Portabilidade com troco
        </Paragraph>
        <Paragraph
          variant="inter"
          fontWeight="400"
          fontSize="14px"
          lineHeight="17px"
          textAlign="left"
          color={Colors.GRAY500}
          margin="8px 0 16px"
        >
          Refinancie seu contrato atual mantendo o mesmo valor das parcelas e receba um troco na
          volta.
        </Paragraph>
      </ListaDescricao>

      <ListaDescricao iconLeft={<MoneyDown height={25} width={25} color={Colors.PRIMARY500} />}>
        <Paragraph
          variant="inter"
          fontWeight="700"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="0"
        >
          Taxas reduzidas
        </Paragraph>
        <Paragraph
          variant="inter"
          fontWeight="400"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="8px 0 16px"
        >
          Taxas de juros menores em relação a outras modalidades de crédito, e adequadas às suas
          necessidades.
        </Paragraph>
      </ListaDescricao>

      <ListaDescricao iconLeft={<Agenda height={25} width={25} color={Colors.PRIMARY500} />}>
        <Paragraph
          variant="inter"
          fontWeight="700"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="0"
        >
          Agilidade e praticidade
        </Paragraph>
        <Paragraph
          variant="inter"
          fontWeight="400"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="8px 0 16px"
        >
          Receba o dinheiro em até 3 dias e tenha as parcelas descontadas diretamente do seu
          benefício ou folha de pagamento.
        </Paragraph>
      </ListaDescricao>

      <ListaDescricao iconLeft={<Smartphones height={25} width={25} color={Colors.PRIMARY500} />}>
        <Paragraph
          variant="inter"
          fontWeight="700"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="0"
        >
          Atendimento personalizado
        </Paragraph>
        <Paragraph
          variant="inter"
          fontWeight="400"
          fontSize="14px"
          lineHeight="17px"
          color={Colors.GRAY500}
          textAlign="left"
          margin="8px 0 16px"
        >
          Conte com um time de especialistas em Crédito Consignado e receba um atendimento único, de
          acordo com o que você precisa.
        </Paragraph>
      </ListaDescricao>

      {open ? (
        <Dialog
          onClose={() => setOpen(false)}
          headerTitle={variant?.title || ''}
          description={variant?.text}
          buttonText="Entendi"
        />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default Onboarding
