import { call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ConveniosCodes, ErrorCodes } from '@utils/enums'
import {
  setConvenioSelecionado,
  setNomeConvenioSelecionado,
  setOrgaoSelecionado,
} from '@store/portabilidade/dadosProfissionais/actions'

import { conveniosError, conveniosRequest, conveniosSuccess, orgaosSuccess } from '../actions'
import { Convenio } from '../types'

interface ConveniosPayload {
  fontePagamento: number
  callback?: (convenios?: Convenio[]) => void
}

interface ConveniosPayload {
  fontePagamento: number
  callback?: (convenios?: Convenio[]) => void
}

export default function* getConvenios(action: AnyAction) {
  const payload = action.payload as ConveniosPayload

  try {
    const response = yield* call(services.getConvenios, payload.fontePagamento)
    const { convenios } = response.data
    const data = Object.values(convenios).filter((value) => !!value && !!value?.codigo)
    yield put(conveniosSuccess(data))

    // Se houver apenas um convenio ja deixa ele como selecionado
    if (data?.length === 1) {
      const [convenio] = data
      yield put(setConvenioSelecionado(convenio.codigo))
      if (convenio.codigo === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
        yield put(setNomeConvenioSelecionado('SIAPE'))
      } else {
        yield put(setNomeConvenioSelecionado(convenio.descricao))
      }

      // Busca orgaos de uma vez, ja que o convenio ja foi selecionado
      const responseOrgao = yield* call(services.getOrgaos, convenio.codigo)
      const { orgaosPorConvenioConsulta } = responseOrgao.data
      const orgaos = Object.values(orgaosPorConvenioConsulta).filter(
        (value) => !!value && !!value?.codigo,
      )
      yield put(orgaosSuccess(orgaos))

      // Se houver apenas um orgao também ja deixa ele como selecionado
      if (orgaos.length === 1) {
        const [orgao] = orgaos
        yield put(setOrgaoSelecionado(orgao.codigo))
      }
    }
    if (payload.callback) {
      yield payload.callback(convenios)
    }
  } catch (error) {
    yield put(conveniosError())
    yield put(
      showError({
        title: 'Erro ao buscar os convênios',
        message: 'Ocorreu um erro listagem de convênios.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_005_CONVENIOS,
        actionCallback: () => conveniosRequest(payload),
      }),
    )
  }
}
