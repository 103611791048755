import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { formatCurrency } from '@utils/masks'
import { PortabilityContractsType } from '@store/portabilidade/simulacaoCompleta/types'
import { formatDecimalBr } from '@utils/functions'

import { Container } from './styles'
import { BoxLine, Header } from '../styles'

interface OwnProps {
  portability: PortabilityContractsType
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetSaibaMais = ({ portability, setShowBottomSheet }: OwnProps) => (
  <BottomSheet onClose={() => setShowBottomSheet(false)} noSwipe>
    <Container>
      <Header>
        <Text variant="body-2" bold colorWeight={500}>
          Detalhes do contrato sem troco
        </Text>
      </Header>
      <BoxLine>
        <Text variant="body-3">Nº do contrato</Text>
        <Text variant="body-3" bold colorWeight={500} style={{ color: '#00AA4F' }}>
          {portability.contractNumber}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Parcelas mensais </Text>
        <Text variant="body-3" bold colorWeight={500}>
          {portability.numberOfInstallments}x {formatCurrency(portability.installmentValue)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Taxa nominal (a.m.)</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatDecimalBr(portability.nominalRateAM)}%
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Valor IOF</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatCurrency(portability.iofValue)}
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">CET (a.m.)</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatDecimalBr(portability.cetAM)}%
        </Text>
      </BoxLine>
      <BoxLine>
        <Text variant="body-3">Saldo devedor</Text>
        <Text variant="body-3" bold colorWeight={500}>
          {formatCurrency(portability.debtBalance)}
        </Text>
      </BoxLine>

      <Button fullWidth style={{ marginTop: '8px' }} onClick={() => setShowBottomSheet(false)}>
        Entendi
      </Button>
    </Container>
  </BottomSheet>
)

export default BottomsheetSaibaMais
