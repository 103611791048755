import styled, { css } from 'styled-components'

import RadioOffIcon from '@interco/icons/build-v4/bidis/v2/toggle/radio-button-off'
import RadioOnIcon from '@interco/icons/build-v4/bidis/v2/toggle/radio-button-on'
import { DEFAULT_CHECKBOX_RADIO_SIZE } from '@/common/constants'

export type StyledRadioProps = {
  disabled: boolean
  reversed: boolean
}

type ModifiersProps = Partial<StyledRadioProps>

const modifiers = {
  disabled: `
    pointer-events: none;
    opacity: 0.5;
  `,
}

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  cursor: pointer;
`

export const Label = styled.label<ModifiersProps>`
  ${({ reversed }) => css`
    font-family: Inter;
    font-size: var(--radio-label-font-size);
    color: var(--radio-label-text-color);
    margin: ${reversed ? '0 12px 0 0' : '0 0 0 12px'};
  `}
`

export const Container = styled.div<ModifiersProps>`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    padding: 4px 0;
    box-sizing: border-box;
    user-select: none;
    ${disabled && modifiers.disabled}
  `}
`

export const RadioOn = styled(RadioOnIcon)<Size>`
  ${DEFAULT_CHECKBOX_RADIO_SIZE}
  color: var(--radio-selected-color);
`

export const RadioOff = styled(RadioOffIcon)<Size>`
  ${DEFAULT_CHECKBOX_RADIO_SIZE}
  color: var(--radio-selected-color);
  path {
    fill: var(--gray300);
  }
`

export const IconWrapper = styled.div`
  max-height: 32px;
`
